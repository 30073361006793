import React, { Component } from "react";
import NavLink from "./NavLink";

function AnSideBarOptions(props) {
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h3>{props.title}</h3>
      </div>
      <div className="sidebar-body">
        <ul className="nav nav-pills flex-column">
          {props.links.map((link, index) => 
            <NavLink key={index} badge={link.span} {...link} />
          )}
          {/* <li className="nav-item">
            <Link to="/offhire/other" className="nav-link">
              <i className="fa fa-cog"></i> Other
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default AnSideBarOptions;
