import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function EventTypeForm(props) {
  
    const labels = {
        // offhire_event_category: {
        //   isGroupTitle: false,
        //   title: "Event Category",
        //   type: "select",
        //   options: [
        //     {
        //       value: "",
        //       title: "-Select Event Category-",
        //     },
        //   ],
        // },
        event_type_name: {
          isGroupTitle: false,
          title: "Name",
          type: "text",
          value: "",
        },
        event_type_description: {
          isGroupTitle: false,
          title: "Description",
          type: "text",
          // readOnly: true,
        },
      };

      const getFieldsMap = () => {
        // front:back
        return {
          // offhire_event_category: "offhire_events_category_id",
          event_type_name: "name",
          event_type_description: "description",
        };
      };

    return (
        <AnCommonFormDialog
            {...props}
            header="Event Type"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/events/type/"
        />
    );
}

export default EventTypeForm;