import React, { Component } from "react";
import { EachItem } from "../components/hoc/EachItem";
import EditableText from "../../common/components/inputs/EditableText";


class Profile extends Component {
  state = {
    items: [],
  }
  componentDidMount() {
    const { user } = this.props;
    this.initUserDetails(user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      const { user } = this.props;
      this.initUserDetails(user);
    }
  }

  initUserDetails = (user) => {
    const { username, employee, role } = user ? user : {};
    const details = [
      { title: "Name", value: employee?.name },
      { title: "Username", value: username },
      { title: "Email", value: employee?.email },
      { title: "Role", value: role?.name },
    ];
    this.setState({ items: details });
  }

  handleValueChange = (index, value) => {
    let { items } = this.state;
    items = [...items];
    items[index].value = value;
    this.setState({ items });
    alert("Value changes currently are not allowed")
  }

  // View Profile
  render() {
    const { items: details } = this.state;
    return (
      <div className="no-gutters border py-3 px-5 my-1 rounded">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-lg-12">
            <span className="display-4">Profile</span>
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-sm-12 col-lg-12">
          Name: <input type="text" name="name" id="name" />
          Email: <input type="text" name="email" id="email" />
          < */}
        <div className="row justify-content-center mt-3">
          <div className="col-sm-12 col-lg-12">
            <EachItem of={details} render={(item, index) => 
              <div className="row mt-3">
              <div className="col-12">
                <p className="card-title">{item.title}</p>
                <h5 className="card-text">
                  <EditableText value={item.value} onSubmit={(value) => this.handleValueChange(index, value)} />  
                </h5>
              </div>
            </div>
          }
          />  
          </div>
      </div>
    </div>
    );
  }
}

export default Profile;
