import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Navigate } from "react-router-dom";
import AnSpinner from "../../core/components/other/AnSpinner";
import { OffhireReportService } from "../services/OffhireReportService";

class SameVesselReportSelector extends Component {
  state = {
    vesselReports: [],
    selectedReport: null,
    totalRecords: 0,
    lazyParams: {
        first: 0,
        rows: 10,
        page: 1,
        // sortField: null,
        // sortOrder: null,
        // filters: {
        //     'name': { value: '', matchMode: 'contains' },
        //     'country.name': { value: '', matchMode: 'contains' },
        //     'company': { value: '', matchMode: 'contains' },
        //     'representative.name': { value: '', matchMode: 'contains' },
        // }
    },

    onProgress: true,
    isLoggedIn: true,
    isLoaded: false,
    error: "",
  };

  onFetchSuccess = (response, firstFetch = false) => {
    if (response.status === 200) {
      const { records, records_count} = response?.data ?? {};

      this.setState({
        onProgress: false,
        isLoaded: true,
        vesselReports: records ?? [],
        totalRecords: records_count ?? 0
      });
    }
  };

  onFetchFailed = (error) => {
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.setState({
        onProgress: false,
        isLoaded: true,
        error: "No data found for the given input.",
      });
    } else {
      this.setState({
        onProgress: false,
        isLoaded: false,
        error: "Error: " + error.response.statusText + ", (Not found)",
      });
    }
    this.setState({
      vesselReports: [],
    });
  };

  componentDidMount() {
    this.fetchReports();
  }

  fetchReports = (page=1) => {
    this.setState({ onProgress: true});
    const {vesselCode} = this.props;
    const filters = {
        vessel_codes: vesselCode ? [vesselCode]:[],
        years: [],
        months: [],
        page,
      };

    OffhireReportService.getFilteredNonMemo(filters)
      .then(
        (response) => this.onFetchSuccess(response, true),
        this.onFetchFailed
      );
  };

    onPage = (event) => {
        this.fetchReports(event?.page+1);
        this.setState({ lazyParams:event});
    }

  setSelectedReport = (selectedReport) => {
    const { onSelect } = this.props;
    onSelect && onSelect(selectedReport);
    console.log(selectedReport);
    // set selected report
    this.setState({
      ...this.state,
      selectedReport,
    });
  };

  render() {
    // debugger;
    const { isLoaded, isLoggedIn, onProgress, vesselReports, selectedReport, lazyParams, totalRecords } = this.state;
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    if (onProgress) {
      return <AnSpinner/>;
    }
    if (!vesselReports || vesselReports?.length < 1)
      return <>No Reports available!</>;

    vesselReports.forEach((rep) => {
      rep.formatted = {};
      rep.formatted.is_planned = rep?.offhire_report_technical?.is_planned
        ? "Planned"
        : "Unplanned";
      rep.formatted.delay_start_at_gmt =
        rep?.offhire_report_technical?.delay_start_at_gmt;
      rep.formatted.delay_end_at_gmt =
        rep?.offhire_report_technical?.delay_end_at_gmt;
      rep.formatted.offhire_days =
        rep?.offhire_report_technical?.delay_end_at_gmt -
        rep?.offhire_report_technical?.delay_start_at_gmt;
      rep.formatted.place = rep?.offhire_report_technical?.location?.toUpperCase() === "SEA"
      ? rep?.offhire_report_technical?.sea?.name ?? ""
      : rep?.offhire_report_technical?.port?.name ?? "";
      });

      const {technicalReport} = this.props;
    return (
      <div className="row-col p-3">
        <DataTable
          value={vesselReports}
          size="small"
          selection={selectedReport}
          onSelectionChange={(e) => this.setSelectedReport(e.value)}
          dataKey="id"
          style={{ fontSize: "0.7rem" }}
          lazy
          paginator
          first={lazyParams.first}
          rows={10}
          totalRecords={totalRecords}
          onPage={this.onPage}
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          // rowsPerPageOptions={[5, 10, 20, 50]}
          //   paginatorLeft={paginatorLeft}
          //   paginatorRight={paginatorRight}
          isDataSelectable={options=>{
            //debugger;
            const {data, index} = options;
            return data?.offhire_report_technical?.id !== technicalReport?.id;
          }}
        >
          <Column
            selectionMode="single"
            headerStyles={{ width: "3em" }}
          ></Column>
          <Column field="vessel_name" header="Vessel"></Column>
          <Column
            field="offhire_report_technical.location"
            header="Locaiton"
          ></Column>
          <Column
            field="formatted.place"
            header="Place"
          ></Column>
          <Column
            field="formatted.delay_start_at_gmt"
            header="Delay Start (GMT)"
          ></Column>
          <Column
            field="formatted.delay_end_at_gmt"
            header="Delay End (GMT)"
          ></Column>
          <Column
            field="formatted.offhire_days"
            header="Total Time (day)"
          ></Column>
          <Column field="activity" header="Offhire/Stoppage"></Column>
          <Column
            field="formatted.is_planned"
            header="Planned/Unplanned"
          ></Column>
          <Column
            field="offhire_report_technical.event_type.name"
            header="Type"
          ></Column>
          <Column
            field="offhire_report_technical.machinery.machinery_type.name"
            header="Machinery"
          ></Column>
          <Column
            field="offhire_report_technical.machinery.name"
            header="Machinery Subtype"
          ></Column>
          <Column field="reason" header="Details"></Column>
          <Column field="report_status" header="Report Status"></Column>
        </DataTable>
      </div>
    );
  }
}

export default SameVesselReportSelector;
