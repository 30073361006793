import { Link } from "react-router-dom";

function AnLink({type, to, icon, state, title, className, style}) {
  return (
  <Link 
    className={className ?? "p-button p-1 p-button-sm p-button-rounded p-button-text p-button-"+(type??'secondary')} 
    to={to} 
    state={state} 
    title={title}
    style={style}
    >
        <i className={icon}></i>
    </Link>);
}

export default AnLink;