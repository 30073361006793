export function roundNum(num) {
  /**
   * The Number.EPSILON property represents
   * the difference between 1 and the smallest floating point number greater than 1.
   */
  return isNaN(num) ? num : Math.round((num + Number.EPSILON) * 100) / 100;
}

export function getDaysBetweenDates(startDate, endDate) {
  /**
   * Returns the number of days between two dates.
   */
  if (startDate === "" || endDate === "") {
    return "";
  }
  let start = new Date(startDate);
  let end = new Date(endDate);
  const milisecondsPerDay = 86400000;
  return (end - start) / milisecondsPerDay;
}

// getDaysBetweenDates = (startDate, endDate) => {
//   if (startDate === "" || endDate === "") {
//     return "";
//   }
//   let start = new Date(startDate);
//   let end = new Date(endDate);
//   let days = (end - start) / (1000 * 60 * 60 * 24);
//   return days;
// };

export function formatDatetime(datetime) {
  let datetimeSplit = datetime?.slice(0, 16).split("T");
  let dateSplit = datetimeSplit?.[0].split("-").reverse();
  return `${dateSplit?.[0]}/${dateSplit?.[1]}/${dateSplit?.[2]} ${datetimeSplit?.[1]}`;
}
