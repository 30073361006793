import { getToken } from "./session";
export const getAuthHeader = () => {
  // const token = localStorage.getItem("hpttoken");
  const token = getToken();
  let headers = {
    Accept: "application/json",
    // "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
};
