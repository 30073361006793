import React, { Component } from "react";
import glob, { cHeadLogoHeight } from "../application/application";
import { Outlet } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import HeaderNavBar from "./components/header/HeaderNavBar";


const NotificationRow = (props) => {
  if(!glob?.errorMsg)
    return <></>;
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className={"alert alert-dismissable alert-" + props.errorType}>
          <button data-dismiss="alert" className="close" type="button">
            x
          </button>
          <p>{props.errorMsg}</p>
        </div>
        <div style={{ height: 10 }}>&nbsp;</div>
      </div>
    </div>
  );
}

class MainTemplate extends Component {
  state = {
    isSideBarOpen: false,
  }
  render() {
    const { isSideBarOpen } = this.state;
    const { breadCrumbItems, isLoggedIn } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            height: cHeadLogoHeight + 10,
            backgroundColor: "#000000 #343a40 !important",
          }}
        ></div>
        {/* {isLoggedIn && <BreadCrumb model={breadCrumbItems} home={{ icon: "pi pi-home" }} style={{background: 'none'}} />} */}
        <div className="container_ body">
          <div className="main_container" style={isSideBarOpen?{marginLeft:'250px'}:{}}>
            <div className="row m-0 p-0">
              {/* <HeadNavComponent fetchUser={this.getLoggedInUser} /> */}
              <HeaderNavBar isLoggedIn={isLoggedIn} refresh={true}  onSideBarToggle={(isOpen)=>{
                this.setState({
                  isSideBarOpen: isOpen,
                });
              }} />
            </div>
            <div className="right_col p-1" role="main">
              <div className="row justify-content-center ml-3 px-3">
                <div className="col col-lg-12">
                  <NotificationRow errorType={glob.errorType} errorMsg={glob.errorMsg} />
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }


}


export default MainTemplate;
