import React, { useState } from "react";
import "./App.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
 
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MainTemplate from "./core/layout/mainTemplate";

import Login from "./core/pages/Login";
import Logout from "./core/pages/Logout";
import Main from "./core/pages/Main";
import Profile from "./core/pages/Profile";
import SessionEndMessage from "./core/pages/SessionEndMessage";
import AnCreateUser from "./core/components/forms/AnCreateUser";

// import CreateEventScreen from "./offhire/pages/CreateEventScreen";
import OffhireBudgetScreen from "./offhire/pages/OffhireBudgetScreen";

import EventCategoryScreen from "./offhire/pages/EventCategoryScreen";
import EventTypeScreen from "./offhire/pages/EventTypeScreen";
import MachineryTypeScreen from "./offhire/pages/MachineryTypeScreen";
import MachineryScreen from "./offhire/pages/MachineryScreen";
import FleetScreen from "./common/pages/FleetScreen";

import SmallFormsScreen from "./core/pages/SmallFormsScreen";
import AddEventTypeForm from "./offhire/forms/AddEventTypeForm";
import AddMachineryTypeForm from "./offhire/forms/AddMachineryType";
import AddMachineryForm from "./offhire/forms/AddMachineryForm";
import AnAddVesselForm from "./core/components/forms/AnAddVesselForm";
import AddEventsCategoryForm from "./offhire/forms/AddEventsCategoryForm";
import UsersScreen from "./common/pages/UsersScreen";
import NotFound from "./core/pages/NotFound";
import { getUser } from "./core/application/session";
import AnAddFleetForm from "./core/components/forms/AnAddFleetForm";
import EmployeeScreen from "./common/pages/EmployeeScreen";
import RoleScreen from "./common/pages/RoleScreen";
import OffhireEventDaysScreen from "./offhire/pages/OffhireEventDaysScreen";
import OffhireEventDaysEditForm from "./offhire/forms/OffhireEventDaysEditForm";
import VesselScreen from "./common/pages/VesselScreen";
import PortScreen from "./common/pages/PortScreen";
import AddBudgetItemForm from "./offhire/forms/AddBudgetItemForm";
import OffhireReportUpdateForm from "./offhire/forms/OffhireReportUpdateForm";
import OffhireReportApprovalForm from "./offhire/forms/OffhireReportApprovalForm";
import OffhireReportView from "./offhire/components/OffhireReportView";
import OffhireReportAddForm from "./offhire/forms/OffhireReportAddForm";
import OffhireReportForm from "./offhire/forms/OffhireReportForm";
import OffhireReportFormScreen from "./offhire/pages/OffhireReportFormScreen";
import FleetOffhireReports from "./offhire/components/FleetOffhireReports";
import OffhireDashboardPannel2 from "./offhire/components/OffhireDashboardPannel2";
// import AnSpinner from "./core/components/other/AnSpinner";

function App() {
  // isLoggedIn: false,
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainTemplate isLoggedIn={isLoggedIn} user={user} />}>
            <Route index element={<Main setIsLoggedIn={setIsLoggedIn} setUser={setUser} />} />
            <Route path="main" element={<Main setIsLoggedIn={setIsLoggedIn} setUser={setUser} />} />
            <Route path="ulogin" element={<Login setIsLoggedIn={setIsLoggedIn} setUser={setUser} />} />
            <Route path="ulogout" element={<Logout setIsLoggedIn={setIsLoggedIn} setUser={setUser} /> } />
            <Route path="sessionends" element={<SessionEndMessage setIsLoggedIn={setIsLoggedIn} setUser={setUser} /> } />
            <Route path="profile" element={<Profile user={user}/>} />

            {/* Dashboard */}
            <Route path="offhire/dashboard" element={<OffhireDashboardPannel2 />} />

            {/* Reports*/}
            <Route path="offhire/reports" element={<FleetOffhireReports />} />
            <Route path="offhire/report" element={<OffhireReportFormScreen />} >
              <Route path="update" element={<OffhireReportUpdateForm />} />
              <Route path="check" element={<OffhireReportApprovalForm />} />
              <Route path="view" element={<OffhireReportView />} />
              <Route path="addon" element={<OffhireReportAddForm />} />
              <Route path="create" element={<OffhireReportForm />} />
            </Route>

            {/* Admin Pages - c - view screens */}
            <Route path="offhire/event-category" element={<EventCategoryScreen />} />
            <Route path="offhire/event-type" element={<EventTypeScreen />} />
            <Route path="offhire/machinery-type" element={<MachineryTypeScreen />} />
            <Route path="offhire/machinery" element={<MachineryScreen />} />
            <Route path="offhire/event_days" element={<OffhireEventDaysScreen />} />

            <Route path="offhire/employee" element={<EmployeeScreen />} />
            <Route path="offhire/fleet" element={<FleetScreen />} />
            <Route path="offhire/port" element={<PortScreen />} />
            <Route path="offhire/role" element={<RoleScreen />} />
            <Route path="offhire/vessel" element={<VesselScreen />} />
            <Route path="offhire/users" element={<UsersScreen />} />

            {/* aa */}
            <Route path="offhire" element={<SmallFormsScreen cardTitle="Create" />}>
              <Route path="event-category/add" element={<AddEventsCategoryForm />} />
              <Route path="event-type/add" element={<AddEventTypeForm />} />
              <Route path="machinery-type/add" element={<AddMachineryTypeForm />} />
              <Route path="machinery/add" element={<AddMachineryForm />} />
              <Route path="event_days/edit" element={<OffhireEventDaysEditForm />} />

              <Route path="vessel/add" element={<AnAddVesselForm />} />
              <Route path="fleet/add" element={<AnAddFleetForm />} />
              <Route path="users/add" element={<AnCreateUser />} />
              <Route path="budget/add" element={<AddBudgetItemForm />} />
            </Route>
            {/* <Route path="event/create" element={<CreateEventScreen />} /> */}

            {/* Budget */}
            <Route path="budget" element={<OffhireBudgetScreen />} />

            {/* Default Route */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
