import React, { Component } from "react";
import { formatDatetime } from "../../core/utility/conversion";
import { Navigate } from "react-router-dom";
import Col4 from "../../core/components/other/Col4";
import Row from "../../core/components/other/Row";
import "./ORTUpdateHistory.css";
import { getFieldNameByBEKey } from "../util/fields";
import AnSpinner from "../../core/components/other/AnSpinner";
import { BaseService } from "../services/offhire-services";

class ORTUpdateHistory extends Component {
  state = {
    isLoggedIn: true,
    isLoaded: false,
    history: [],
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getReportChangeComponent = (
    changedAt,
    changedBy,
    reportFields,
    attributesToShow
  ) => {
    return (
      <div className="event mt-3">
        <div className="knob"></div>
        {attributesToShow.changedAt.isActive && (
          <div className="date">
            <h6>{changedAt}</h6>
          </div>
        )}
        <div className="description">
          {reportFields.map((reportField) => {
            return (
              <p>
                {attributesToShow.fieldName.isActive && (
                  <strong>{getFieldNameByBEKey(reportField.field_name)}</strong>
                )}
                {attributesToShow.fieldOldValue.isActive && (
                  <span className="ml-3"> {reportField.field_old_value}</span>
                )}
                {attributesToShow.fieldCurrentValue.isActive && (
                  <span className="ml-3">
                    {" "}
                    {this.getFieldCurrentValue(reportField)}
                  </span>
                )}
                {attributesToShow.fieldChangedAt.isActive && (
                  <span className="ml-3">
                    {" "}
                    {formatDatetime(reportField.created_at)}
                  </span>
                )}
                {attributesToShow.reportChangedAt.isActive && (
                  <span className="ml-3">
                    {formatDatetime(
                      reportField.report_data_change?.data_changed_at
                    )}
                  </span>
                )}
              </p>
            );
          })}
          {attributesToShow.changedBy.isActive && (
            <p>
              <small>Updated By: </small>
              {changedBy}
            </p>
          )}
        </div>
      </div>
    );
  };

  getFieldCurrentValue = (reportField) => {
    return reportField.field_name.endsWith("_id")
      ? reportField.report_data_change?.technical_report[
          reportField.field_name.substring(0, reportField.field_name.length - 3)
        ]?.name
      : reportField.report_data_change?.technical_report[
          reportField.field_name
        ];
  };

  trStyle = {borderTop:'.1px solid #dee2e6'};

  getReportChangeComponentTabular = (
    changedAt,
    changedBy,
    reportFields,
    attributesToShow
  ) => {
    return (
      <>
        {reportFields.map((reportField, index) => {
          return (
            <tr style={this.trStyle}>
              {index === 0 && (
                <>
                  {attributesToShow.changedAt.isActive && (
                    <td rowSpan={reportFields.length}>
                      <b>{changedAt}</b>
                    </td>
                  )}
                  {attributesToShow.changedBy.isActive && (
                    <td rowSpan={reportFields.length}>{changedBy}</td>
                  )}
                </>
              )}
              {attributesToShow.fieldName.isActive && (
                <td>{getFieldNameByBEKey(reportField.field_name)}</td>
              )}
              {attributesToShow.fieldOldValue.isActive && (
                <td> {reportField.field_old_value ?? ""}</td>
              )}
              {attributesToShow.fieldCurrentValue.isActive && (
                <td>{this.getFieldCurrentValue(reportField)}</td>
              )}
              {attributesToShow.fieldChangedAt.isActive && (
                <td>{formatDatetime(reportField.created_at)}</td>
              )}
              {attributesToShow.reportChangedAt.isActive && (
                <td>
                  {formatDatetime(
                    reportField.report_data_change?.data_changed_at
                  )}
                </td>
              )}
            </tr>
          );
        })}
      </>
    );
  };

  showUpdateHistory = (changedFields, attributesToShow, look = "tabular") => {
    if (look === "tabular") {
      return (
        <Row className="justify-content-center w-100">
          <div className="col-sm-12 p-3">
            <table className="table table-sm" style={{ fontSize:'0.9rem', textAlign:'center'}}>
              <tr style={this.trStyle}>
                {Object.keys(attributesToShow).map((key) => {
                  if (attributesToShow[key].isActive) {
                    return <th>{attributesToShow[key].title}</th>;
                  }
                  return null;
                })}
              </tr>
              {this.groupChangedFields(
                changedFields,
                this.getReportChangeComponentTabular,
                attributesToShow
              )}
            </table>
          </div>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col4>
            {this.groupChangedFields(
              changedFields,
              this.getReportChangeComponent,
              attributesToShow
            )}
          </Col4>
        </Row>
      );
    }
  };

  //Group changed fields by ReportChangedId
  groupChangedFields = (
    changedFields,
    groupedFielsComponent,
    attributesToShow
  ) => {
    let lastReportChange = "";
    let lastModifiedAt = null;
    let reportFields = [];
    return changedFields.map((fieldChange, index) => {
      const fieldModifiedAt = formatDatetime(
        fieldChange.report_data_change?.data_changed_at
      );
      const isDifferentReportChange =
        fieldChange.report_data_change_id !== lastReportChange;
      const isNotFirstRun = lastReportChange !== "";
      const isLastRun = index === changedFields.length - 1;
      let reportUpdateComponent = <></>;

      if ((isDifferentReportChange && isNotFirstRun) || isLastRun) {
        if (isLastRun) {
          reportFields.push(fieldChange);
        }
        reportUpdateComponent = groupedFielsComponent(
          lastModifiedAt,
          fieldChange.created_by,
          reportFields,
          attributesToShow
        );
        reportFields = [];
      }
      lastReportChange = fieldChange.report_data_change_id;
      lastModifiedAt = fieldModifiedAt;
      reportFields.push(fieldChange);
      return reportUpdateComponent;
    });
  };

  componentDidMount() {
    const { reportID } = this.props;
    if (reportID) {
      const link = "/reports/history/" + reportID;
      BaseService.get(link)
        .then((response) => {
          // debugger;
          if (response.status === 201 || response.status === 200) {
            this.setState({ history: response.data, isLoaded: true });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.setState({ isLoggedIn: false });
          }
          this.setState({ isLoaded: true });
        });
    }
  }

  render() {
    const { history, isLoaded, isLoggedIn } = this.state;
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    if (!isLoaded) {
      return <AnSpinner/>;
    }
    if (!history) {
      return <div>Change History is not Available</div>;
    }
    let attributesToShow = {
      changedAt: {
        title: "Changed At",
        isActive: true,
      },
      changedBy: {
        title: "Changed By",
        isActive: true,
      },
      fieldName: {
        title: "Changed Field Name",
        isActive: true,
      },
      fieldOldValue: {
        title: "Old Value",
        isActive: false,
      },
      fieldCurrentValue: {
        title: "Current Value",
        isActive: false,
      },
      fieldChangedAt: {
        title: "Field Changed At",
        isActive: false,
      },
      reportChangedAt: {
        title: "Report Changed At",
        isActive: false,
      },
    };
    return (
      <>
        {/* <h6>Update History</h6> */}
        {this.showUpdateHistory(history, attributesToShow, "tabular")}
      </>
    );
  }
}

export default ORTUpdateHistory;
