import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function MachineryTypeForm(props) {

    const labels = {
        machinery_type_name: {
          isGroupTitle: false,
          title: "Name",
          type: "text",
          value: "",
        },
        sfi_code: {
          isGroupTitle: false,
          title: "SFI Code",
          type: "text",
          value: "",
        },
        machinery_type_description: {
          isGroupTitle: false,
          title: "Description",
          type: "text",
          value: "",
        },
      };

    const getFieldsMap = () => {
        // front:back
        return {
            machinery_type_name: "name",
            sfi_code: "sfi_code",
            machinery_type_description: "description",
        };
    };

    return (
        <AnCommonFormDialog
            {...props}
            header="Machinery Type"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/machinery/type/"
            valueChangeInterceptor={(key, value) => {
                if (key === "machinery_type_name")
                    return value.toUpperCase();
                return value;
            }}
        />
    );
}

export default MachineryTypeForm