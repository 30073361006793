import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import NavLink from "../../components/other/NavLink";

function NmBreadcrumb({ items }) {
  const ITEMS = items === "Home" ? undefined : items;
  const HOME_ITEM = {
    icon: "pi pi-home",
    template: () => (
      <NavLink path="/" icon="home" title="Home" className="px-0" />
    ),
  };

  return (
    <div className="nm-breadcrumb-container">
      <BreadCrumb
        className="py-0 pl-0"
        model={ITEMS}
        home={HOME_ITEM}
        style={{ background: "none" }}
      />
    </div>
  );
}

export default NmBreadcrumb;
