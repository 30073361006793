import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AnLabelValue from "../../core/components/factory/AnLabelValue";
import Col4 from "../../core/components/other/Col4";
import Row from "../../core/components/other/Row";
import {
  formatDatetime,
  getDaysBetweenDates,
} from "../../core/utility/conversion";
import { OffhireReportChangeHistoryService } from "../services/offhire-services";

class OffhireReportTechnicalUpdateHistory extends Component {
  state = {
    isHistoryLoaded: false,
    history: [],
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  componentDidMount() {
    const { reportID } = this.props;
    if (reportID) {
      OffhireReportChangeHistoryService.get(reportID)
        .then((response) => {
          // debugger;
          if (response.status === 201 || response.status === 200) {
            this.setState({ history: response.data, isHistoryLoaded: true });
          }
        });
    }
  }

  render() {
    const { history, isHistoryLoaded } = this.state;
    if (!isHistoryLoaded) {
      return <div>Loading...</div>;
    }
    return (
      <Row className="m-3">
        <table>
          <tr>
            {/* <th>Field Date</th> */}
            <th>Modified At</th>
            <th>Modified By</th>
            <th>Modified Field</th>
            <th>Field's Old Value</th>
          </tr>
          {history.map((fieldChange, index) => {
            return (
              <tr>
                {/* <td>{formatDatetime(fieldChange.created_at)}</td> */}
                <td>
                  {formatDatetime(
                    fieldChange.report_data_change?.data_changed_at
                  )}
                </td>
                <td>{fieldChange.created_by}</td>
                <td>{fieldChange.field_name}</td>
                <td>{fieldChange.field_old_value}</td>
              </tr>
            );
          })}
        </table>
      </Row>
    );
  }
}

export default OffhireReportTechnicalUpdateHistory;
