export const CoreAPIRoutes = {
    // BUDGET : '/events/item/planned/',
    // DASHBOARD : '/',
    // EVENT_CATEGORY : '/events/category/',
    // EVENT_DAYS : '/reports/event_days/',
    // EVENT_TYPE : '/events/type/',
    // MACHINERY : '/machinery/',
    // REPORT : '/reports/combined/',
    // REPORT_ATTATCHMENT : '/reports/upload/technical',
    // REPORT_COMMERCIAL : '',
    // REPORT_TECHNICAL : '',
    // REPORT_GROUP : '/reports/group/',
    // PORT : '/port/',
    // SEA : '/sea/',

    ROLE: '/role/',
    EMPLOYEE: '/employee/',
    USER: '/users/',
    FLEET: '/fleet/',
    VESSEL: '/vessel/',

}