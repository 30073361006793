import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import MachineryForm from "../forms-dialog/MachineryForm";

function MachineryScreen() {
    return (
      <CommonListViewScreen
        path="/machinery/"
        dataPreprocessor={{
          mainTitle: "Machineries",
          path: "/offhire/machinery",
          columns: [
            {  header: "ID", field: "id", width: "10%"},
            {  header: "Name", field: "name", width: "20%" },
            {  header: "Description", field: "description", width: "40%" },
            {  header: "Machinery Type", field: "machinery_type.name", width: "20%" },
          ]
        }}
      >
        <MachineryForm />
      </CommonListViewScreen>
    );
}

export default MachineryScreen;
