import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import AnSideBarOptions from "../../core/components/other/AnSideBarOptions";

class OffhireAdminSidebar extends Component {
  state = {};
  render() {
    const title = "Offhire Admin";
    const links = [
      // { path: "/user/new", title: "Add User", icon: "users" },
      {
        path: "/offhire/event-category",
        title: "Event Category",
        icon: "calendar",
      },
      { path: "/offhire/event-type", title: "Event Type", icon: "calendar" },
      {
        path: "/offhire/machinery-type",
        icon: "cog",
        title: "Machiney Type",
      },
      { path: "/offhire/machinery", icon: "cog", title: "Machinery" },
      { path: "/budget", icon: "book", title: "Budget" },
      { path: "/offhire/fleet", icon: "ship", title: "Fleet", span: "UC" },
      { path: "/offhire/vessel", icon: "ship", title: "Vessel", span: "UC" },
      { path: "/offhire/port", icon: "ship", title: "Port", span: "UC"},
      {
        path: "/offhire/employee",
        title: "Employees",
        icon: "users",
        span: "UC",
      },
      { path: "/offhire/role", title: "Roles", icon: "users", span: "UC" },
      { path: "/offhire/users", title: "Users", icon: "users", span: "UC" },
    ];
    return (
      <React.Fragment>
        {React.cloneElement(this.props.children, { ...this.props, links, title })}
      </React.Fragment>
      // <AnSideBarOptions links={links} />
    );
  }
}

export default OffhireAdminSidebar;
