import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";
import { getOptionsArrayForSelectElement } from "../util/first";

function BudgetForm(props){
    const labels = {
        planned_event: {
            isGroupTitle: false,
            title: "Planned Event",
            type: "select",
            options: [
            {
                value: "",
                title: "-Select Event-",
            },
            ],
            optionsFetch: {
                api: "/events/type/",
                responseHandler: (eventTypes)=>{
                    return this.getOptionsArrayForSelectElement(
                        eventTypes.filter((eventType) => 
                        eventType?.offhire_events_category?.name === "Planned")
                      )},
              } 
        },
        event_name: {
            isGroupTitle: false,
            title: "Event Name",
            type: "text",
            value: "",
        },
    
        vessel: {
            isGroupTitle: false,
            title: "Vessel",
            type: "select",
            options: [
            {
                value: 0,
                title: "-Select Vessel-",
            },
            ],
            // readOnly: true,
            optionsFetch: {
                api: "/vessel/options",
                responseHandler: (vessels) => getOptionsArrayForSelectElement(
                    vessels,
                    "vessel_code",
                    "vessel_name"
                  ),
              } 
        },
        // event_year_month: {
        //   isGroupTitle: false,
        //   title: "Month",
        //   type: "month",
        //   min: new Date().toISOString().slice(0, 7),
        // },
        // days: {
        //   isGroupTitle: false,
        //   title: "Days",
        //   type: "text",
        // },
        event_start_date: {
            isGroupTitle: false,
            title: "Event Start Date",
            type: "datetime-local",
        },
        event_end_date: {
            isGroupTitle: false,
            title: "Event End Date",
            type: "datetime-local",
        },
        days: {
            isGroupTitle: false,
            title: "Days",
            type: "text",
            readOnly: true,
            required: false,
        },
        cost: {
            isGroupTitle: false,
            title: "Cost",
            type: "number",
        },
        };
  
      const getFieldsMap = () => {
        // front:back
        return {
          planned_event: "planned_event_type_id",
          event_name: "event_name",
          vessel: "vessel_code",
          // event_year_month: "event_year_month",
          // days: "event_days",
          event_start_date: "start_date",
          event_end_date: "end_date",
          cost: "operation_cost",
          days: "duration_in_days",
        };
      };
  
    return (
        <AnCommonFormDialog
            {...props}
            header="Budget Item"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/events/item/planned"
            valueChangeInterceptor={(key, value, feilds) => {
                if (key === "event_start_date")
                    feilds["days"] = this.getDaysBetweenDates(value, feilds["event_end_date"]);
                
                if (key === "event_end_date") {
                    const diff = this.getDaysBetweenDates(feilds["event_start_date"], value);
                    if (diff < 0) {
                      alert("End date should be greater than Start date");
                      return value;
                    }
                    feilds["days"] = diff;
                  }
                return value;
            }}
        />
    );
}

export default BudgetForm;