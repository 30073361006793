class Storage {
  constructor(astorage) {
    this.storage = astorage;

    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
    this.remove = this.remove.bind(this);
    this.clear = this.clear.bind(this);
  }

  set(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    return JSON.parse(this.storage.getItem(key));
  }

  remove(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}

export const storage = new Storage(window.sessionStorage);
export const storageLocal = new Storage(window.localStorage);
