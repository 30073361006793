export function getFieldsKeyMap() {
  // frontend:backend
  return {
    vessel_name: "vessel_name",
    name: "name",
    vessel_code: "vessel_code",
    voyage_number: "voyage_number",
    voyage_status: "voyage_status",
    commenced_at: "commenced_at",
    completed_at: "completed_at",
    activity: "activity",
    offhire_or_stoppage: "activity",
    delay_start_at_gmt: "commercial_delay_start_at_gmt",
    delay_end_at_gmt: "commercial_delay_end_at_gmt",
    reason: "commercial_reason",
    tci: "tci",
    tco: "tco",
    // id: "id",
    delay_year: "delay_year",
    delay_start_at_local: "delay_start_at_local",
    delay_end_at_local: "delay_end_at_local",
    hours: "hours",
    custom_offhire_days: "custom_offhire_days",
    custom_offhire_days_group: "custom_offhire_days_group",
    // ------------------

    id: "offhire_report_commercial_id",
    offhire_type: "event_type_id",
    machinery_subtype: "machinery_id",
    from_datetime: "delay_start_at_gmt",
    to_datetime: "delay_end_at_gmt",
    days: "duration_in_days",
    event: "event",
    // vessel_age: "vessel_age",
    location: "location",
    place: "place_id",
    sea: "sea_id",
    port: "port_id",
    ship_manager_comment: "ship_manager_comment",
    //--------------------------
    machinery_type: "machinery_type_id",
    sfi_code: "sfi_code",
    events_category: "events_category_id",
    is_planned: "is_planned",
    fleet_manager_comment: "fleet_manager_comment",
    approved_by: "fleet_manager_name",
    subbmited_by: "ship_manager_name",
    ship_manager_submited_on: "ship_manager_submited_on",
    fleet_manager_submited_on: "fleet_manager_submited_on",
  };
}

export function getFields() {
  return {
    offhire_report_commercial_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Offhire Report (Commercial)",
    },
    vessel_name: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Vessel Name",
      type: "text",
      value: "string",
    },
    name: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Name",
      type: "text",
      value: "string",
    },
    vessel_code: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Vessel Code",
      type: "text",
      value: 0,
    },
    voyage_number: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voyage Number",
      type: "text",
      value: 0,
    },
    voyage_status: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voyage Status",
      type: "text",
      value: "string",
    },
    commenced_at: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voayge Commenced At",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.899Z",
    },
    completed_at: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Voayge Completed At",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.899Z",
    },
    activity: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Activity",
      type: "text",
      value: "string",
    },
    delay_start_at_gmt: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Start At (GMT)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_start_at_local: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Start At (Local)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_end_at_gmt: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay End At (GMT)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_end_at_local: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay End At (Local)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    reason: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Reason",
      type: "text",
      value: "string",
    },
    tci: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "TCI %",
      type: "text",
      value: 0,
    },
    tco: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "TCO %",
      type: "text",
      value: 0,
    },

    delay_year: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Year",
      type: "text",
      value: 0,
    },

    hours: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Duration (in Hours)",
      type: "text",
      value: 0,
    },
    custom_offhire_days: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Delay Duration (in Days)",
      type: "text",
      value: 0,
    },
    offhire_or_stoppage: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Offhire/Stoppage",
      type: "text",
      // type: "select",
      options: [
        {
          value: true,
          title: "Offhire",
        },
        {
          value: false,
          title: "Stoppage",
        },
      ],
    },
    // custom_offhire_days_group: {
    //   isGroupTitle: false,
    //   readOnly: true,
    //   required: false,
    //   title: "Offhire Days Group",
    //   type: "text",
    //   value: "string",
    // },
    offhire_report_technical_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Offhire Report (Technical)",
    },
    offhire_report_technical_section_p1: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 1:",
    },

    from_datetime: {
      isGroupTitle: false,
      title: "From Date Time",
      type: "datetime-local",
    },
    to_datetime: {
      isGroupTitle: false,
      title: "To Date Time",
      type: "datetime-local",
    },
    days: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Days",
      type: "text",
    },
    offhire_report_technical_section_p2: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 2:",
    },
    offhire_type: {
      isGroupTitle: false,
      title: "Offhire Type",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Offhire Type-",
        },
      ],
    },
    // events_category: {
    //   isGroupTitle: false,
    //   readOnly: true,
    //   required: false,
    //   title: "Events Category",
    //   type: "select",
    //   // type: "select",
    //   options: [
    //     {
    //       value: "",
    //       title: "Select",
    //     },
    //     // {
    //     //   value: true,
    //     //   title: "Planned",
    //     // },
    //     // {
    //     //   value: false,
    //     //   title: "Unplanned",
    //     // },
    //   ],
    // },
    is_planned: {
      isGroupTitle: false,
      title: "Planned/Unplanned",
      type: "select",
      options: [
        {
          value: "",
          title: "Select",
        },
        {
          value: true,
          title: "Planned",
        },
        {
          value: false,
          title: "Unplanned",
        },
      ],
    },
    filler: {
      isGroupTitle: false,
      type: "filler",
    },
    machinery_type: {
      isGroupTitle: false,
      title: "Machinery Type",
      type: "search-select",
      options: [
        {
          value: "",
          title: "-Select Machinery Type-",
        },
      ],
    },
    sfi_code: {
      isGroupTitle: false,
      title: "SFI Code",
      type: "text",
      readOnly: true,
      required: false,
    },
    machinery_subtype: {
      isGroupTitle: false,
      title: "Machinery Subtype",
      type: "search-select",
      options: [
        {
          value: "",
          title: "-Select Machinery Subtype-",
        },
      ],
    },
    offhire_report_technical_section_p3: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Group 3:",
    },
    event: {
      isGroupTitle: false,
      title: "Event",
      maxlength: "30",
      type: "text",
      value: "string",
    },
    // ...
    // vessel_age: {
    //   isGroupTitle: false,
    //   title: "Vessel Age",
    //   type: "text",
    //   readOnly: true,
    //   required: false,
    // },
    location: {
      isGroupTitle: false,
      title: "Location",
      type: "select",
      options: [
        {
          value: "",
          title: "-- Select Location --",
        },
        {
          value: "SEA",
          title: "Sea",
        },
        {
          value: "PORT",
          title: "Port",
        },
      ],
      // readOnly: true,
      // required: false,
    },
    // place: {
    //   isGroupTitle: false,
    //   title: "Place",
    //   type: "datalist-input",
    //   options: [
    //     {
    //       value: "",
    //       title: "-- Select Place --",
    //     },
    //   ],
    //   linkSelectByKey: "port",
    //   link: [
    //     {
    //       key: "sea",
    //       link: "sea/all",
    //       fetch: "once",
    //     },
    //     {
    //       key: "port",
    //       link: "port/l/",
    //     },
    //   ],
    //   // readOnly: true,
    //   required: false,
    // },
    sea: {
      isGroupTitle: false,
      title: "Place (Sea)",
      type: "select",
      options: [
        {
          value: "",
          title: "--Select Sea--",
        },
      ],
      //passing this feild value to 'place'
      required: false,
    },
    port: {
      isGroupTitle: false,
      title: "Place (Port)",
      type: "search-select",
      options: [
        {
          value: "",
          title: "--Select Port--",
        },
      ],
      //passing this feild value to 'place'
      required: false,
    },

    // ...

    shipmanager_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Ship Manager Section",
    },
    ship_manager_comment: {
      isGroupTitle: false,
      title: "Ship Manager Comment",
      type: "text",
      value: "string",
    },
    subbmited_by: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Submitted By",
      type: "text",
    },
    ship_manager_submited_on: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Created/Last Updated On",
      type: "datetime-local",
    },
    fleetmanager_section: {
      isGroupTitle: true,
      readOnly: true,
      required: false,
      title: "Fleet Manager Section",
    },
    fleet_manager_comment: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Fleet Manager Comment",
      type: "text",
      value: "string",
    },
    approved_by: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Approved / Rejected By",
      type: "text",
    },
    fleet_manager_submited_on: {
      isGroupTitle: false,
      readOnly: true,
      required: false,
      title: "Last Action Time",
      type: "datetime-local",
    },
  };
}

export function getFieldNameByBEKey(backendKey) {
  const fields = getFields();
  const fieldsKeyMap = getFieldsKeyMap();
  for (let key in fieldsKeyMap) {
    if (fieldsKeyMap[key] === backendKey) {
      return fields[key].title;
    }
  }
  return "";
}

export function getFieldNameByFEKey(frontendKey) {
  const fields = getFields();
  if (frontendKey in fields) {
    return fields[frontendKey].title;
  }
  return "";
}
