import React from "react";
import { Navigate } from "react-router-dom";

import AnHPForm from "./factory/AnHPForm";
import AnInlineFormGroup from "./factory/AnInlineFormGroup";
import AnBordered from "../factory/AnBordered";
import { getAuthHeader } from "../../application/connection";
import UserFetchService from "../../services/UserFetchService";
import { getOptionsArrayForSelectElement, getSubmitButton } from "../../../offhire/util/first";
import EmployeeFetchService from "../../services/EmployeeFetchService";
import RoleFetchService from "../../services/RoleFetchService";
import AnSpinner from "../other/AnSpinner";
import { UserService } from "../../../offhire/services/offhire-services";

class AnCreateUser extends React.Component {
  state = {
    isLoggedIn: true,
    isLoaded: true,
    onProgress: false,
    error: null,
    errorMsg: "",
  };

  //Feilds object
  labels = {
    username: {
      isGroupTitle: false,
      title: "Username",
      type: "text",
    },
    password: {
      isGroupTitle: false,
      title: "Password",
      type: "password",
    },
    confirm_password: {
      isGroupTitle: false,
      type: "password",
      title: "Confirm Password",
    },
    employee: {
      isGroupTitle: false,
      title: "Employee",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Employee-",
        },
      ],
    },
    role: {
      isGroupTitle: false,
      title: "Role",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Role-",
        },
      ],
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  //   getTommorowDate() {
  //     const today = new Date();
  //     const tomorrow = new Date(today);
  //     tomorrow.setDate(today.getDate() + 1);
  //     return tomorrow.toISOString().slice(0, 10);
  //   }

  getFieldsMap = () => {
    return {
      username: "username",
      password: "password",
      confirm_password: "confirm_password",
      employee: "employee_id",
      role: "role_id",
    };
  };

  updateFeilds = (responseFeildsObject) => {
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];
      if (value !== undefined) {
        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({ isLoaded: true, feilds });
  };

  getPostRequestObject = () => {
    const feildsMap = this.getFieldsMap();
    const formFeildsState = this.state.feilds;
    let postRequestObject = {};

    if (formFeildsState["password"] !== formFeildsState["confirm_password"]) {
      alert("Password and Confirm Password must be same.");
      return;
    }

    if (formFeildsState["password"].length < 8) {
      alert("Password must be at least 8 characters long.");
      return;
    }

    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = formFeildsState[key];

      if (value.length === 0) {
        const feildName = key.split("_").join(" ").toUpperCase();
        alert("Error:" + feildName + " value is empty");
        return null;
      }

      postRequestObject[feildKey] = value;
    }
    return postRequestObject;
  };

  responceError = (error) => {
    // alert("Error: " + error.response.status);
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.setState({
        onProgress: false,
        error: "No data found for the given dates.",
      });
    } else {
      this.setState({
        onProgress: false,
        error: "Error: " + error.response.statusText,
      });
    }
  };

  async componentDidMount() {
    EmployeeFetchService.getAll().then(
      (response) => {
        if (response.status === 200) {
          // debugger;
          const employees = response.data;
          const employeeOptions = getOptionsArrayForSelectElement(employees);
          this.setState({
            isEmployeeLoaded: true,
            employees,
            feildsOptions: {
              ...this.state.feildsOptions,
              employee : employeeOptions
            },
          });
        }
      },
      (error) => this.responceError(error)
    );


    RoleFetchService.getAll().then(
      (response) => {
        if (response.status === 200) {
          // debugger;
          const roles = response.data;
          const roleOptions = getOptionsArrayForSelectElement(roles);
          this.setState({
            isRoleLoaded: true,
            roles,
            feildsOptions: {
              ...this.state.feildsOptions,
              role : roleOptions
            },
          });
        }
      },
      (error) => this.responceError(error)
    );
    
  }

  handleValueChange = (e) => {
    let { feilds } = this.state;
    let key = e.target.name;
    let value = e.target.value.toString();
    feilds[key] = value;
    this.setState({ feilds });
  };

  handleHullPerfTrendsState = (event) => {
    this.setState({ onProgress: true });

    const postRequestObject = this.getPostRequestObject();
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }

    UserService.create(postRequestObject)
      .then(
        (response) => {
          // console.log("Post Response");
          // console.log(response);
          // console.log(this.props);
          if (response.data) {
            this.updateFeilds(response.data);
            this.setState({ onProgress: false });
          }
          if (response.status === 200 || response.status === 201) {
            alert("User created Successfully");
            // this.props.doRefreshTrends();
          }

          //   console.log(response);
        },
        (error) => {
          alert("Error: " + error.response.status);
          //   console.log("Error");
          //   console.log(error);
          if (error.response.status === 401) {
            this.setState({ isLoggedIn: false });
          }
          this.setState({
            onProgress: false,
            isLoaded: true,
            errorMsg: "Error: " + error.response.statusText,
          });
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        }
      );
    event.preventDefault();
  };


  render() {
    const { feilds, feildsOptions, isLoaded, isLoggedIn, errorMsg } = this.state;

    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    if (!isLoaded) return <AnSpinner />;

    let formFeildsArr = [];
    for (let key in this.labels) {
      const selectOptions = this.labels[key].options ?? [
        { value: "", title: "--Select--" },
      ];

      // let { title, isGroupTitle, type, readOnly, options, required, min, max } =
      //   this.labels[key];

      formFeildsArr.push({
        ...this.labels[key],
        name: key,
        // title: title,
        // type: type,
        // readOnly: readOnly,
        // isGroupTitle: isGroupTitle,
        value: feilds[key],
        onChangeHandler: this.handleValueChange,
        // options: options,
        // required: required,
        // min: min,
        // max: max,
        options: [...selectOptions, ...(feildsOptions?.[key] ?? [])],
      });
    }

    return (
      <>
        <AnHPForm
            onBack={this.props.onBack}
            onSubmit={this.handleHullPerfTrendsState}
          >
            {formFeildsArr.map((attribute, index) => {
              return (
                <>
                  <AnInlineFormGroup
                    attribute={attribute}
                    key={index}
                    value={this.state.feilds}
                    onChange={this.handleValueChange}
                  />
                </>
              );
            })}
            {getSubmitButton(
              "Create New User",
              false,
              this.state.onProgress,
              this.state.error
              )}
          </AnHPForm>
          {errorMsg?.length > 0 && (
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

export default AnCreateUser;
