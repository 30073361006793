import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./AnSideBar.css";
import "../design/Glassmorphic.css";

class AnSideBar extends Component {
  state = {
    isMinimised: true,
  };
  showSideBar = () => {
    const { onToggle } = this.props;
    if (onToggle)
      onToggle(true);
    // console.log("showSideBar");
    this.setState({
      isMinimised: false,
    });
  };

  hideSidebar = () => {
    const { onToggle } = this.props;
    if (onToggle)
      onToggle(false);
    // console.log("hideSidebar");
    this.setState({
      isMinimised: true,
    });
  };

  render() {
    const { isMinimised } = this.state;
    const { children, title, col, displaySizeMargin } = this.props;
    const sidebarStateClass = isMinimised ? "" : "active";
    return (
      <div className="an-sidebar ">
        <Link
          to="#"
          className={"an-menu-bar " + sidebarStateClass}
          onClick={this.showSideBar}
        >
          <i className="fa fa-caret-right"></i>
        </Link>
        <nav className={"an-sidebar-nav glassed shadow " + sidebarStateClass}>
          <div className="an-sidebar-nav-list">
            <div className="an-sidebar-nav-item" onClick={this.hideSidebar}>
              <Link to="#" className="an-sidebar-nav-link">
                <i className="fa fa-caret-left"></i>
              </Link>
            </div>
            {/* <div className="an-sidebar-nav-item">
                    <Link to="#" className="an-sidebar-nav-link">
                        <i className="fa fa-user"></i>
                        <span>Profile</span>
                    </Link>
                </div> */}
            <div className="an-sidebar-nav-item">{children}</div>
          </div>
        </nav>
      </div>
    );
  }
}

export default AnSideBar;
