import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import IconButton from "../buttons/IconButton";


function EditableText({ value, onChange, onSubmit }) {
    const [isInEditMode, setIsInEditMode] = React.useState(false);
    const [text, setText] = React.useState(value);
    const handleEdit = () => {
      setIsInEditMode(true);
    }
    const handleSubmit = () => {
      setIsInEditMode(false);
      onSubmit(text);
    }
    return (
      <div>
        {isInEditMode
        ? <>
          <InputText value={text} onChange={(e) => { onChange?.(e.target.value); setText(e.target.value)}} />
          <IconButton iconName="check" onClick={handleSubmit} />
        </> 
        : <>
        <span>{value}</span>
        <IconButton iconName="pencil" onClick={handleEdit} />
      </>                   
        }
      </div>
    );
  }

export default EditableText;