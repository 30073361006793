import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import VesselOffhireReport from "./VesselOffhireReport";
import AnSpinner from "../../core/components/other/AnSpinner";
import {
  ofhrReportCommercialDataTitle,
  ofhrReportCommercialDatesOverlaping,
  ofhrReportTechnicalDataTitle,
  ofhrReportTechnicalDatesDifferent,
} from "../application/constants";
import FilterSelect from "./FilterSelect";
import { OffhireTableDef } from "../constants/reports.table-def";
import { OffhireReportService } from "../services/OffhireReportService";
import { ReportLegends } from "../util/first";
import { VesselService } from "../services/offhire-services";
import { AnDialog } from "../../core/components/other/AnDialog";
import OffhireReportView from "./OffhireReportView";
import { Paginator } from "primereact/paginator";
import NmBreadcrumb from "../../core/layout/components/NmBreadcrumb";
import { EachItem } from "../../core/components/hoc/EachItem";
import VesselOffhireReportGroup from "./VesselOffhireReportGroup";

class FleetOffhireReports extends Component {
  state = {
    isLoggedIn: true,
    isLoaded: false,
    onProgress: false,
    error: null,

    // isReportsLoaded: false,
    reports: [],
    reportsIdForApproval: [],
    reportsIdToRemove: [],

    sortReportsBy: "",
    vessel_code: "",
    vessel_name: "",
    vesselOptions: [],
    year: new Date().getFullYear(),
    filterByReportStatus: { value: "", label: "All" },
    filterByVessel: [],
    filterByYear: [
      // {
      //   value: new Date().getFullYear(),
      //   label: new Date().getFullYear(),
      // },
    ],
    filterByMonth: [],
    page: 1,

    actionDialog: {
      visible: false,
      action: "",
      state: null,
    }
  };

  onFetchSuccess = (response, firstFetch = false) => {
    if (response.status === 200) {
      const { results: REPORTS, total: totalReports, page, size: pageRows } = response.data;
      const pageFirst = pageRows * (page - 1);
      
      if (firstFetch) {
        const vesselsMap = new Map();
        REPORTS.forEach((report) => {
          if (!vesselsMap.has(report.vessel_code)) {
            vesselsMap.set(report.vessel_code, report.vessel_name);
          }
        });
        const filteredVessels = [];
        vesselsMap.forEach((vessel_name, vessel_code) => {
          filteredVessels.push({ value: vessel_code, label: vessel_name });
        });
        this.setState({
          filterByVessel: filteredVessels,
        });
      }

      this.setState({
        onProgress: false,
        isLoaded: true,
        reports: REPORTS,
        totalReports,
        pageFirst,
        pageRows,
      });
    }
  };

  onFetchFailed = (error, resetReports = false) => {
    const ERROR_MESSAGE = error.response?.data?.detail;
    if (error.response?.status === 401) {
      this.setState({ isLoggedIn: false });
    }

    if (error.response?.status === 404) {
      this.setState({
        onProgress: false,
        isLoaded: true,
        error: ERROR_MESSAGE ?? "No data found for the given input.",
      });
    } else {
      this.setState({
        onProgress: false,
        isLoaded: true,
        error: ERROR_MESSAGE ?? "Error: " + error.response?.statusText + ", (Not found)",
      });
    }

    alert(ERROR_MESSAGE ?? "Error: " + error.response?.statusText);

    resetReports &&  this.setState({
      reports: [],
    });
  };

  reportsIdForApprovalHandler = (reportId, operation = "add") => {
    const { reportsIdForApproval } = this.state;
    if (operation === "add") {
      reportsIdForApproval.push(reportId);
    } else {
      reportsIdForApproval.splice(reportsIdForApproval.indexOf(reportId), 1);
    }
    this.setState({ reportsIdForApproval });
  };

  reportsIdForRemoveHandler = (reportId, operation = "add") => {
    const { reportsIdToRemove } = this.state;
    operation === "add" 
    ? reportsIdToRemove.push(reportId)
    : reportsIdToRemove.splice(reportsIdToRemove.indexOf(reportId), 1);
    this.setState({ reportsIdToRemove });
  };

  componentDidMount = () => {
    this.setState(
      {...this.getStoredState(),},
      () => this.fetchReportsByFilterMultiOptions(this.state.page)
    );

    VesselService.getAsOptions()
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            vesselOptions: response.data,
          });
        }
      });
  };

  handleSelectFilterChange = (selectedOption) => {
    // let { filterByVessel, filterByYear, filterByMonth } = this.state;
    if (selectedOption.id === "report_filter_vessel") {
      const filterByVessel = selectedOption.value;
      this.setState({
        filterByVessel,
      });
    } else if (selectedOption.id === "report_filter_month") {
      const filterByMonth = selectedOption.value;
      this.setState({
        filterByMonth,
      });
    } else if (selectedOption.id === "report_filter_year") {
      const filterByYear = selectedOption.value;
      this.setState({
        filterByYear,
      });
    } else if (selectedOption.id === "report_filter") {
      const filterByReportStatus = selectedOption.value;
      this.setState({
        filterByReportStatus,
        page: 1,
      });
      return;
    }

    //this.fetchReportsByFilter(filterByVessel, filterByYear);
  };

  getStoredState = () => {
    const state = OffhireReportService.loadFilter();
    if (state)
      return {
        filterByVessel: state.filterByVessel,
        filterByYear: state.filterByYear,
        filterByMonth: state.filterByMonth,
        page: state.pageToFetch,
      };
    return {};
  };

  /**
   *
   * @param {number} pageToFetch
   * @returns
   */
  fetchReportsByFilterMultiOptions = (pageToFetch = 1, pageSize = 10) => {
    const {
      filterByVessel,
      filterByYear,
      filterByMonth,
      filterByReportStatus,
    } = this.state;

    pageToFetch = pageToFetch || 1;

    // Store the filter values in the local storage
    OffhireReportService.storeFilter({
      filterByVessel,
      filterByYear,
      filterByMonth,
      filterByReportStatus,
      pageToFetch,
      pageSize,
    });

    if (!filterByVessel || !filterByYear) {
      alert("Please select empty filter values.");
      return;
    }

    const vessel_codes = filterByVessel.map((option) => option.value);
    const years = filterByYear.map((option) => option.value);
    const months = filterByMonth.map((option) => option.value);

    OffhireReportService
      .getFiltered({
        vessel_codes,
        years,
        months,
        report_status: filterByReportStatus.value,
        page: pageToFetch,
        page_params: {
          page: pageToFetch,
          size: pageSize,
        }
      })
      .then((response) => {
        this.onFetchSuccess(response);
      }, this.onFetchFailed)
      .finally(() => this.setState({ 
        page: pageToFetch,
        reportsIdForApproval: [],
        reportsIdToRemove: [],
       }));
  };

  dateColumnStyle = {
    minWidth: "75px",
  };

  styles = {
    minWidth: {
      minWidth: "150px",
    },
  };

  approveReportsHandler = (isRemove) => {
    const ACTION_NAME = isRemove ? "remove" : "approve";
    if (
      !window.confirm(`Are you sure you want to ${ACTION_NAME} the selected reports?`)
    ) {
      return;
    }

    const { reportsIdForApproval, reportsIdToRemove } = this.state;
    const reportsIdForAction = isRemove ? reportsIdToRemove : reportsIdForApproval;

    if (reportsIdForAction.length === 0) {
      alert(`Please select at least one report to ${ACTION_NAME}.`);
      return;
    }

    (isRemove 
      ? OffhireReportService.removeMultiple(reportsIdForAction)
      : OffhireReportService.approveMultiple(reportsIdForAction))
      .then((response) => {
        if (response.status === 201) {
          alert(`Reports ${ACTION_NAME}d successfully.`);
          this.setState({
            reportsIdForApproval: [],
            reportsIdToRemove: [],
          });
          this.fetchReportsByFilterMultiOptions();
        }
      },
      (error )=>this.onFetchFailed(error, false)
      );
  };

  handleRowAction = ({action, state}) => {
    this.setState({
      actionDialog: {
        visible: true,
        action,
        state,
      }
    });
  }

  handleRowActionClose = () => {
    this.setState({
      actionDialog: {
        visible: false,
        action: "",
        state: null,
      }
    });
  }

  render() {
    const { fleet } = this.props;
    const columns = OffhireTableDef
    const {
      isLoaded,
      isLoggedIn,
      // isReportsLoaded,
      reports,
      vessel_code,
      vessel_name,
      vesselOptions,

      filterByReportStatus,
      filterByVessel,
      filterByYear,
      filterByMonth,

      reportsIdForApproval,
      reportsIdToRemove,
      actionDialog,
      totalReports,
      pageFirst,
      pageRows,
    } = this.state;

    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;

    if (!isLoaded) return <AnSpinner />;

    let vesselReports = reports;
    let currentYear = new Date().getFullYear();
    let vesselFilterDefaultSelectedOption = filterByVessel;
    // let grouped_count = 0;

    const { visible: actionDialogVisible, action: actionDialogAction, state: actionDialogState } = actionDialog;

    return (
      <React.Fragment>
        <AnDialog header="Offhire Report" visible={actionDialogVisible} onHide={this.handleRowActionClose} position="bottom">
          <OffhireReportView reportDirect={actionDialogState}/>
        </AnDialog>

        <NmBreadcrumb items={[{label: "Offhire Reports"}]} />

        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <div className="card-title h4">
                  Fleet {fleet} - Offhire Reports
                </div>
              </div>
              <div className="col-sm-6 col-md-auto align-self-end">
                <div className="row">
                  <div className="col-sm-6 col-md-auto align-self-center bg-white">
                    <Paginator first={pageFirst} rows={pageRows} totalRecords={totalReports} 
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageChange={(e) => {
                      this.fetchReportsByFilterMultiOptions(e.page + 1, e.rows);
                    }}>                      
                    </Paginator>
                  </div>
                  <div className="col-sm-6 col-md-auto">
                    <FilterSelect
                      label="Report Status"
                      id="report_filter"
                      onChange={this.handleSelectFilterChange}
                      options={[
                        { value: "", label: "All" },
                        { value: "INCOMPLETE", label: "Incompleted" },
                        { value: "PENDING", label: "Pending" },
                        { value: "APPROVED", label: "Approved" },
                        { value: "REJECTED", label: "Rejected" },
                      ]}
                      defaultValue={filterByReportStatus}
                    ></FilterSelect>
                  </div>
                  <div className="col-sm-6 col-md-auto">
                    <FilterSelect
                      label="Vessel"
                      closeMenuOnSelect={false}
                      isMulti={true}
                      id="report_filter_vessel"
                      onChange={this.handleSelectFilterChange}
                      options={
                        vesselOptions?.length > 0
                          ? vesselOptions.map((vesselOpt) => {
                              return {
                                value: vesselOpt.vessel_code,
                                label: vesselOpt.vessel_name,
                              };
                            })
                          : { value: vessel_code, label: vessel_name }
                      }
                      defaultValue={vesselFilterDefaultSelectedOption}
                    ></FilterSelect>
                  </div>

                  <div className="col-sm-6 col-md-auto">
                    <FilterSelect
                      label="Month"
                      closeMenuOnSelect={false}
                      isMulti={true}
                      id="report_filter_month"
                      onChange={this.handleSelectFilterChange}
                      options={[
                        { value: "", label: "All" },
                        { value: "1", label: "JAN" },
                        { value: "2", label: "FEB" },
                        { value: "3", label: "MAR" },
                        { value: "4", label: "APR" },
                        { value: "5", label: "MAY" },
                        { value: "6", label: "JUN" },
                        { value: "7", label: "JUL" },
                        { value: "8", label: "AUG" },
                        { value: "9", label: "SEP" },
                        { value: "10", label: "OCT" },
                        { value: "11", label: "NOV" },
                        { value: "12", label: "DEC" },
                      ]}
                      defaultValue={filterByMonth}
                    ></FilterSelect>
                  </div>
                  <div className="col-sm-6 col-md-auto">
                    <FilterSelect
                      label="Year"
                      closeMenuOnSelect={false}
                      isMulti={true}
                      id="report_filter_year"
                      onChange={this.handleSelectFilterChange}
                      options={[
                        ...Array(5)
                          .fill(currentYear)
                          .map((year, index) => {
                            return {
                              value: currentYear - index,
                              label: currentYear - index,
                            };
                          }),
                        // { value: "1111", label: "All" },
                      ]}
                      defaultValue={filterByYear}
                    ></FilterSelect>
                  </div>
                  <div className="col-sm-6 col-md-auto pt-lg-3">
                    <button
                      className="btn btn-sm btn-secondary mt-lg-3"
                      onClick={() => this.fetchReportsByFilterMultiOptions()}
                    >
                      Apply Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body shadow rounded mt-1">
            <div className="row justify-content-end mb-1">
              <div
                className="col-sm-6 col-md-auto col-lg-10"
                style={{ fontSize: "0.7rem" }}
              >
                <ReportLegends />
              </div>
              <div className="col-auto">
                {Array.isArray(reportsIdForApproval) &&
                  reportsIdForApproval.length > 0 && (
                    <>
                      <button
                        onClick={this.approveReportsHandler}
                        className="btn btn-outline-secondary btn-sm p-1 py-0"
                        title="Approve all pending Reports"
                      >
                        <i className="fa fa-check"></i>
                        <small>Approve Selected</small>
                      </button>
                    </>
                  )}
                  {Array.isArray(reportsIdToRemove) &&
                  reportsIdToRemove.length > 0 && (
                    <>
                      <button
                        onClick={()=>this.approveReportsHandler(true)}
                        className="btn btn-outline-danger btn-sm p-1 py-0"
                        title="Remove all selected Reports"
                      >
                        <i className="fa fa-check"></i>
                        <small>Remove Selected</small>
                      </button>
                    </>
                  )}

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table
                    className="table table-sm "
                    // className="table table-bordered table-striped thead-dark table-hover table-sm "
                    style={{ fontSize: "0.7rem", textAlign: "center" }}
                  >
                    <thead>
                      {/* head row 1 */}
                      <tr
                      // className="thead-light"
                      >
                        <th colSpan="4"></th>
                        <th colSpan="2">FROM (GMT)</th>
                        <th colSpan="2">TO (GMT)</th>
                        <th colSpan="1"></th>
                        <th className="table-secondary" colSpan="6">
                          REASON
                        </th>
                        <th colSpan="1"></th>
                      </tr>
                      {/* head row 2 */}
                      <tr
                      // className="bg-info text-light"
                      >
                        <EachItem of={columns} render={(column, index) => 
                          <th key={"header_" + index} scope="col" className={`${column.className}`}>
                            {column.title}
                          </th>
                        }/>
                      </tr>
                    </thead>
                    <tbody>
                      <EachItem of={vesselReports} 
                        render={(report, index2) => 
                          <VesselOffhireReportGroup 
                          offhireReport={report} 
                          columns={columns} 
                          srNo={pageFirst + index2 + 1} 
                          key={"report_row_" + index2}
                          reportsIdForApprovalHandler={this.reportsIdForApprovalHandler}
                          reportsIdForRemoveHandler={this.reportsIdForRemoveHandler}      
                          onAction={this.handleRowAction}
                          />
                        } 
                        empty={
                          <tr>
                            <td colSpan="17">
                              <div className="display-4 p-5">
                                {"No Reports are available under this category"}
                              </div>
                            </td>
                          </tr>
                        } />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

}

export default FleetOffhireReports;
