import { Component } from "react";

class AnFileDownloadElement extends Component {
    state = {  } 

    // handleClick=()=>{
    //     const { name, reportId, fileAttachmentId } = this.props;
    // }

    render() { 
    const { attribute, paddingTop } = this.props;
    const { name: attributeName, handleDownload, value } = attribute;
    const { fileAttachmentId } = value;
    const FILE_DOWNLOAD_COMP_CLASS = "an-file-download pl-1" + (paddingTop?"file-input-pad":"");
    const name = "";
        return (<div className={FILE_DOWNLOAD_COMP_CLASS} >
            <p className="pl-1 pointer">
                <span className="pr-1">Previously Uploaded:</span>
                {name && <span className="an-file-download-name">{name}</span>}
                <button 
                className="btn btn-sm btn-outline"
                id={attributeName+'DownloadButton'} 
                name={attributeName+'DownloadButton'}
                onClick={handleDownload}
                ><i className="fa fa-download"></i></button>
            </p>
        </div>);
    }
}
 
export default AnFileDownloadElement;