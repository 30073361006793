import React from "react";
import { Navigate } from "react-router-dom";
import {
  getSubmitButton,
  getOptionsArrayForSelectElement,
} from "../../../offhire/util/first";
import AnHPForm from "./factory/AnHPForm";
import AnInlineFormGroup from "./factory/AnInlineFormGroup";
import AnSpinner from "../other/AnSpinner";
import { FleetService } from "../../../offhire/services/offhire-services";
import { errorHandler } from "../../utility/http-util";

class AnAddFleetForm extends React.Component {
  state = {
    isLoggedIn: true,
    isLoaded: true,
    onProgress: false,
    error: null,
    feilds: {},
    feildsOptions: {},

    // report: null,
    isFleetManagersLoaded: false,
    fleetManagers: [],
  };

  labels = {
    fleet_name: {
      isGroupTitle: false,
      title: "Fleet Name",
      type: "text",
      value: "",
    },
    fleet_code: {
      isGroupTitle: false,
      title: "Fleet Code",
      type: "text",
      value: "",
    },
    fleet_description: {
      isGroupTitle: false,
      title: "Fleet Description",
      type: "text",
      value: "",
    },
    fleet_type: {
      isGroupTitle: false,
      title: "Fleet Type",
      type: "text",
      value: "",
    },
    fleet_status: {
      isGroupTitle: false,
      title: "Fleet Status",
      type: "text",
      value: "",
    },
    fleet_manager: {
      isGroupTitle: false,
      title: "Fleet Manager",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Fleet Manager-",
        },
      ],
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getFieldsMap = () => {
    // front:back
    return {
      fleet_name: "fleet_name",
      fleet_code: "fleet_code",
      fleet_description: "description",
      fleet_type: "fleet_type",
      fleet_status: "fleet_status",
      fleet_manager: "fleet_manager_id",
    };
  };

  updateAllFeildsValue = (responseFeildsObject) => {
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      if (value !== undefined) {
        // if (key === "last_data_entry_date") {
        //   //"2019-12-31T11:49:41.457000"
        //   timestamp = value;
        //   value = value.split("T")[0];
        // } else if (
        //   key === "import_operation_timestamp" ||
        //   key === "calculation_operation_timestamp"
        // ) {
        //   value = value.replace("T", " ").slice(0, 19);
        // }

        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({ isLoaded: true, feilds });
  };

  getPostRequestObject = () => {
    const feildsMap = this.getFieldsMap();
    const formFeildsState = this.state.feilds;
    let postRequestObject = {};
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = formFeildsState[key];
      const is_required = this.labels[key]?.required === false ? false : true;
      if (value !== undefined) {
        if (value.length === 0 && is_required) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Error:" + feildName + " value is empty");
          return null;
        }
        postRequestObject[feildKey] = value;
      }
    }
    return postRequestObject;
  };

  responceError = (error) => errorHandler(
    error, 
    this.setState.bind(this), 
    this.toastRef, 
    null, 
    true
    );

  async componentDidMount() {
    //MONTH, VESSEL, PLANNED_EVENT
    FleetService.getFleetManagers()
      .then(
        (response) => {
          if (response.status === 200) {
            const fleetManagers = response.data;
            const fleetManagersOptions =
              getOptionsArrayForSelectElement(fleetManagers);
            this.setState({
              isFleetManagersLoaded: true,
              fleetManagers,
              feildsOptions: {
                ...this.state.feildsOptions,
                fleet_manager: fleetManagersOptions,
              },
              isLoaded: true,
            });
          }
        },
        (error) => {
          this.responceError(error);
        }
      );
  }

  handleValueChange = (e) => {
    let { feilds } = this.state;
    let key = e.target.name;
    let value = e.target.value.toString();
    feilds[key] = value;
    this.setState({ feilds });
  };

  APP_URL = "/fleet/";

  handleFormSubmit = (event) => {
    // debugger;
    // prevent default form submit
    event.preventDefault();
    // Mark the state as submitted to show the spinner
    this.setState({ onProgress: true });
    // Create object from form data
    const postRequestObject = this.getPostRequestObject();
    // Empty form validation
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }
    // console.log("postRequestObject");
    // console.log(postRequestObject);
    // return;
    FleetService.create(postRequestObject)
      .then(
        (response) => {
          // console.log("Post Response");
          // console.log(response);
          // console.log(this.props);
          if (response.data) {
            this.updateAllFeildsValue(response.data);
            this.setState({ onProgress: false });
          }
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            alert("Successfully Submitted");
          }

          //   console.log(response);
        },
        (error) => this.responceError(error)
      );
  };

  render() {
    const { feilds, feildsOptions, isLoaded, isLoggedIn } = this.state;
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    if (!isLoaded) return <AnSpinner />;

    let formFeildsArr = [];
    for (let key in this.labels) {
      const selectOptions = this.labels[key].options ?? [
        { value: "", title: "--Select--" },
      ];

      formFeildsArr.push({
        ...this.labels[key],
        name: key,
        value: feilds[key],
        onChangeHandler: this.handleValueChange,
        options: [...selectOptions, ...(feildsOptions[key] ?? [])],
      });
    }

    return (
      <React.Fragment>
        <AnHPForm onBack={this.props.onBack} onSubmit={this.handleFormSubmit}>
          {formFeildsArr.map((attribute, index) => {
            return (
              <React.Fragment>
                <AnInlineFormGroup attribute={attribute} key={index} />
              </React.Fragment>
            );
          })}
          {getSubmitButton(
            "Add",
            false,
            this.state.onProgress,
            this.state.error
          )}
        </AnHPForm>
      </React.Fragment>
    );
  }
}

export default AnAddFleetForm;
