import React, { Component } from "react";
import AnScreenCenter from "../components/factory/AnScreenCenter";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <AnScreenCenter>
        <h1 className="display-4 text-danger ">
          <i className="fa fa-exclamation-circle"></i>
          <span className="ml-3">404: Page not found</span>
        </h1>
      </AnScreenCenter>
    );
  }
}

export default NotFound;
