import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearSession } from "../application/session";

class SessionEndMessage extends Component {

  componentDidMount() {
    const { setIsLoggedIn, setUser } = this.props;
    setIsLoggedIn && setIsLoggedIn(false);
    setUser && setUser({});
    clearSession();
  }
  
  render() {
    return (
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <div className="col-md-12 col-lg-4">
          <div className="card shadow">
            <div className="card-body">
              <h4 className="card-title">
                <i className="fa fa-sign-out"></i> Your session has expired
              </h4>
              <p className="card-text">
                Please log in again to continue using the app.
              </p>
              <Link to="/ulogin" className="p-button p-button-info">
                Log in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SessionEndMessage;
