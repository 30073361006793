import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearSession } from "../application/session";
import BlobsBackground from "../components/design/BlobsBackground";
import "../components/design/Glassmorphic.css";
import Waves from "../components/design/Waves";

class Logout extends Component {
  componentDidMount() {
    const { setIsLoggedIn, setUser } = this.props;
    setIsLoggedIn && setIsLoggedIn(false);
    setUser && setUser({});
    clearSession();
  }
  render() {
    return (
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <BlobsBackground />
        <div className="col-md-12 col-lg-4">
          <div className="card shadow glassed">
            <div className="card-body">
              <h4 className="card-title">
                <i className="fa fa-sign-out"></i> Logged Out Successfully
              </h4>
              <p className="card-text">
                You have been logged out successfully.
              </p>
              <Link to="/ulogin" className="btn btn-primary">
                Login
              </Link>
            </div>
          </div>
        </div>
        {/* <Waves /> */}
      </div>
    );
  }
}

export default Logout;
