import React, { Component } from "react";
import AnFormGroup from "../forms/factory/AnFormGroup";
import AnInputElement from "../forms/factory/AnInputElement";
import "./AnLabelValue.css";

class AnLabelValue extends Component {
  state = {};
  render() {
    const { field, classes, label, value } = this.props;
    return (
      <div className={classes?.root ?? "row mb-3"}>
        {/* <div className={classes?.label ?? "col-md-4"}>{label}</div>
        <div
          className={classes?.value ?? "col-md-8"}
          style={{
            fontSize: "1rem",
          }}
        >
          {value}
        </div> */}
        <div
          className={
            classes?.label ?? "body-2-text color-code-06 line-height-15"
          }
        >
          {label}
        </div>
        {field?.type === "file" 
        ? <AnInputElement attribute={field}/>
        : <div
          className={
            classes?.value ??
            "subtitle-2-text line-height-15 color-code-087 mediumFont"
          }
          style={{
            fontSize: "1rem",
          }}
        >
          {value}
        </div>}
      </div>
    );
  }
}

export default AnLabelValue;
