import React, { Component } from "react";
import { MAIN_SCREEN_MENU_ITEMS } from "../../offhire/application/link";
import { setUser } from "../application/session";
import Waves from "../components/design/Waves";
import "../components/design/Glassmorphic.css";
import AnGroupElements from "../components/factory/AnGroupElements";
import { UserService } from "../../offhire/services/offhire-services";
import IsAvailable from "../components/hoc/IsAvailable";
import TileButton from "../../common/components/buttons/TileButton";



class Main extends Component {
  state = {
    isLoaded: false,
    isLoggedIn: false,
    user: {},
    refs: [],
  };

  componentDidMount() {
    const refs = MAIN_SCREEN_MENU_ITEMS;
    this.setState({ refs });

    UserService.getLoggedInUser()
      .then(
        (res) => {
          if (res.status === 200) {
            const user = res.data;
            // console.log("user", user);
            setUser(user);
            this.props?.setUser(user);
            this.setState({
              isLoaded: true,
              isLoggedIn: true,
              user: user,  
            });
          } else {
            this.setState({
              isLoaded: true,
              isLoggedIn: false,
              user: {},  
            });
          }
        },
        (err) => {
          // clearSession();
          this.setState({
            isLoaded: true,
            isLoggedIn: false,
            user: {},
          });
        }
      );
  }

  render() {
    const { refs, user, isLoaded, isLoggedIn } = this.state;
    return (
      <IsAvailable isLoaded={isLoaded} isLoggedIn={isLoggedIn} toastRef={this.handleToastRef}>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="no-gutters border py-3 px-5 my-1  rounded">
              <div className="row justify-content-center align-items-center" style={{ height: "80vh" }}>
                <div className="col">
                  <AnGroupElements
                    componentsPerGroup={2}
                    items={refs}
                    condition={(item) => item.roles.includes(user?.role?.name)}
                    componentWrapper={(item, index) => <TileButton key={index} {...item} index={index} />}
                    rowClassName="justify-content-center align-items-center"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <Waves /> */}
        </div>
      </IsAvailable>
    );
  }

}

export default Main;
