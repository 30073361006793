import React, { Component } from "react";
import "../design/Glassmorphic.css";
class AnBordered extends Component {
  // state = {
  //   isMinimised: false,
  //   holdRow: false,
  // };
  constructor(props) {
    super(props);
    const { isMinimised, holdRow } = this.props;
    this.state = {
      isMinimised: isMinimised,
      holdRow: holdRow,
    };
  }

  getHeader = (
    title,
    iconName,
    textStyle,
    canMinimise = true,
    isElemHidden = false
  ) => {
    if (canMinimise === false) {
      return <h5 className={textStyle + " pr-1 text-wrap"}>{title}</h5>;
    }
    return (
      <button
        className={
          "btn btn-sm " +
          (isElemHidden ? "btn-light" : "text-secondary") +
          " mb-0 px-0"
        }
        onClick={() => {
          // const { canMinimise } = this.props;
          if (canMinimise !== false) {
            this.setState({ isMinimised: !this.state.isMinimised });
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <span className={textStyle + " pr-1 text-wrap"}>{title}</span>
        <span>
          <i className={"fa fa-" + iconName}></i>
        </span>
      </button>
    );
  };

  render() {
    const { canMinimise, aling, shadow } = this.props;
    let {
      title,
      children,
      col,
      displaySizeMargin,
      holdRow,
      minimiseIconName,
      hideOnPrint,
    } = this.props;

    const state = this.state;
    let alingItemsClass = "";
    alingItemsClass = aling === "center" ? " align-self-center" : "";

    let smCol = 12,
      lgCol = 1,
      iconDir = "right",
      iconName = "",
      textStyle = "small";

    const isValidColLen = col && !isNaN(col);
    if (isValidColLen) {
      if (!state.isMinimised) {
        // smCol = 12;
        lgCol = parseInt(col);
        iconDir = "down";
        textStyle = "h5";
      } else if (holdRow) {
        // smCol = 12;
        lgCol = parseInt(col);
        textStyle = "h6";
      }
    }

    iconName = "angle-" + iconDir;
    if (textStyle === "small" && minimiseIconName) {
      title = "";
      iconName = minimiseIconName;
    } else if (canMinimise === false) {
      // title = "";
      iconName = "minus";
    }

    let mrgn = col == 4 ? 0 : 2;
    mrgn = col < 4 ? -1 : mrgn;
    mrgn = isNaN(displaySizeMargin) ? mrgn : parseInt(displaySizeMargin);

    const hideOnPrintClass = hideOnPrint ? " d-print-none" : "";
    const isElemHidden = state.isMinimised && minimiseIconName;
    return (
      <React.Fragment>
        <div
          className={
            (isElemHidden || !isValidColLen
              ? "col-auto"
              : `col-sm-12 col-md-${lgCol - mrgn} col-lg-${lgCol} col-xl-${
                  lgCol + mrgn
                }`) +
            " " +
            hideOnPrintClass +
            alingItemsClass
          }
          style={{
            maxHeight: alingItemsClass === "" ? "auto" : "90vh",
          }}
        >
          <div className="row mb-0">
            <div className="col mb-0 px-1">
              {this.getHeader(
                title,
                iconName,
                textStyle,
                canMinimise,
                isElemHidden
              )}
            </div>
          </div>
          {!state.isMinimised && (
            <div className="row mt-0">
              <div className="col mt-0 p-1">
                <div
                  className={
                    // " no-gutters border bg-white rounded"+
                    "py-3 my-1 mt-0" +
                    (shadow === true ? " px-5 shadow" : " px-3") +
                    " glassed"
                  }
                >
                  {!state.isMinimised && children}
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default AnBordered;
