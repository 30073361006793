export const OffhireTableDef = [
    {
      key: "sr_no",
      title: "Sr. No.",
      dataIndex: "sr_no",
      width: "100px",
    },
    {
      key: "vessel_name",
      title: "Vessel Name",
      dataIndex: "vessel_name",
      width: "100px",
    },
    // {
    //   key: "vessel_age",
    //   title: "Vessel Age",
    //   dataIndex: "vessel_age",
    //   width: "100px",
    // },
    {
      key: "location",
      title: "Location",
      dataIndex: "location",
      width: "100px",
    },
    {
      key: "place",
      title: "Place",
      dataIndex: "place",
      width: "100px",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      width: "100px",
    },
    {
      key: "time",
      title: "Time",
      dataIndex: "time",
      width: "100px",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      width: "100px",
    },
    {
      key: "time",
      title: "Time",
      dataIndex: "time",
      width: "100px",
    },
    {
      key: "total_time",
      title: "Total Time (day)",
      dataIndex: "total_time",
      width: "100px",
    },
    {
      key: "offhire_stoppage",
      title: "Offhire/Stoppage",
      dataIndex: "offhire_stoppage",
      width: "100px",
    },
    {
      key: "planned_unplanned",
      title: "Planned/Unplanned",
      dataIndex: "planned_unplanned",
      width: "100px",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      width: "100px",
    },
    {
      key: "machinery",
      title: "Machinery",
      dataIndex: "machinery",
      width: "100px",
    },
    {
      key: "machinery_subtype",
      title: "Machinery Subtype",
      dataIndex: "machinery_subtype",
      width: "100px",
    },
    {
      key: "details",
      title: "Details",
      dataIndex: "details",
      width: "100px",
    },
    {
      key: "report_status",
      title: "Report Status",
      dataIndex: "report_status",
      width: "100px",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      width: "100px",
    },
  ];