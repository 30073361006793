import { Dialog } from "primereact/dialog";

export const AnDialog = ({
  header,
  visible,
  onHide,
  position,
  footerContent,
  children,
}) => {
  return (
    <Dialog
      className="an-dialog"
      header={header}
      visible={visible}
      position={position ?? "right"}
      onHide={onHide}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {children}
    </Dialog>
  );
};
