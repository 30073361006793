import React, { Component, Fragment } from "react";
import Row from "../other/Row";

class AnGroupElements extends Component {
  render() {
    const {
      componentsPerGroup,
      items,
      condition,
      componentWrapper,
      rowClassName,
    } = this.props;
    let components = [];

    return (
      <>
        {items.map((item, index) => {
          let temp = null;

          if (condition(item)) {
            components.push(componentWrapper(item, index));
          }

          const IS_LAST_ITEM = index === items.length - 1;
          // const IS_GROUP_FULL = components.length === componentsPerGroup;
          const IS_GROUP_FULL_EXCEPT_FIRST = components.length % componentsPerGroup === 0 && index !== 0;

          //set Group to return
          if (IS_GROUP_FULL_EXCEPT_FIRST || IS_LAST_ITEM) {
            temp = (
              <Row key={index} className={"" + rowClassName}>
                {components}
              </Row>
            );
            components = [];
          }

          return temp ?? <Fragment key={index}></Fragment>;
        })}
      </>
    );
  }
}

export default AnGroupElements;
