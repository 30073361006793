import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";


function RoleForm(props){
        
    const labels = {
    
    };

    const getFieldsMap = () => {
        // front:back
        return {
        };
    }

    return (
        <AnCommonFormDialog
            {...props}
            header="Add Role"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/role/"
        />
    )

}

export default RoleForm;