import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY_LIGHT,
  BRAND_NAME_COLOR,
  PROJECT_BRAND_NAME,
  PROJECT_ROOT,
  PROJECT_LOGO,
  cHeadLogoWidth,
  cHeadLogoHeight,
  cHeadNavHeight,
  PROJECT_LOGO_3,
} from "../../../application/application";
import { getUser } from "../../../application/session";
import HeaderNavItems from "./HeaderNavItems";
import HeaderUserOptions from "./HeaderUserOptions";


export function withRouter(Children) {
  return (props) => {
    // console.log(useLocation());
    const user = getUser();
    // console.log("u", user);
    return (
      <Children
        {...props}
        // currentpath={useLocation()?.pathname?.split("/")[1]}
        user={user}
      />
    );
  };
}


class HeaderNavBar extends Component {
  state = {
    is_page: "",
    nv_options: [],//["Op1", "Op2", "Op3"],
    isLoggedIn: false,
    user: {},
    pageChange: false,
    currentpath: "",
  };

  // constructor(props, context) {
  //   super(props, context)
  //   this.state.is_page = props.is_page ? props.is_page : [];
  //   this.state.nv_options = props.nv_options;
  // }

  render() {
    const { user } = this.props;

    return (
      <React.Fragment>
        <header>
          <nav
            className="navbar fixed-top navbar-expand-md navbar-light text-dark shadow py-0"
            style={{
              backgroundColor: COLOR_PRIMARY_LIGHT,
              color: COLOR_SECONDARY_LIGHT,
              height: cHeadNavHeight,
            }}
          >
            {/* <div className="nav-item toggle">
              <Link id="menu_toggle">
                <i className="fa fa-caret-right" aria-hidden="true"></i>
              </Link>
            </div> */}

            <Link className="navbar-brand ml-lg-5 pl-lg-3" to="/">
              <img
                src={PROJECT_ROOT + "/assets/images/" + PROJECT_LOGO_3}
                style={{ width: cHeadLogoWidth, height: cHeadLogoHeight }}
                className="d-inline-block align-top mr-md-1 mt-md-1"
                alt=""
              />
              <span
                className="navbar-text pt-lg-3 pl-3"
                style={{
                  color: BRAND_NAME_COLOR + "!important",
                  fontFamily:
                    "'Abril Fatface', 'Playfair Display', playfair, serif",
                  fontSize: "1rem",
                }}
              >
                {PROJECT_BRAND_NAME}
              </span>
            </Link>

            <button
              className="navbar-toggler m-md-2"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <div className="navbar-nav ml-md-auto">
                <HeaderNavItems menuItems={this.state.nv_options} activePage={this.state.is_page} />
                <HeaderUserOptions user={user} onSideBarToggle={this.props.onSideBarToggle}/>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }

}

export default withRouter(HeaderNavBar);
