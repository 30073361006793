import React, { Children, Component } from "react";

function Col3(props) {
  return (
    <div className={"col-sm-12 col-md-6 col-lg-3 " + props.className}>
      {props.children}
    </div>
  );
}

export default Col3;
