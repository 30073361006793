export const linkAllEventsType = "/events/type/";
export const linkAllMachinery = "/machinery/";
export const linkAllOptionsSeas = "sea/all";
export const linkAllOptionPorts = "port/all"; //"port/l/";
export const linkAllReportsCombined = "/reports/combined/";

export const OffhireAPIRoutes = {
    BUDGET : '/events/item/planned/',
    DASHBOARD : '/',
    EVENT_CATEGORY : '/events/category/',
    EVENT_DAYS : '/reports/event_days/',
    EVENT_TYPE : '/events/type/',
    MACHINERY : '/machinery/',
    REPORT : '/reports/combined/',
    REPORT_ATTATCHMENT : '/reports/upload/technical',
    REPORT_COMMERCIAL : '',
    REPORT_TECHNICAL : '',
    REPORT_GROUP : '/reports/group/',
    PORT : '/port/',
    SEA : '/sea/',
}