export const MAIN_SCREEN_MENU_ITEMS = [
    // {
    //   name: "Offhire Dashboard",
    //   link: "/offhire/dashboard",
    //   icon: "line-chart",
    //   color: "danger",
    //   roles: ["admin", "fleetmanager", "shipmanager", "crew"],
    // },
    // {
    //   name: "Offhire Reports",
    //   link: "/offhire/reports",
    //   icon: "file-text-o",
    //   color: "warning",
    //   roles: ["admin", "fleetmanager", "shipmanager", "crew"],
    // },
    // {
    //   name: "Offhire Settings",
    //   link: "/offhire/settings",
    //   icon: "cog",
    //   color: "primary",
    //   roles: ["admin", "fleetmanager", "shipmanager", "crew"],
    // },
    {
      name: "Offhire Reports",
      link: "/offhire/reports",
      icon: "list",
      color: "info",
      roles: ["admin", "fleetmanager", "shipmanager"],
    },
    {
      name: "Offhire Dashboard",
      link: "/offhire/dashboard",
      icon: "line-chart",
      color: "info",
      roles: ["admin", "fleetmanager", "shipmanager", "crew"],
    },
    {
      name: "Budget (Planned Events)",
      link: "/budget",
      icon: "dollar",
      color: "info",
      roles: ["admin", "fleetmanager"],
    },
    // {
    //   name: "Offhire Event Days",
    //   link: "/offhire/event_days",
    //   icon: "calendar",
    //   color: "info",
    //   roles: ["admin", "fleetmanager"],
    // },
  ];
