import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import { OffhireAPIRoutes } from "../application/apiSources";
import EventTypeForm from "../forms-dialog/EventTypeForm";
import { EventTypeService } from "../services/services";

class EventTypeScreen extends Component {  
  render() {
    return (
      <CommonListViewScreen
        serviceModeActive={true}
        entityService={EventTypeService}
        path={OffhireAPIRoutes.EVENT_TYPE}
        dataPreprocessor={{
          mainTitle: "Events Types",
          path: "/offhire/event-type",
          columns: [
            {  header: "ID", field: "id", width: "10%" },
            {  header: "Name", field: "name", width: "25%" },
            {  header: "Description", field: "description", width: "55%" },
          ]
        }}
        canDelete={true}
      >
        <EventTypeForm />
      </CommonListViewScreen>
    );
  }
}

export default EventTypeScreen;
