import { ofhrReportCommercialDataTitle, ofhrReportCommercialDatesOverlaping, ofhrReportTechnicalDataTitle, ofhrReportTechnicalDatesDifferent } from "../application/constants";

export const getTommorowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().slice(0, 10);
};

export const extractEventsCategoriesFromEventTypes = (eventTypes) => {
  let eventsCategories = {};
  eventTypes.forEach((eventType) => {
    eventsCategories[eventType.offhire_events_category.id] =
      eventType.offhire_events_category;
  });

  // Convert to array
  let eventsCategoriesArray = [];
  for (let key in eventsCategories) {
    eventsCategoriesArray.push(eventsCategories[key]);
  }
  return eventsCategoriesArray;
};

export const getOptionsArrayForSelectElement = (
  optionsInJSON,
  property_value = "id",
  property_name = "name"
) => {
  // debugger;
  console.log("optionsInJSON");
  let optionsArray = [];
  for (let aOption of optionsInJSON) {
    optionsArray.push({
      value: aOption[property_value],
      title: aOption[property_name],
      label: aOption[property_name],
    });
  }
  return optionsArray;
};

export const getDaysBetweenDates = (startDate, endDate) => {
  // debugger;
  if (startDate === "" || endDate === "") {
    return "";
  }
  var start = new Date(startDate);
  var end = new Date(endDate);
  return (end - start) / 86400000;
};

export const getOffhireDaysBetweenDates = (
  startDate,
  endDate,
  TCIPercent = 100
) => {
  // debugger;
  let days = getDaysBetweenDates(startDate, endDate);
  if (!days && days !== 0) {
    return "";
  }
  return (days * (TCIPercent / 100)).toFixed(2);
};

export const getSubmitButton = (
  buttonTitle = "Submit",
  isSizeSmall = false,
  isOnProgress = false,
  error = ""
) => {
  return (
    <div className="form-group row  m-0 p-0 pt-1 justify-content-end">
      <div className="col-sm-12 col-md-6">
        {isOnProgress && (
          <span className="text-danger" style={{ fontSize: "0.7rem" }}>
            {" "}
            <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
            <span className="sr-only">Generating...</span>
          </span>
        )}
        {!error && (
          <span className="text-danger" style={{ fontSize: "0.7rem" }}>
            {error}
          </span>
        )}
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <input
          type="submit"
          className="form-control p-button p-button-info p-button-sm p-0"
          name="submit"
          id="submit"
          value={buttonTitle}
          style={{ fontSize: "0.6rem" }}
          disabled={isOnProgress}
        />
      </div>
    </div>
  );
};

export const StatusPill = ({status}) => {
  const STATUS_AND_COLOR = {
    APPROVED: "success",
    REJECTED: "danger",
    PENDING: "warning",
    INCOMPLETE: "info",
  };

  const CURRENT_COLOR_CLASS = STATUS_AND_COLOR[status] || "secondary";

  return (
    <span
      className={'badge badge-pill badge-' + CURRENT_COLOR_CLASS}
      style={{ fontFamily: "monospace" }}
    >
      {status}
    </span>
  );
};


export const ReportLegends = () => <>
<span>
  <span className="badge badge-dark text-dark mx-1">.</span>
    {ofhrReportCommercialDataTitle}
  </span>
  <span>
    <span className="badge badge-primary text-primary mx-1">
      .
    </span>
    {ofhrReportTechnicalDataTitle}
  </span>
  <span>
    <span className="badge badge-warning text-warning mx-1">
      .
    </span>
    {ofhrReportCommercialDatesOverlaping}
  </span>
  <span>
    <span className="badge badge-danger text-danger mx-1">.</span>
    {ofhrReportTechnicalDatesDifferent}
  </span>
</>