import React, { Component } from "react";
import "./BlobsBackground.css";

class BlobsBackground extends Component {
  render() {
    return (
      <div className="blobs-background">
        <svg
          viewBox="-750 -400 2000 1000"
          // viewBox="-1050 -650 3000 1000"
          xmlns="http://www.w3.org/2000/svg"
          className="blob-1"
        >
          <path
            fill="#9EF0F0"
            // d="M32.6,-56.2C42.8,-44.3,51.9,-36,60.4,-25.3C69,-14.5,77,-1.1,73.9,9.6C70.8,20.4,56.5,28.5,45.1,35.8C33.6,43,25,49.4,15.2,52.7C5.4,56.1,-5.6,56.3,-20.9,57.9C-36.1,59.5,-55.6,62.3,-69.3,55.2C-82.9,48.1,-90.7,31.1,-92.8,13.6C-94.9,-3.9,-91.3,-22,-79.9,-32.2C-68.5,-42.4,-49.4,-44.7,-34.8,-54.6C-20.2,-64.5,-10.1,-81.9,0.6,-82.8C11.2,-83.7,22.5,-68.1,32.6,-56.2Z"
            d="M64.7,-50.2C80.4,-31.9,87.3,-5.2,81.6,18C75.8,41.2,57.3,60.9,35.2,70.6C13.2,80.2,-12.5,79.7,-33.7,69.6C-54.8,59.5,-71.5,39.8,-77.1,17.1C-82.7,-5.7,-77.2,-31.4,-62.5,-49.4C-47.8,-67.5,-23.9,-77.7,0.3,-77.9C24.5,-78.2,49,-68.4,64.7,-50.2Z"
            transform="translate(0 0)"
          />
          <path
            fill="#9EF0F0"
            // d="M69.6,-23.5C78.4,4.4,65.5,38.4,40.5,56.8C15.5,75.2,-21.6,78,-45.9,60.9C-70.3,43.7,-82,6.7,-72.2,-22.6C-62.4,-51.9,-31.2,-73.3,-0.4,-73.2C30.4,-73.1,60.8,-51.4,69.6,-23.5Z"
            d="M64.7,-50.2C80.4,-31.9,87.3,-5.2,81.6,18C75.8,41.2,57.3,60.9,35.2,70.6C13.2,80.2,-12.5,79.7,-33.7,69.6C-54.8,59.5,-71.5,39.8,-77.1,17.1C-82.7,-5.7,-77.2,-31.4,-62.5,-49.4C-47.8,-67.5,-23.9,-77.7,0.3,-77.9C24.5,-78.2,49,-68.4,64.7,-50.2Z"
            transform="translate(200 200)"
          />
          <path
            fill="#9EF0F0"
            d="M64.7,-50.2C80.4,-31.9,87.3,-5.2,81.6,18C75.8,41.2,57.3,60.9,35.2,70.6C13.2,80.2,-12.5,79.7,-33.7,69.6C-54.8,59.5,-71.5,39.8,-77.1,17.1C-82.7,-5.7,-77.2,-31.4,-62.5,-49.4C-47.8,-67.5,-23.9,-77.7,0.3,-77.9C24.5,-78.2,49,-68.4,64.7,-50.2Z"
            transform="translate(470 30)"
          />
        </svg>
        {/* <svg
          viewBox="-600 -450 100 250"
          xmlns="http://www.w3.org/2000/svg"
          className="blob-2"
        >
          <path
            fill="#9EF0F0"
            d="M69.6,-23.5C78.4,4.4,65.5,38.4,40.5,56.8C15.5,75.2,-21.6,78,-45.9,60.9C-70.3,43.7,-82,6.7,-72.2,-22.6C-62.4,-51.9,-31.2,-73.3,-0.4,-73.2C30.4,-73.1,60.8,-51.4,69.6,-23.5Z"
            transform="translate(100 100)"
          />
        </svg>
        <svg
          viewBox="-500 -550 1200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="blob-3"
        >
          <path
            fill="#9EF0F0"
            d="M64.7,-50.2C80.4,-31.9,87.3,-5.2,81.6,18C75.8,41.2,57.3,60.9,35.2,70.6C13.2,80.2,-12.5,79.7,-33.7,69.6C-54.8,59.5,-71.5,39.8,-77.1,17.1C-82.7,-5.7,-77.2,-31.4,-62.5,-49.4C-47.8,-67.5,-23.9,-77.7,0.3,-77.9C24.5,-78.2,49,-68.4,64.7,-50.2Z"
            transform="translate(100 100)"
          />
        </svg> */}
      </div>
    );
  }
}

export default BlobsBackground;
