import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import PortForm from "../forms/PortForm";

function PortScreen(props) {
  return (
    <CommonListViewScreen
      path="/port/all" // api
      dataPreprocessor={{
        mainTitle: "Ports",
        path: "/offhire/vessel", // route
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "name" },
          { header: "UN Code", field: "un_code" },
          { header: "Country ID", field: "country_id" },
        ],
      }}
    >
      <PortForm />
    </CommonListViewScreen>
  );
}

export default PortScreen;
