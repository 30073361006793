import React from "react";
import { Navigate } from "react-router-dom";
import AnSpinner from "../other/AnSpinner";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog } from "primereact/confirmdialog";
import NmBreadcrumb from "../../layout/components/NmBreadcrumb";

function IsAvailable({ children, isLoaded, isLoggedIn, toastRef, breadcrumbItems, headerTemplate }) {
  const NOT_LOGGED_IN = !isLoggedIn;
  const LOADING = !isLoaded;

  /** 
   * Moved the redirection from here to after the loading spinner
   * to prevent redirection before the loading of actual API responce.
   */
  // if (NOT_LOGGED_IN) return <Navigate replace to="/sessionends" />;

  return (
    <React.Fragment>
      {breadcrumbItems?.length && <NmBreadcrumb items={breadcrumbItems} />}
      {headerTemplate && headerTemplate()}
      <Toast ref={toastRef} />
      <ConfirmDialog />
      {/* {LOADING && <ProgressSpinner/>} */}
      {LOADING ? <AnSpinner /> : <>
        {NOT_LOGGED_IN && <Navigate replace to="/sessionends" />}
        {React.cloneElement(children)}
      </>}
    </React.Fragment>
  );
}

export default IsAvailable;
