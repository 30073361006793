import React, { Component } from "react";


function AnButtonExpandable({ isMaximised, value, onClick }){
  return (
    <div
      onClick={() => onClick(!isMaximised)}
      className="btn btn-sm p-1 py-0 btn-outline-secondary"
    >
      <i
        className={"fa fa-caret-" + (isMaximised ? "down" : "right") + " mr-1"}
        style={{ fontSize: ".7rem" }}
      ></i>
      {!!value ? value : ""}
    </div>
  );
}

export default AnButtonExpandable;
