import React from "react";
import AnInputElement from "./AnInputElement";

class AnInlineFormGroup extends React.Component {
  render() {
    const { attribute, validation } = this.props;

    let validClass = validation?.isValid ? "is-valid" : "is-invalid";
    validClass = validation?.isChecked ? validClass : "";
    let validationMessage = validation?.message ? validation.message : "";

    attribute.style = { fontSize: "0.7rem" };

    return (
      <React.Fragment>
        <React.Fragment>
          {attribute.isGroupTitle && (
            <div className="form-group row" key={attribute.name}>
              <h5
                className="col-sm-12 col-lg-12"
                style={{ fontSize: "0.9rem" }}
              >
                {attribute.title}
              </h5>
            </div>
          )}
        </React.Fragment>

        <React.Fragment>
          {!attribute.isGroupTitle && (
            <div className="form-group row pl-1" key={attribute.name}>
              <div className="col-sm-12 col-md-6 col-lg-4 p-0 pr-1">
                <label
                  // className="col-auto col-form-label col-form-label-sm px-1"
                  className="label-sm p-0"
                  htmlFor={attribute.name}
                  style={attribute.style}
                >
                  {attribute.title}
                </label>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8 p-0">
                <AnInputElement attribute={attribute} validClass={validClass} />
                {validationMessage && (
                  <div className="valid-feedback">{validationMessage}</div>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default AnInlineFormGroup;
