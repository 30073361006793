import { Link } from "react-router-dom";

const NavLink = ({ path, icon, title, badge, className }) => (
  <span className="nav-item">
    <Link to={path} className={"nav-link "+(className??'')}>
      <i className={`fa fa-${icon}`}></i>
      <span className="pl-1">{title}</span>
      {badge && (
        <span className="badge badge-pill badge-secondary ml-3">
          {badge}
        </span>
      )}
    </Link>
  </span>
);

export default NavLink;
