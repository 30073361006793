import axios from "axios";
import { CoreAPIRoutes } from "../application/apiSources";
import { getAuthHeader } from "../application/connection";

const EmployeeFetchService = {
  get: () => axios.get(CoreAPIRoutes.EMPLOYEE, { headers: getAuthHeader() }),
  getAll: () => axios.get(`${CoreAPIRoutes.EMPLOYEE}all`, { headers: getAuthHeader() }),
}

export default EmployeeFetchService;
