import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function VesselForm(props){
     
    const labels = {
        fleet: {
          isGroupTitle: false,
          title: "Fleet",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Fleet-",
            },
          ],
        },
        ship_manager: {
          isGroupTitle: false,
          title: "Ship Manager",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Ship Manager-",
            },
          ],
          optionsFetch:{
            api:  "/person/shipmanager",
          }
        },
    
        vessel_name: {
          isGroupTitle: false,
          title: "Vessel Name",
          type: "text",
          value: "",
        },
        vessel_name: {
          isGroupTitle: false,
          title: "Vessel Name",
          type: "text",
          value: "",
        },
        vessel_imo: {
          isGroupTitle: false,
          title: "Vessel IMO",
          type: "text",
          value: "",
        },
        vessel_code: {
          isGroupTitle: false,
          title: "Vessel Code",
          type: "text",
          value: "",
        },
        vessel_flag: {
          isGroupTitle: false,
          title: "Vessel Flag",
          type: "text",
          value: "",
        },
        vessel_type: {
          isGroupTitle: false,
          title: "Vessel Type",
          type: "text",
          value: "",
        },
        vessel_year_built: {
          isGroupTitle: false,
          title: "Vessel Year Built",
          type: "number",
          value: "",
        },
        vessel_builder: {
          isGroupTitle: false,
          title: "Vessel Builder",
          type: "text",
          value: "",
        },
        vessel_owner: {
          isGroupTitle: false,
          title: "Vessel Owner",
          type: "text",
          value: "",
        },
      };

      const getFieldsMap = () => {
        // front:back
        return {
            fleet: "fleet_id",
            ship_manager: "ship_manager_id",
            vessel_name: "vessel_name",
            vessel_imo: "vessel_imo",
            vessel_flag: "vessel_flag",
            vessel_type: "vessel_type",
            vessel_year_built: "vessel_year_built",
            vessel_builder: "vessel_builder",
            vessel_owner: "vessel_owner",
        };
      };

     return (
        <AnCommonFormDialog
            {...props}
            header="Add Vessel"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/vessel/"
        />
     )
    
}

export default VesselForm;