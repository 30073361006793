import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import React, { Component } from "react";
import { getUser } from "../../core/application/session";
import AnGroupElements from "../../core/components/factory/AnGroupElements";
import AnLabelValue from "../../core/components/factory/AnLabelValue";
import AnButtonExpandable from "../../core/components/other/AnButtonExpandable";
import Col4 from "../../core/components/other/Col4";
import Row from "../../core/components/other/Row";
import {
  formatDatetime,
  getDaysBetweenDates,
} from "../../core/utility/conversion";
import OffhireEventDaysEditForm from "../forms/OffhireEventDaysEditForm";
import OffhireEventDaysScreen from "../pages/OffhireEventDaysScreen";
import ORTUpdateHistory from "./ORTUpdateHistory";
import SameVesselReportSelector from "./SameVesselReportSeletor";
import { ReportGroupService } from "../services/services";

class OffhireReportTechnicalDetails extends Component {
  state = {
    isUpdateHistoryShown: false,
    isMoveEventDaysShown: false,
    isGroupReportShown: false,
    USERROLE: "",
  };

  labels = {
    // offhire_report_commercial_section: {
    //   isGroupTitle: true,

    //   title: "Offhire Report (Commercial)",
    // },
    // vessel_name: {
    //   title: "Vessel Name",
    // },
    // name: {
    //   title: "Name",
    // },
    // vessel_code: {
    //   title: "Vessel Code",
    // },
    // voyage_number: {
    //   title: "Voyage Number",
    // },
    // voyage_status: {
    //   title: "Voyage Status",
    // },
    // commenced_at: {
    //   title: "Voayge Commenced At",
    //   type: "datetime-local",
    // },
    // completed_at: {
    //   title: "Voayge Completed At",
    //   type: "datetime-local",
    // },
    // activity: {
    //   title: "Activity",
    // },
    // delay_start_at_gmt: {
    //   title: "Delay Start At (GMT)",
    //   type: "datetime-local",
    //   value: "2022-02-23T23:25:30.900Z",
    // },
    // delay_start_at_local: {
    //   title: "Delay Start At (Local)",
    //   type: "datetime-local",
    //   value: "2022-02-23T23:25:30.900Z",
    // },
    // delay_end_at_gmt: {
    //   title: "Delay End At (GMT)",
    //   type: "datetime-local",
    //   value: "2022-02-23T23:25:30.900Z",
    // },
    // delay_end_at_local: {
    //   title: "Delay End At (Local)",
    //   type: "datetime-local",
    //   value: "2022-02-23T23:25:30.900Z",
    // },
    // reason: {
    //   title: "Reason",
    // },
    // tci: {
    //   title: "TCI %",
    // },
    // tco: {
    //   title: "TCO %",
    // },

    // delay_year: {
    //   title: "Delay Year",
    // },

    // hours: {
    //   title: "Delay Duration (in Hours)",
    // },
    // custom_offhire_days: {
    //   title: "Delay Duration (in Days)",
    // },
    // offhire_or_stoppage: {
    //   title: "Offhire/Stoppage",

    //   // type: "select",
    //   options: [
    //     {
    //       value: true,
    //       title: "Offhire",
    //     },
    //     {
    //       value: false,
    //       title: "Stoppage",
    //     },
    //   ],
    // },
    // // custom_offhire_days_group: {
    // //
    // //
    // //
    // //   title: "Offhire Days Group",
    // //
    // //
    // // },
    // offhire_report_technical_section: {
    //   isGroupTitle: true,

    //   title: "Offhire Report (Technical)",
    // },
    // offhire_report_technical_section_p1: {
    //   isGroupTitle: true,

    //   title: "Group 1:",
    // },

    // from_datetime: {
    //   title: "From Date Time",
    //   type: "datetime-local",
    // },
    // to_datetime: {
    //   title: "To Date Time",
    //   type: "datetime-local",
    // },
    // days: {
    //   title: "Days",
    // },
    // offhire_report_technical_section_p2: {
    //   isGroupTitle: true,

    //   title: "Group 2:",
    // },
    // offhire_type: {
    //   title: "Offhire Type",
    //   type: "select",
    //   options: [
    //     {
    //       value: "",
    //       title: "-Select Offhire Type-",
    //     },
    //   ],
    // },
    // // events_category: {
    // //
    // //
    // //
    // //   title: "Events Category",
    // //   type: "select",
    // //   // type: "select",
    // //   options: [
    // //     {
    // //       value: "",
    // //       title: "Select",
    // //     },
    // //     // {
    // //     //   value: true,
    // //     //   title: "Planned",
    // //     // },
    // //     // {
    // //     //   value: false,
    // //     //   title: "Unplanned",
    // //     // },
    // //   ],
    // // },
    // is_planned: {
    //   title: "Planned/Unplanned",
    //   type: "select",
    //   options: [
    //     {
    //       value: "",
    //       title: "Select",
    //     },
    //     {
    //       value: true,
    //       title: "Planned",
    //     },
    //     {
    //       value: false,
    //       title: "Unplanned",
    //     },
    //   ],
    // },
    // filler: {
    //   type: "filler",
    // },
    // machinery_type: {
    //   title: "Machinery Type",
    //   type: "select",
    //   options: [
    //     {
    //       value: "",
    //       title: "-Select Machinery Type-",
    //     },
    //   ],
    // },
    // sfi_code: {
    //   title: "SFI Code",
    // },
    // machinery_subtype: {
    //   title: "Machinery Subtype",
    //   type: "select",
    //   options: [
    //     {
    //       value: "",
    //       title: "-Select Machinery Subtype-",
    //     },
    //   ],
    // },
    // offhire_report_technical_section_p3: {
    //   isGroupTitle: true,

    //   title: "Group 3:",
    // },
    // event: {
    //   title: "Event",
    // },
    // // ...
    // // vessel_age: {
    // //
    // //   title: "Vessel Age",
    // //
    // //
    // //
    // // },
    // location: {
    //   title: "Location",
    //   type: "select",
    //   options: [
    //     {
    //       value: "",
    //       title: "-- Select Location --",
    //     },
    //     {
    //       value: "SEA",
    //       title: "Sea",
    //     },
    //     {
    //       value: "PORT",
    //       title: "Port",
    //     },
    //   ],
    //   //
    //   //
    // },
    // place: {
    //   title: "Place",
    //   type: "datalist-input",
    //   options: [
    //     {
    //       value: "",
    //       title: "-- Select Place --",
    //     },
    //   ],
    //   linkSelectByKey: "port",
    //   link: [
    //     {
    //       key: "sea",
    //       link: "sea/all",
    //       fetch: "once",
    //     },
    //     {
    //       key: "port",
    //       link: "port/l/",
    //     },
    //   ],
    //   //
    // },
    // // ...

    // shipmanager_section: {
    //   isGroupTitle: true,

    //   title: "Ship Manager Section",
    // },
    id: {
      title: "Offhire Report (Commercial) Unique ID",
    },
    offhire_report_technical_id: {
      title: "Offhire Report (Technical) Unique ID",
    },
    created_at: {
      title: "Created in System / Imported from IMOS On",
      type: "datetime-local",
    },
    ship_manager_comment: {
      title: "Ship Manager's Comment",
    },
    subbmited_by: {
      //   title: "Submitted By",
      title: "Submitted By",
    },
    ship_manager_submited_on: {
      title: "Created/Updated On",
      type: "datetime-local",
    },
    // fleetmanager_section: {
    //   isGroupTitle: true,

    //   title: "Fleet Manager Section",
    // },
    fleet_manager_comment: {
      title: "Fleet Manager Comment",
    },
    approved_by: {
      title: "Approved / Rejected By",
    },
    fleet_manager_submited_on: {
      title: "Approved / Rejected On",
      type: "datetime-local",
    },
    report_status: {
      title: "Report Status",
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getFieldsMap = () => {
    // front:back
    return {
      vessel_name: "vessel_name",
      name: "name",
      vessel_code: "vessel_code",
      voyage_number: "voyage_number",
      voyage_status: "voyage_status",
      commenced_at: "commenced_at",
      completed_at: "completed_at",
      activity: "activity",
      offhire_or_stoppage: "activity",
      delay_start_at_gmt: "commercial_delay_start_at_gmt",
      delay_end_at_gmt: "commercial_delay_end_at_gmt",
      reason: "commercial_reason",
      tci: "tci",
      tco: "tco",
      // id: "id",
      delay_year: "delay_year",
      delay_start_at_local: "delay_start_at_local",
      delay_end_at_local: "delay_end_at_local",
      hours: "hours",
      custom_offhire_days: "custom_offhire_days",
      custom_offhire_days_group: "custom_offhire_days_group",
      // ------------------

      id: "offhire_report_commercial_id",
      offhire_report_technical_id: "offhire_report_technical_id",
      //   offhire_type: "event_type_id",
      //   machinery_subtype: "machinery_id",
      offhire_type: "event_type_name",
      machinery_subtype: "machinery_name",
      from_datetime: "delay_start_at_gmt",
      to_datetime: "delay_end_at_gmt",
      days: "duration_in_days",
      event: "event",
      // vessel_age: "vessel_age",
      location: "location",
      //   place: "place_id",
      place: "place_name",
      ship_manager_comment: "ship_manager_comment",
      //--------------------------
      //   machinery_type: "machinery_type_id",
      machinery_type: "machinery_type_name",
      sfi_code: "sfi_code",
      //   events_category: "events_category_id",
      events_category: "events_category_name",
      is_planned: "is_planned",
      fleet_manager_comment: "fleet_manager_comment",
      approved_by: "fleet_manager_name",
      subbmited_by: "ship_manager_name",
      ship_manager_submited_on: "ship_manager_submited_on",
      fleet_manager_submited_on: "fleet_manager_submited_on",
      report_status: "report_status",
      created_at: "created_at",
    };
  };

  preProcessValuesForFeilds = (report) => {
    return {
      ...report,
      // vessel_age: "",
      location: report.offhire_report_technical?.location ?? "",
      sea_id: report.offhire_report_technical?.sea_id ?? "",
      port_id: report.offhire_report_technical?.port_id ?? "",
      place_id:
        report.offhire_report_technical?.location?.toUpperCase() === "SEA"
          ? report.offhire_report_technical?.sea_id ?? ""
          : report.offhire_report_technical?.port_id ?? "",
      place_name:
        report.offhire_report_technical?.location?.toUpperCase() === "SEA"
          ? report.offhire_report_technical?.sea?.name ?? ""
          : report.offhire_report_technical?.port?.name ?? "",

      hours: report.hours.toFixed(2),
      // days: report.custom_offhire_days.toFixed(2),
      custom_offhire_days: report.custom_offhire_days.toFixed(2),

      machinery_id: report.offhire_report_technical?.machinery_id,
      machinery_name: report.offhire_report_technical?.machinery?.name,

      machinery_type_id:
        report.offhire_report_technical?.machinery?.machinery_type_id,
      sfi_code:
        report.offhire_report_technical?.machinery?.machinery_type?.sfi_code,
      machinery_type_name:
        report.offhire_report_technical?.machinery?.machinery_type.name,

      event_type_id: report.offhire_report_technical?.event_type_id,
      event_type_name: report.offhire_report_technical?.event_type?.name,

      events_category_id:
        report.offhire_report_technical?.offhire_events_category_id ?? "",
      events_category_name:
        report.offhire_report_technical?.offhire_events_category
          ?.name,
      is_planned: report.offhire_report_technical?.is_planned,
      ship_manager_comment:
        report.offhire_report_technical?.ship_manager_comment ?? "",
      fleet_manager_comment:
        report.offhire_report_technical?.fleet_manager_comment ?? "",
      ship_manager_name:
        report.offhire_report_technical?.ship_manager?.name ?? "",
      ship_manager_submited_on:
        report.offhire_report_technical?.ship_manager_updated_report_at?.slice(
          0,
          16
        ) ?? "",
      fleet_manager_name:
        report.offhire_report_technical?.fleet_manager?.name ?? "",
      fleet_manager_submited_on:
        report.offhire_report_technical?.fleet_manager_updated_report_at?.slice(
          0,
          16
        ) ?? "",
      report_status: report.offhire_report_technical?.report_status ?? "",
      offhire_report_commercial_id: report.id,
      offhire_report_technical_id: report.offhire_report_technical?.id ?? "",
      // resolving the denesting key conflicts
      event: report.offhire_report_technical?.event ?? "",
      delay_start_at_gmt:
        report.offhire_report_technical?.delay_start_at_gmt ?? "",
      delay_end_at_gmt: report.offhire_report_technical?.delay_end_at_gmt ?? "",
      duration_in_days: getDaysBetweenDates(
        report.offhire_report_technical?.delay_start_at_gmt,
        report.offhire_report_technical?.delay_end_at_gmt
      )?.toFixed(2),
      commercial_reason: report.reason ?? "",
      commercial_delay_start_at_gmt: report.delay_start_at_gmt ?? "",
      commercial_delay_end_at_gmt: report.delay_end_at_gmt ?? "",
      created_at: report.created_at?.slice(0, 16) ?? "",
    };
  };

  updateAllFeildsValue = (responseFeildsObjectOrg) => {
    const responseFeildsObject = this.preProcessValuesForFeilds(
      responseFeildsObjectOrg
    );
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    let timestamp = null;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      if (value !== undefined) {
        // if (key === "last_data_entry_date") {
        //   //"2019-12-31T11:49:41.457000"
        //   timestamp = value;
        //   value = value.split("T")[0];
        // } else if (
        //   key === "import_operation_timestamp" ||
        //   key === "calculation_operation_timestamp"
        // ) {
        //   value = value.replace("T", " ").slice(0, 19);
        // }

        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({
      isAllLoaded: this.state.isAllLoaded + 1,
      feilds,
      last_sync_timestamp: timestamp,
      report_status: responseFeildsObjectOrg?.report_status,
    });
  };

  componentDidMount() {
    const { report } = this.props;
    let user = getUser();
    this.setState({ USERROLE: user?.role?.name?.toUpperCase() ?? "" });
    this.updateAllFeildsValue(report);
  }

  switchExpention = (expedPropertyName, isExpannd = true) => {
    let state = {
      isUpdateHistoryShown: false,
      isMoveEventDaysShown: false,
      isGroupReportShown: false,
    };

    typeof state === 'object' 
    && expedPropertyName in state 
    && (state[expedPropertyName] = isExpannd);

    this.setState(state);
  }

  render() {
    const { feilds, isUpdateHistoryShown, isMoveEventDaysShown, isGroupReportShown, USERROLE } =
      this.state;
    const { report, isGrouped } = this.props;
  
    let viewFeilds = [];
    for (let key in this.labels) {
      // Form Input Feilds
      viewFeilds.push({
        ...this.labels[key],
        name: key,
        value: feilds[key],
      });
    }
    const isFleetManager = USERROLE === "FLEETMANAGER";
    return (
      <>
        {/* <Row className="m-3">
          {viewFeilds.map((feild, index) => {
            if (feild?.type === "datetime-local" && feild.value?.length > 15) {
              feild.value = formatDatetime(feild.value);
            }
            return feild?.isGroupTitle === true ? (
              <div className="col-12" key={index + "_col_sec"}>
                <h6>{feild.title}</h6>
              </div>
            ) : (
              <Col4 key={index + "_col_sec"}>
                <AnLabelValue
                  key={index + "_an_label_value"}
                  label={feild.title}
                  value={feild.value}
                  name={feild.name}
                />
              </Col4>
            );
          })}
        </Row> */}

        <AnGroupElements
          componentsPerGroup={3}
          items={viewFeilds}
          condition={(item) => true}
          componentWrapper={(item, index) => {
            if (item?.type === "datetime-local" && item.value?.length > 15) {
              item.value = formatDatetime(item.value);
            }

            return item?.isGroupTitle === true ? (
              <div className="col-12" key={index + "_col_sec"}>
                <h6>{item.title}</h6>
              </div>
            ) : (
              <Col4 key={index + "_col_sec"}>
                <AnLabelValue
                  key={index + "_an_label_value"}
                  label={item.title}
                  value={item.value}
                  name={item.name}
                />
              </Col4>
            );
          }}
          rowClassName="mx-3 bg-light"
        />
        {/* <OffhireReportTechnicalUpdateHistory
                  reportID={vesselOffhireReport?.offhire_report_technical?.id}
                /> */}
        {report.offhire_report_technical && (
        <>
        <Row className=" pt-3">
          <div className="col-auto">
            <AnButtonExpandable
              isMaximised={isUpdateHistoryShown}
              value={"Update History"}
              onClick={(isMaximised) => {
                this.switchExpention('isUpdateHistoryShown', isMaximised);
                // this.setState({ isUpdateHistoryShown: isMaximised });
              }}
            />
          </div>
          {isFleetManager && (
            <div className="col-auto">
              <AnButtonExpandable
                isMaximised={isMoveEventDaysShown}
                value={"Move Event Days"}
                onClick={(isMaximised) => {
                  this.switchExpention('isMoveEventDaysShown', isMaximised);
                  // this.setState({ isMoveEventDaysShown: isMaximised });
                }}
              />
            </div>
          )}
          {isFleetManager && (
            <div className="col-auto">
              <AnButtonExpandable
                isMaximised={isGroupReportShown}
                value={"Group with Other Report"}
                onClick={(isMaximised) => {
                  this.switchExpention('isGroupReportShown', isMaximised);
                  // this.setState({ isGroupReportShown: isMaximised });
                }}
              />
            </div>
          )}
          {isGrouped && (
            <div className="col-auto">
            <AnButtonExpandable
              value={"UnGroup"}
              onClick={(isMaximised) => {
                ReportGroupService.unGroup(report?.offhire_report_technical?.id)
                .then(
                  res=>{
                    alert('Ungrouped Successfully.');
                  },
                  error=>{
                    const errorMessage = error?.response?.data?.detail;
                    const status = error?.response?.status ?? '';
                    alert(errorMessage ?? ('Error '+status));
                  }
                );
              }}
            />
          </div>
          )}
        </Row>
        {isUpdateHistoryShown && (
        <Dialog header="Change History" visible={isUpdateHistoryShown} onHide={() => this.switchExpention('isUpdateHistoryShown', false)}>
        <ORTUpdateHistory reportID={report?.offhire_report_technical?.id} />
        </Dialog>
        )}
        {isMoveEventDaysShown && isFleetManager && (
        <Dialog header="Move Event Days" visible={isMoveEventDaysShown} onHide={() => this.switchExpention('isMoveEventDaysShown', false)}>
        <Row className=" justify-content-center mx-3 px-3">
            <div className="col">
              <OffhireEventDaysEditForm
                technical_report_id={report?.offhire_report_technical?.id}
              />
            </div>
          </Row>
        </Dialog>
        // <OffhireEventDaysEditForm
          //   technical_report_id={report?.offhire_report_technical?.id}
          // />
          // <OffhireEventDaysScreen />
        )}
        {isGroupReportShown && isFleetManager && (
        <Dialog 
          header="Select a report to Group with" 
          visible={isGroupReportShown} 
          onHide={() => this.switchExpention('isGroupReportShown', false)}
          footer={this.groupReportFooterContent}>
        
            <SameVesselReportSelector 
              vesselCode={report?.vessel_code} 
              technicalReport={report?.offhire_report_technical}
              onSelect={(selectedReport)=>{
                this.setState({
                  reportsToGroup: {
                    first: report?.offhire_report_technical,
                    second: selectedReport?.offhire_report_technical
                  }
                });
              }}
            ></SameVesselReportSelector>
            
        </Dialog>
       )}
       </>
      )}
        
      </>
    );
  }

  moveEventDaysFooterContent = (
    <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => this.switchExpention('isMoveEventDaysShown', false)} className="p-button-text" />
        <Button label="Update" icon="pi pi-check" onClick={() => {
          // const {first, second} = this?.state?.reportsToGroup ?? {};
          // debugger;
          // if(!first || !second){
          //   alert('Please select alteast a report.');
          //   return;
          // }
          // ReportGroupService.group(first?.id, second?.id)
          // .then(
          //   res=>{
          //     this.switchExpention('isMoveEventDaysShown', false);
          //   },
          //   error=>{
          //     const errorMessage = error?.response?.data?.detail;
          //     const status = error?.response?.status ?? '';
          //     alert(errorMessage ?? ('Error '+status));
          //   }
          // );
          }} autoFocus />
    </div>
  );

  groupReportFooterContent = (
    <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => this.switchExpention('isGroupReportShown', false)} className="p-button-text" />
        <Button label="Group" icon="pi pi-check" onClick={() => {
          const {first, second} = this?.state?.reportsToGroup ?? {};
          // debugger;
          if(!first || !second){
            alert('Please select alteast a report.');
            return;
          }
          ReportGroupService.group(first?.id, second?.id)
          .then(
            res=>{
              this.switchExpention('isGroupReportShown', false);
            },
            error=>{
              const errorMessage = error?.response?.data?.detail;
              const status = error?.response?.status ?? '';
              alert(errorMessage ?? ('Error '+status)); 
            }
          );
          }} autoFocus />
    </div>
  );

}

export default OffhireReportTechnicalDetails;
