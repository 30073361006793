import React from "react";
import Select from "react-select";

function FilterSelect(props) {
  const { label, id, onChange, options, defaultValue,closeMenuOnSelect,isMulti } = props;
//   const styles = {
//     minWidth: {
//       minWidth: "150px",
//     },
//   };

  const styles = {
    minWidth: "150px",
    // maxWidth: "250px",
    // maxHeight: "20px",
    //  height: "20px",
    control: (base) => ({
      ...base,
    //   minHeight: 20,
    //   height: "20px",
    //   maxHeight: "20px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <div className="form-group" style={styles}>
      <label htmlFor={id} style={{ fontSize: ".75rem" }}>
        {label}
      </label>
      <Select
        id={id}
        onChange={(selectedOption) => {
          onChange({
            value: selectedOption,
            id: id,
          });
        }}
        // autosize={false}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        options={options}
        defaultValue={defaultValue}
      />
    </div>
  );
}

export default FilterSelect;
