import React, { Component } from "react";
// import AnFixedProgressBar from "../../core/AnFixedProgressBar";

class AnHPForm extends Component {
  state = {};

  render() {
    // console.log(this.props);
    const { title, children, onBack, onSubmit, onAddRequest } = this.props;
    return (
      <div className="row justify-content-center align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-12">
          {/* <div className="shadow p-3 mb-1 bg-white rounded"> */}
          <div className="row">
            {onBack && (
              <div className="col-sm-2 col-lg-1">
                <button
                  onClick={onBack}
                  className="form-control btn btn-light btn-md"
                >
                  <i className="fa fa-arrow-left"></i>
                </button>
              </div>
            )}
            <div className="col-sm-8 col-lg-10">
              <h4>{title}</h4>
            </div>
            {onAddRequest && (
              <div className="col-sm-2 col-lg-1">
                <button
                  onClick={onAddRequest}
                  className="form-control btn btn-light btn-md"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            )}
          </div>
          <form
            onSubmit={(e) => {
              onSubmit(e);
              e.preventDefault();
            }}
            autoComplete="off"
            id="form"
            className="needs-validation"
            //   novalidate=""
          >
            {children}
          </form>
          {/* </div> */}
          {/* shadow */}
        </div>
        {/* col */}
      </div>
      // row-e
    );
  }
}

export default AnHPForm;
