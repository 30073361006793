import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import EmployeeForm from "../forms/EmployeeForm";

function EmployeeScreen() {
  // in path last / in a must be
  return (
    <CommonListViewScreen
      path="/employee/all"
      dataPreprocessor={{
        mainTitle: "Employees",
        path: "/offhire/employee",
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "name" },
          { header: "Code", field: "code" },
          { header: "Type", field: "type" },
          { header: "Status", field: "status" },
          { header: "Email", field: "email" },
          { header: "Phone", field: "phone" },
          { header: "Address", field: "address" },
        ],
      }}
    >
      <EmployeeForm />
    </CommonListViewScreen>
  );
}

export default EmployeeScreen;
