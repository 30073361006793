import React, { Component } from "react";
import AnFormGroup from "../../core/components/forms/factory/AnFormGroup";
import Row from "../../core/components/other/Row";

class OffhireReportTechnicalFormFormat extends Component {
  state = {};
  render() {
    const { feilds } = this.props;
    return (
      <Row>
        {feilds.map((attribute, index) => {
          return <AnFormGroup attribute={attribute} key={index} />;
        })}
      </Row>
    );
  }
}

export default OffhireReportTechnicalFormFormat;
