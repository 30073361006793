import React, { Component } from "react";
import AnBordered from "../components/factory/AnBordered";
import { Outlet } from "react-router-dom";

class SmallFormsScreen extends Component {
  state = {};
  render() {
    const { cardTitle } = this.props;

    return (
      <React.Fragment>
        <div
          className="row justify-content-center mt-3"
          //   style={{ height: "80vh" }}
        >
          <AnBordered
            title={cardTitle}
            col="3"
            displaySizeMargin={0}
            hideOnPrint={true}
            // isMinimised={true}
            // minimiseIconName="cog"
            // holdRow={true}
            canMinimise={false}
            // aling="center"
            shadow={true}
          >
            <Outlet />
          </AnBordered>
        </div>
      </React.Fragment>
    );
  }
}

export default SmallFormsScreen;
