import React, { Component } from "react";
import AnDatalistInput from "./AnDatalistInput";
// import DataListInput from "react-datalist-input";
// import Select from "react-select";
// import AsyncSelect from "react-select/async";
// import AnAsyncSelect from "./AnAsyncSelect";
// import AnSelectSearch from "./AnSelectSearch";
import AnSearchableSelect from "./AnSearchableSelect";
import './AnInputElement.css';
import AnFileDownloadElement from "../AnFileDownoadElement";

class AnInputElement extends Component {
  state = {};
  render() {
    const { attribute, validClass } = this.props;
    const inputElementClass = `form-control form-control-lg p-1 ${validClass}`;
    const { style } = attribute;
    // CHECKBOX
    if (attribute.type === "checkbox") {
      return "checkbox";
    } else if (attribute.type === "select") {
      // SELECT
      attribute.options = attribute.options.map((option) => {
        return {
          value: option.value,
          title: option.title || option.label,
          label: option.title || option.label,
        };
      });

      return (
        <>
          <select
            className={inputElementClass}
            id={attribute.name}
            name={attribute.name}
            value={attribute.value}
            onChange={attribute.onChangeHandler}
            required={attribute.required === false ? false : true}
            readOnly={attribute.readOnly ? true : false}
            disabled={attribute.readOnly ? true : false}
            style={style}
            datalivesearch="true"
          >
            {attribute.options &&
              attribute.options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.title}
                  </option>
                );
              })}
          </select>

          {/* <Select
            // classNamePrefix={inputElementClass}
            // style={{
            //   control: (base) => ({
            //     ...base,
            //     height: 25,
            //     minHeight: 25,
            //   }),
            //   dropdownIndicator: (styles) => ({
            //     ...styles,
            //     paddingTop: 2,
            //     paddingBottom: 2,
            //   }),
            //   clearIndicator: (styles) => ({
            //     ...styles,
            //     paddingTop: 2,
            //     paddingBottom: 2,
            //   }),
            // }}
            name={attribute.name}
            // isDisabled={attribute.readOnly ? true : false}
            isSearchable={true}
            options={attribute.options}
            // defaultValue={attribute.options.filter(
            //   (option) => option.value === attribute.value
            // )}
            // defaultInputValue={
            //   attribute.options.filter(
            //     (option) => option.value === attribute.value
            //   )?.label
            // }
            onChange={(selelctedOption) => {
              attribute.onChangeHandler({
                target: { value: selelctedOption.value, name: attribute.name },
              });
            }}
            defaultValue={attribute.options.find(
              (option) => option.value === attribute.value
            )}
            // placeholder={
            //   attribute.options.find(
            //     (option) => option.value === attribute.value
            //   )?.label
            // }
            // setValupe={attribute.value}
            // defaultInputValue={{ value: attribute.value }}
          /> */}
        </>
      );
    } else if (attribute.type === "search-select") {
      // SEARCHABLE SELECT
      // return <AnSelectSearch attribute={attribute} />;
      return (
        <AnSearchableSelect
          className={inputElementClass}
          id={attribute.name}
          name={attribute.name}
          value={attribute.value}
          onChange={attribute.onChangeHandler}
          required={attribute.required === false ? false : true}
          readOnly={attribute.readOnly ? true : false}
          disabled={attribute.readOnly ? true : false}
          options={attribute.options}
          style={style}
        />
      );
    } else if (attribute.type === "datalist-input") {
      return (
        <AnDatalistInput
          className={inputElementClass}
          id={attribute.name}
          name={attribute.name}
          value={attribute.value}
          onChange={attribute.onChangeHandler}
          required={attribute.required === false ? false : true}
          style={style}
          readOnly={attribute.readOnly ? true : false}
          link={attribute?.link}
          linkSelectByKey={attribute?.linkSelectByKey}
          list={attribute?.list}
        />

        // <AsyncSelect
        // defaultValue={attribute.options.filter(
        //   (option) => option.value === attribute.value
        // )}
        // onChange={(selelctedOption) => {
        //   attribute.onChangeHandler({
        //     target: { value: selelctedOption.value, name: attribute.name },
        //   });
        // }}
        // isDisabled={attribute.readOnly ? true : false}
        // isSearchable={true}
        // loadOptions={(inputValue, callback) => {
        //   if (inputValue) {
        //     attribute.list(inputValue, (options) => {
        //       callback(options);
        //     });
        //   }
        // }}
        // />

        // // latest - uc
        // <AnAsyncSelect
        //   className={inputElementClass}
        //   id={attribute.name}
        //   name={attribute.name}
        //   value={attribute.value}
        //   onChange={attribute.onChangeHandler}
        //   required={attribute.required === false ? false : true}
        //   style={style}
        //   readOnly={attribute.readOnly ? true : false}
        //   link={attribute?.link}
        //   linkSelectByKey={attribute?.linkSelectByKey}
        //   list={attribute?.list}
        // />
      );
    } else if (attribute.type === "filler") {
      return <div className="col-12" />;
    } else if (attribute.type === "file") {
      const file = attribute.value?.files?.[0];
      const {fileAttachmentId} = attribute.value ?? {};
      const EDITABLE = !attribute?.readOnly;
      return (
        <div className="custom-file-sm mb-3 modified">
          { EDITABLE && <>
           <input 
              type={attribute.type} 
              className="custom-file-input" 
              id={attribute.name}
              name={attribute.name}
              onChange={attribute.onChangeHandler} 
              required={attribute.required === false ? false : true}
              />
              <input 
              type="hidden" 
              id={attribute.name+'FileAttachmentId'}
              name={attribute.name+'FileAttachmentId'}
              value={attribute.value?.fileAttachmentId ?? ""} 
              onChange={attribute.onChangeHandler}
              />
            <label className="custom-file-label" htmlFor={attribute.name}>{file ? `${file.name}`: 'Choose file...'}</label>
            <div className="invalid-feedback">Example invalid custom file feedback</div>
            </>}
            { fileAttachmentId && <AnFileDownloadElement attribute={attribute} paddingTop={EDITABLE}/>}
        </div>
      ); 
    } else if (attribute.type === "textarea") {
      return (
        <textarea
          type={attribute.type}
          className={inputElementClass}
          id={attribute.name}
          name={attribute.name}
          value={attribute.value}
          onChange={attribute.onChangeHandler}
          required={attribute.required === false ? false : true}
          style={style}
          maxLength={attribute.maxlength}
          readOnly={attribute.readOnly ? true : false}
          min={
            (attribute.type === "number" || attribute.type === "date") &&
            attribute.min
              ? attribute.min
              : ""
          }
          max={
            (attribute.type === "number" || attribute.type === "date") &&
            attribute.max
              ? attribute.max
              : ""
          }
          onWheel={(e) => attribute.type === "number" && e.target.blur()}
        >
        </textarea>
      );
    } else {
      return (
        <input
          type={attribute.type}
          className={inputElementClass}
          id={attribute.name}
          name={attribute.name}
          value={attribute.value}
          onChange={attribute.onChangeHandler}
          required={attribute.required === false ? false : true}
          style={style}
          maxLength={attribute.maxlength}
          readOnly={attribute.readOnly ? true : false}
          min={
            (attribute.type === "number" || attribute.type === "date") &&
            attribute.min
              ? attribute.min
              : ""
          }
          max={
            (attribute.type === "number" || attribute.type === "date") &&
            attribute.max
              ? attribute.max
              : ""
          }
          onWheel={(e) => attribute.type === "number" && e.target.blur()}
        />
      );
    }
  }
}

export default AnInputElement;
