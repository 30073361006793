import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import FleetForm from "../forms/FleetForm";

function FleetScreen() {
  // in path last / in a must be
  return (
    <CommonListViewScreen
      path="/fleet/"
      dataPreprocessor={{
        mainTitle: "Fleets",
        path: "/offhire/fleet",
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "fleet_name" },
          { header: "Code", field: "fleet_code" },
          { header: "Description", field: "fleet_description" },
          { header: "Type", field: "fleet_type" },
          { header: "Status", field: "fleet_status" },
          { header: "Manager ID", field: "fleet_manager.username" },
        ],
      }}
    >
      <FleetForm />
    </CommonListViewScreen>
    );
}

export default FleetScreen;
