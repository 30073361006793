import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import UserForm from "../forms/UserForm";

function UsersScreen() {
  return (
    <CommonListViewScreen
      path="/users/all"
      dataPreprocessor={{
        mainTitle: "Users",
        path: "/offhire/users",
        columns: [
          { header: "ID", field: "id" },
          { header: "Username", field: "username" },
          { header: "Email", field: "email" },
          { header: "Employee Name", field: "employee.name" },
          { header: "Role", field: "role.name" },
        ],
      }}
    >
      <UserForm />
    </CommonListViewScreen>
  );
}

export default UsersScreen;
