import React, { Component } from "react";
import OffhirePannelVessel from "./OffhirePannelVessel";
import AnSpinner from "../../core/components/other/AnSpinner";
import { Navigate } from "react-router-dom";
import { getAuthHeader } from "../../core/application/connection";
import { getOffhireDaysBetweenDates } from "../util/first";
import FilterSelect from "./FilterSelect";
import { EachItem } from "../../core/components/hoc/EachItem";
import { BudgetService, OffhireEventDaysService } from "../services/services";
import NmBreadcrumb from "../../core/layout/components/NmBreadcrumb";
class OffhireDashboardPannel2 extends Component {
  state = {
    isLoggedIn: true,
    resources: {},
    resourcesRequiredCount: 0,
    localResources: { vessels: {}, eventTypes: {} },
    eventDurationDays: {},
    year: 2022,
    fleetName: "FLTO",

    fleet_code: "",
    fleet_name: "",
    fleetOptions: [{ fleet_name: "Fleet One", fleet_code: "FLTO" }],
    // year: new Date().getFullYear(),
    filterByFleet: { label: "Fleet One", value: "FLTO" },
    filterByYear: {
      value: new Date().getFullYear(),
      label: new Date().getFullYear(),
    },
  };

  offhirePlannedBoardAOA = () => {
    const theadRow1 = ["Q1", "Q2", "Q3", "Q4"];
    // const year = new Date().getFullYear();
    const theadRow2 = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const theadRow3 = [
      "Vessel",
      "TYP",
      "MGR",
      ["PLAN", "ACTUAL"],
      "TOTAL",
      "BUDGET",
      "DIFF",
      "VESSEL",
    ];

    let panelData = [
      ["", "", "", ...theadRow1, "", "", "", ""],
      ["", "", "", ...theadRow2, "", "", "", ""],
      [...theadRow3],
    ];
  };

  /**
   *
   * @param {Date} startDatetime
   * @param {Date} endDatetime
   * @param {int} tciPercent
   * @returns
   */
  calculateEachMonthDaysOfYear = (
    startDatetime,
    endDatetime,
    tciPercent = 100
  ) => {
    let daysInEachMonth = [];
    //Event may span over multiple months
    for (
      let monthNumber = startDatetime.getMonth();
      monthNumber <= endDatetime.getMonth();
      monthNumber++
    ) {
      if (daysInEachMonth[monthNumber] === undefined) {
        daysInEachMonth[monthNumber] = 0;
      }
      // that month's start datetime and end datetime
      const monthStartDatetime = new Date(
        startDatetime.getFullYear(),
        monthNumber,
        1
      );
      const monthEndDatetime = new Date(
        startDatetime.getFullYear(),
        monthNumber + 1,
        1
      );
      // if the offhire start datetime is not in that month then consider start datetime as 1st of that month
      const requiredStartDatetime =
        startDatetime < monthStartDatetime ? monthStartDatetime : startDatetime;
      // if the offhire end datetime is not in that month then consider end datetime as last day of that month
      const requiredEndDatetime =
        endDatetime > monthEndDatetime ? monthEndDatetime : endDatetime;

      daysInEachMonth[monthNumber] = parseFloat(
        getOffhireDaysBetweenDates(
          requiredStartDatetime,
          requiredEndDatetime,
          tciPercent
        )
      );
    }
    return daysInEachMonth;
  };

  /**
   *
   * @param {*} startDatetime
   * @param {*} endDatetime
   * @param {*} year
   * @returns
   */
  moveDatetimeWithInYear = (startDatetime, endDatetime, year) => {
    startDatetime = new Date(startDatetime);
    endDatetime = new Date(endDatetime);
    //if not lies in the same year
    if (startDatetime.getFullYear() > year || endDatetime.getFullYear() < year)
      return;

    if (startDatetime.getFullYear() < year)
      startDatetime = new Date(year, 0, 1);
    if (endDatetime.getFullYear() > year) endDatetime = new Date(year, 11, 31);

    return { startDatetime, endDatetime };
  };

  /**
   *
   * @param {*} event
   * @param {*} year
   * @param {*} fleetPlannedDaysDuration
   * @param {Boolean} isPlanned
   */
  doSomthing = (event, year, fleetPlannedDaysDuration, isPlanned = true) => {
    let {
      vessel,
      plannedEventType,
      startDatetime,
      endDatetime,
      eachMonthDaysOfYear,
    } = event;

    // Vessel not found
    if (fleetPlannedDaysDuration[vessel] === undefined) {
      fleetPlannedDaysDuration[vessel] = {};
    }
    // Event type not found
    if (fleetPlannedDaysDuration[vessel][plannedEventType] === undefined) {
      fleetPlannedDaysDuration[vessel][plannedEventType] = {
        planned: [],
        actual: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      };
    }

    if (isPlanned) {
      const {
        startDatetime: startDatetimeOfYear,
        endDatetime: endDatetimeOfYear,
      } = this.moveDatetimeWithInYear(startDatetime, endDatetime, year);

      fleetPlannedDaysDuration[vessel][plannedEventType].planned =
        this.calculateEachMonthDaysOfYear(
          startDatetimeOfYear,
          endDatetimeOfYear
          //   tciPercent
        );
    } else {
      eachMonthDaysOfYear.forEach(
        (days, month) =>
          (fleetPlannedDaysDuration[vessel][plannedEventType].actual[month] +=
            days)
      );
      // fleetPlannedDaysDuration[vessel][plannedEventType].actual =
      //   eachMonthDaysOfYear;
    }
  };

  //   processBudgetEventObject = (item)={

  //   };

  processOffhireEventDays = (offhireEventDaysObj, year) => {
    let months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    let daysInMonthOfYear = [];
    months.forEach((month, index) => {
      daysInMonthOfYear[index] =
        offhireEventDaysObj[month + "_year"] == year
          ? offhireEventDaysObj[month]
          : 0;
    });
    return daysInMonthOfYear;
  };

  /*
   * offhireEvents - the fetched planned events
   * year - calculation year
   * fleetPlannedDaysDuration - resulted days
   * isPlanned - budgrt or events
   */
  getVesselEventsDurationDaysByMonth = (
    offhireEvents,
    year,
    fleetPlannedDaysDuration,
    isPlanned = true
  ) => {
    /**
     { vessel_code:{
       planned_event_type_id: [jan to dec],
     }}
     */
    let { vessels, eventTypes } = this.state.localResources;
    // For each event
    if (isPlanned) {
      //BUDGET
      offhireEvents.forEach((item) => {
        if (item?.planned_event_type) {
          eventTypes[item.planned_event_type_id] = {
            ...item?.planned_event_type,
          };
        }

        this.doSomthing(
          {
            vessel: item.vessel_code,
            plannedEventType: item.planned_event_type_id,
            startDatetime: item.start_date,
            endDatetime: item.end_date,
          },
          year,
          fleetPlannedDaysDuration,
          isPlanned
        );
      });
    } else {
      //ACTUAL
      offhireEvents.forEach((item) => {
        let plannedEventWithDays = item;
        item = plannedEventWithDays.OffhireReportCommercial;
        //based on report Planned or Unplanned
        // if (item?.offhire_report_technical?.is_planned === true) {
          vessels[item.vessel_code] = { name: item?.vessel_name };
          eventTypes[item.offhire_report_technical.event_type_id] = {
            ...eventTypes[item.offhire_report_technical.event_type_id],
            ...item?.offhire_report_technical?.event_type,
          };
          // debugger;
          this.doSomthing(
            {
              vessel: item.vessel_code,
              plannedEventType: item.offhire_report_technical.event_type_id,
              startDatetime: item.offhire_report_technical.delay_start_at_gmt,
              endDatetime: item.offhire_report_technical.delay_end_at_gmt,
              eachMonthDaysOfYear: this.processOffhireEventDays(
                plannedEventWithDays.OffhireEventDays,
                year
              ),
            },
            year,
            fleetPlannedDaysDuration,
            isPlanned
          );
        // } // if block ends
      });
    }

    this.setState({ localResources: { vessels, eventTypes } });
  };

  responceError = (error) => {
    // alert("Error: " + error.response.status);
    // debugger;
    console.error("Error: " + error.response.status);
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.setState({
        onProgress: false,
        // isLoaded: true,
        error: "No data found for the given dates.",
      });
    } else {
      this.setState({
        onProgress: false,
        // isLoaded: false,
        error: "Error: " + error.response.statusText,
      });
    }
    // this.setState({
    //   resources: {},
    //   eventDurationDays: {},
    //   localResources: { vessels: {}, eventTypes: {} },
    // });
  };

  onSuccess = (response, resource, selectedYear) => {
    const eventDurationDays = this.state.eventDurationDays;

    // console.log("Response " + resource.resourceType);
    // console.log(response.data);
    if (response.status === 200) {
      const events = response.data;

      if (
        resource.resourceType === "Budget" ||
        resource.resourceType === "Offhire"
      ) {
        this.getVesselEventsDurationDaysByMonth(
          events,
          selectedYear,
          eventDurationDays,
          resource.resourceType === "Budget" ? true : false
        );
      }

      this.setState({
        resources: {
          ...this.state.resources,
          [resource.resourceType]: events,
        },
        eventDurationDays: eventDurationDays,
      });
    }
  };

  // Fetch the data from the APIs
  fetchResources = (isFilterActive = false) => {
    const {
      filterByYear: { value: selectedYear },
      filterByFleet: { value: selectedFleetCode },
    } = this.state;

    if (!selectedYear || !selectedFleetCode) {
      alert("Please select filter values.");
      return;
    }

    const resources = [{ resourceType: "Budget" }, { resourceType: "Offhire" }];

    this.setState({ resourcesRequiredCount: resources.length });
    this.setState({
      resources: {},
      eventDurationDays: {},
      localResources: { vessels: {}, eventTypes: {} },
      onProgress: true,
    });

    Promise.allSettled([
      BudgetService.getAll(selectedYear),
      // OffhireEventDaysService.getPlanned(selectedYear, selectedFleetCode)
      OffhireEventDaysService.getBoth(selectedYear, selectedFleetCode),
    ]).then(
      (responses) => {
        // debugger;
        responses?.forEach((response, index) => {
          response.status==='fulfilled' &&  this.onSuccess(response.value, resources[index], selectedYear);
          // response.status==='rejected' && this.responceError(response.reason);
        });
        this.setState({ onProgress: false });
      },
      (error) => {
        this.responceError(error);
      }
    );
  };

  componentDidMount() {
    this.fetchResources();
  }

  handleSelectFilterChange = (selectedOption) => {
    if (selectedOption.id === "report_filter_fleet") {
      this.setState({
        filterByFleet: selectedOption.value,
      });
    } else if (selectedOption.id === "report_filter_year") {
      this.setState({
        filterByYear: selectedOption.value,
      });
    }
  };

  fetchReportsByFilterMultiOptions = (filters) => this.fetchResources(true);

  render() {
    const {
      resourcesRequiredCount,
      resources,
      localResources,
      eventDurationDays,
      isLoggedIn,
      onProgress,
      // year,
      fleetName,
      fleet_code,
      fleet_name,
      fleetOptions,
      filterByFleet,
      filterByYear,
    } = this.state;
    // Login check
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;

    if (onProgress) {
      return <AnSpinner />;
    }
    
    // if (
    //   resourcesRequiredCount === 0 ||
    //   Object.keys(resources).length < resourcesRequiredCount
    // ) {
    //   return <AnSpinner />;
    // }

    const theadRow1 = ["Q1", "Q2", "Q3", "Q4"];
    const theadRow2 = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const theadRow3 = [
      "Vessel",
      "TYP",
      "MGR",
      ["PLAN", "ACTUAL"],
      "TOTAL",
      "BUDGET",
      "DIFF",
      "VESSEL",
    ];
    // console.log(eventDurationDays);
    let year = filterByYear?.label;
    let currentYear = new Date().getFullYear();
    let fleetFilterDefaultSelectedOption = filterByFleet;

    return (
      <React.Fragment>

        <NmBreadcrumb items={[{label: "Offhire Dashboard"}]} />

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Offhire Pannel</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col p-3">
                Fleet: <span>{filterByFleet?.label}</span>
              </div>
              {/* Filters */}
              <div className="col-sm-6 col-md-auto align-self-end">
                <div className="row">
                  {/* <div className="col-sm-6 col-md-auto align-self-center">
                    <div className="">
                      <div className="p-1">
                        {page > 1 && (
                          <span
                            className="btn btn-outline-secondary py-0 btn-sm"
                            onClick={() => {
                              this.fetchReportsByFilterMultiOptions({
                                page: page - 1,
                              });
                            }}
                          >
                            <i className="fa fa-angle-left"></i>
                          </span>
                        )}
                        <span className="m-3 py-3">Page {page}</span>
                        {vesselReports && vesselReports.length === 10 && (
                          <span
                            className="btn btn-outline-secondary py-0 btn-sm"
                            onClick={() => {
                              this.fetchReportsByFilterMultiOptions({
                                page: page + 1,
                              });
                            }}
                          >
                            <i className="fa fa-angle-right" />
                          </span>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-md-auto">
                    <FilterSelect
                      label="Fleet"
                      closeMenuOnSelect={false}
                      isMulti={false}
                      id="report_filter_fleet"
                      onChange={this.handleSelectFilterChange}
                      options={
                        fleetOptions?.length > 0
                          ? fleetOptions.map((fleetOpt) => {
                              return {
                                value: fleetOpt.fleet_code,
                                label: fleetOpt.fleet_name,
                              };
                            })
                          : { value: fleet_code, label: fleet_name }
                      }
                      defaultValue={fleetFilterDefaultSelectedOption}
                    ></FilterSelect>
                  </div>

                  <div className="col-sm-6 col-md-auto">
                    <FilterSelect
                      label="Year"
                      closeMenuOnSelect={false}
                      isMulti={false}
                      id="report_filter_year"
                      onChange={this.handleSelectFilterChange}
                      options={[
                        ...Array(5)
                          .fill(currentYear)
                          .map((year, index) => {
                            return {
                              value: currentYear - index,
                              label: currentYear - index,
                            };
                          }),
                        // { value: "1111", label: "All" },
                      ]}
                      defaultValue={filterByYear}
                    ></FilterSelect>
                  </div>
                  <div className="col-sm-6 col-md-auto pt-lg-3">
                    <button
                      className="btn btn-sm btn-secondary mt-lg-3"
                      onClick={this.fetchReportsByFilterMultiOptions}
                    >
                      Apply Filter
                    </button>
                  </div>
                </div>
                {/* Filters - row end */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-sm "
                    style={{ fontSize: "0.7rem" }}
                  >
                    <thead>
                      {/* head row 1 */}
                      <tr>
                        <th colSpan={3}></th>
                        <EachItem of={theadRow1} render={(item, index) => <th key={item} colSpan={6}>({item + " " + year})</th>} />
                        <th colSpan={4}></th>
                      </tr>
                      {/* head row 2 */}
                      <tr>
                        <th colSpan={3}></th>
                        <EachItem of={theadRow2} render={(item, index) => <th key={item} className="table-secondary" colSpan={2}>{item}</th>} />
                        <th colSpan={4}></th>
                      </tr>
                      {/* head row 3 */}
                      <tr>
                        <EachItem of={theadRow3} render={(item, index) => 
                          typeof item === "object" ?
                            <EachItem of={theadRow2} render={(item2, index2) =>
                              <React.Fragment>
                              <th>{item[0]}</th>
                              <th>{item[1]}</th>
                              </React.Fragment>} />
                          : <th>{item}</th>
                      }/>
                      </tr>
                    </thead>
                    <tbody>
                      <EachItem of={Object.keys(eventDurationDays)} render={(vessel_code, index) =>
                        <EachItem of={Object.keys(eventDurationDays[vessel_code])} render={(event_type_id, index2) => 
                          <OffhirePannelVessel keyy={index + "" + index2}
                            vesselOffhire={{
                              vesselName: localResources?.vessels[vessel_code]?.name ?? vessel_code,
                              vesselType: localResources?.eventTypes[event_type_id]?.name ?? event_type_id,
                              vesselManager: "Vessel Manager",
                              vesselPlan: eventDurationDays[vessel_code][event_type_id].planned,
                              vesselActual: eventDurationDays[vessel_code][event_type_id].actual,
                            }}/>
                          }/>
                        }/>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OffhireDashboardPannel2;
