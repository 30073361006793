import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import { Calendar } from 'primereact/calendar';
import { formatDatetime } from "../../core/utility/conversion";
import BudgetForm from "../forms-dialog/BudgetForm";

class OffhireBudgetScreen extends Component {
  state = {
    date: new Date(),
  };

  render() {
    const { date } = this.state;
    const year = date.getFullYear();
    return (
        <CommonListViewScreen
          path={"/events/item/planned/all/" + year}
          dataPreprocessor={{
            mainTitle: `Offhire Budget (${year})`,
            path: "/offhire/budget",
            columns: [
              { header: "ID", field: "id" },
              { header: "Name", field: "event_name" },
              { header: "Vessel Code", field: "vessel_code" },
              { header: "Operation Cost", field: "operation_cost" },
              { header: "Starts At", body: (rowData) => formatDatetime(rowData.start_date)},
              { header: "Ends At", body: (rowData) => formatDatetime(rowData.end_date)},
              { header: "Days", body: (rowData) => rowData.duration_in_days?.toFixed(5)},
              { header: "Event Type", field: "planned_event_type.name" },
              { header: "Event Category", field: "planned_event_type.offhire_events_category.name" },
            ],
          }}
          headerTemplate={() => 
          <div className="row justify-content-center mt-3">
            <div className="col-auto">
              Year: <Calendar 
                value={date} 
                onChange={(e) => this.setState({date:e.target.value})} 
                view="year" 
                dateFormat="yy" 
                showIcon
                />
            </div>
          </div>}
        >
          <BudgetForm />
        </CommonListViewScreen>
    );
  }
}

export default OffhireBudgetScreen;
