import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function EventCategoryForm(props) {

    const labels = {
        event_category_name: {
          isGroupTitle: false,
          title: "Name",
          type: "text",
          value: "",
        },
        event_category_description: {
          isGroupTitle: false,
          title: "Description",
          type: "text",
          value: "",
        },
      };

      const getFieldsMap = () => {
        // front:back
        return {
          event_category_name: "name",
          event_category_description: "description",
        };
      };

    return (
        <AnCommonFormDialog
            {...props}
            header="Add Event Category"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/events/category/"
        />
    );
}

export default EventCategoryForm;