import React from "react";
import { EachItem } from "../../core/components/hoc/EachItem";
import VesselOffhireReport from "./VesselOffhireReport";


const VesselOffhireReportGroup = ({ offhireReport, srNo, columns, onAction, reportsIdForApprovalHandler, reportsIdForRemoveHandler }) => {
    return (
        <>
        <EachItem 
            of={offhireReport?.offhire_report_technical?.report?.report_group?.group?.report_groups}
            render={(groupedReport, index3) => {
                const {
                duplicate_commercial_report,
                commercial_report,
                } = groupedReport?.member_report ?? {};
    
                return (
                <VesselOffhireReport
                    vesselOffhireReport={
                    duplicate_commercial_report ??
                    commercial_report
                    }
                    key={"report_row_v_G_" + index3}
                    srno={`${srNo}.${index3+1}`}
                    reportsIdForApprovalHandler={reportsIdForApprovalHandler}
                    columns={columns}
                    isGrouped={true}
                />
                );
            }}
            
            empty={
                <VesselOffhireReport
                    vesselOffhireReport={offhireReport}
                    key={"report_row_v_" + srNo}
                    srno={srNo}
                    reportsIdForApprovalHandler={reportsIdForApprovalHandler}
                    reportsIdForRemoveHandler={reportsIdForRemoveHandler}
                    columns={columns}
                    onAction={onAction}
                />
            } />
        </>
    );
};

export default VesselOffhireReportGroup;