import React, { Component } from "react";
import AnFormGroup from "./AnFormGroup";

class AnFormBlock extends Component {
  state = {};
  render() {
    // debugger;
    const { key, elements, legend, children } = this.props;
    let formBlock = { legend: "", formGroupElements: [] };
    let isEndNode = true;
    return (
      <fieldset
        className="form-group"
        style={{
          border: "0.5px solid #ccc",
          padding: "10px",
          margin: "10px",
          borderRadius: "5px",
          //   boxShadow: "0 0 5px #ccc",
        }}
      >
        <legend>{legend}</legend>
        <>
          {elements.map((attribute, index) => {
            return (
              <AnFormGroup key={key + "_" + index} attribute={attribute} />
            );
          })}
        </>
      </fieldset>
    );
  }
}
export default AnFormBlock;
