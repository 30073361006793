import axios from "axios"
import { getAuthHeader } from "../../core/application/connection"

export const BaseService = {
    get: (url) => axios.get(url, {headers: getAuthHeader()}),
    create: (url, data) => axios.post(url, data, {headers: getAuthHeader()}),
    update: (url, data) => axios.put(url, data, {headers: getAuthHeader()}),
    delete: (url) => axios.delete(url, {headers: getAuthHeader()}),
}

export const UserService = {
    create: (user) => BaseService.create("/users/", user),
    getLoggedInUser: () => BaseService.get("/users/"),
}

export const LoginService = {
    login: (user) => axios.post("/login", user, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    // logout: () => axios.post("/logout/"),
}
export const NotificationService = {
    get: () => axios.get("/api/notification"),

}

export const FleetService = {
    getFleetManagers: () => axios.get("/person/fleetmanager", {headers: getAuthHeader()}),
    create: (fleet) => axios.post("/fleet/", fleet, {headers: getAuthHeader()}),
}

export const VesselService = {
    getShipManagers: () => axios.get("/person/shipmanager", {headers: getAuthHeader()}),
    get: () => axios.get("/vessel/", {headers: getAuthHeader()}),
    getAsOptions: () => BaseService.get("/vessel/options"),
    create: (vessel) => axios.post("/vessel/", vessel, {headers: getAuthHeader()}),
}

export const SeaService = {
    getAll: () => BaseService.get("sea/all"),
}

export const MachineryTypeService = {
    getAll: () => BaseService.get("/machinery/type/"),
    create: (machineryType) => BaseService.create("/machinery/type/", machineryType),
}

export const MachineryService = {
    getAll: () => BaseService.get("/machinery/"),
    create: (machinery) => BaseService.create("/machinery/", machinery),

}

export const EventCategoryService = {
    getAll: () => BaseService.get("/events/category/"),
    create: (eventCategory) => BaseService.create("/events/category/", eventCategory),
}

export const EventTypeService = {
    getAll: () => BaseService.get("/events/type/"),
    create: (eventType) => BaseService.create("/events/type/", eventType),
}

export const EventDaysService = {
    update: (eventDays) => BaseService.update("/reports/event_days/", eventDays),
    get: (technicalReportID) => BaseService.get(`/reports/event_days/${technicalReportID}`),
}

export const PlannedEventService = {
    create: (plannedEvent) => BaseService.create("/events/item/planned", plannedEvent),
}

export const OffhireReportService = {
    createTechnical: (report) => BaseService.create("/reports/combined/", report),
    approveTechical: (report) => BaseService.update("/reports/combined/approval/", report),
}

export const OffhireReportChangeHistoryService = {
    get: (reportID) => BaseService.get(`/reports/history/${reportID}`),
}