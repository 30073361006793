import React, { Children, Component } from "react";

function Col4(props) {
  return (
    <div className={"col-sm-12 col-md-6 col-lg-4 " + props.className}>
      {props.children}
    </div>
  );
}

export default Col4;
