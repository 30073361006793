import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

// axios.defaults.baseURL = "http://localhost:8001/api/v1/offhire/";
// axios.defaults.baseURL = "http://localhost:8080/api/v1/offhire/";
axios.defaults.baseURL = "https://offhire.enervamarine.com/api/v1/offhire/";
// axios.defaults.baseURL = "/api/v1/offhire/";
// axios.defaults.baseURL = "https://d89f-103-108-4-115.ngrok.io/api/v1/offhire/";
// cd Downloads/ngrok-stable-windows-amd64
// ngrok http 8000

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
