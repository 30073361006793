import { Children } from "react";
import { IfItem } from "./IfItem";

export const EachItem = ({render, of, empty}) => {
    const eachBase = (_of) => Children.toArray(_of.map((item, index)=>render(item, index)));
    return <>
        {empty || empty===null 
            ? <IfItem items={of} render={eachBase} elseTemplate={empty}/>
            : eachBase(of) 
        }
        </>;
}