import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import RoleForm from "../forms/RoleForm";

function RoleScreen() {
  // in path last / in a must be
  return (
    <CommonListViewScreen
      path="/role/all"
      dataPreprocessor={{
        mainTitle: "Roles",
        path: "/offhire/role",
        columns: [
          { header: "ID", field: "id" },
          { header: "Name", field: "name" },
          { header: "Description", field: "description" },
        ],
      }}
    >
      <RoleForm />
    </CommonListViewScreen>
  );
}

export default RoleScreen;
