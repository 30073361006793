import React, { Component } from "react";
import AnScreenCenter from "../factory/AnScreenCenter";

function AnSpinner(props){
  return (
    <AnScreenCenter>
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span> */}
    </AnScreenCenter>
  );
}

export default AnSpinner;
