export const PROJECT_CODENAME = "offhire";

export const PROJECT_NAME = "Enerva Marine Pvt. Ltd.";

export const PROJECT_BRAND_NAME = "Offhire 0.6.3";

export const PROJECT_HOST = "localhost";

export const PROJECT_ROOT = ""; //"."; //"/enervapurchase";

// export const PROJECT_ABS_PATH = $_SERVER['DOCUMENT_ROOT'] + PROJECT_ROOT;
export const PROJECT_ABS_PATH = PROJECT_ROOT;

export const PROJECT_MODULES_PATH = PROJECT_ROOT + "/mods";

/*
 * Features Resources Directories
 */
export const ICON_PKG = PROJECT_ROOT + "/includes/resources/images/img-health-icon";
export const BANNERS_ABS_PATH = PROJECT_ABS_PATH + "/az/resources/data/media/banners";
export const BANNERS_PATH = PROJECT_ROOT + "/az/resources/data/media/banners";
//PROJECT_ABS_PATH.'/az/resources/data/media/banners'
export const ARTICLES_ABS_PATH = PROJECT_ABS_PATH + "/az/resources/data/media/articles";
export const ARTICLES_PATH = PROJECT_ROOT + "/az/resources/data/media/articles";
export const CUSTOM_TEMPLATE = PROJECT_ROOT + "/includes/template/gentelella-master";
export const ANNOUNCEMENT = "Welcome";

/*
 * COLOR PALLETE
 */
//Header Title and Announcement Bar
export const COLOR_PRIMARY_DARK = "#1EAEFA";
export const COLOR_PRIMARY_LIGHT = "#FFFFFF";
//Header Buttons Text
export const COLOR_SECONDARY_DARK = "#000000";
export const COLOR_SECONDARY_LIGHT = "#faf9f5";

export const ALL_TEXT_COLOR = "#000000";

export const COLOR_ALTERNATE = "#00000F";
export const BRAND_NAME_COLOR = "#000000";

/*
 * ICON PACKS
 */
export const PROJECT_ICON = "azo.ico";
export const PROJECT_LOGO_2 = "azo_white.png";
export const PROJECT_LOGO = "enerva_solutions.jpg";
export const PROJECT_LOGO_3 = "enlogo.jpeg";
export const PROJECT_PRIMARY_BACKGROUNG = "green_solid.jpg";
export const PROJECT_SECONDARY_BACKGROUNG = "bg3.jpg";

const glob = {};
export default glob;

// export const cHeadLogoHeight = 55;
export const cHeadLogoHeight = 45;
export const cHeadLogoWidth = (140 * cHeadLogoHeight) / 55;
// width: 125, height: 60

export const cHeadNavHeight = "55px";
