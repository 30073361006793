import { storageLocal } from "../../core/application/storage";
import { MachineryService, PortService } from "../services/services";

export function fetchPorts() {
  return PortService.getAll().then(
    (response) => {
      if (response.status === 200) {
        storePorts(response.data);
      }
    });
}

export function fetchMachienry() {
  MachineryService.getAll().then(
      (response) => {
        if (response.status === 200) {
          const machinery = response.data;
          storeMachinery(machinery);
        }
      },
    );
}

function storeMachinery(machinery) {
  storageLocal.set("machinery", machinery);
}

export function loadMachinery() {
  return storageLocal.get("machinery");
}

function storePorts(ports) {
  storageLocal.set("ports", ports);
}

export function loadPorts() {
  return storageLocal.get("ports");
}
