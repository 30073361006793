import React, { Component } from "react";
import { EachItem } from "../../core/components/hoc/EachItem";

class OffhirePannelVessel extends Component {
  state = {};
  render() {
    const { vesselOffhire, keyy } = this.props;
    const {
      vesselName,
      vesselType,
      vesselManager,
      vesselPlan,
      vesselActual,
    } = vesselOffhire;
    // console.log(this.props);
    let total = 0,
      budget = 0,
      diff = 0,
      lastActiveIndex = 0;

    for (let i = 0; i < 12; i++) {
      vesselPlan[i] = vesselPlan[i] ?? 0;
      vesselActual[i] = vesselActual[i] ?? 0;
      total += vesselActual[i];
      lastActiveIndex = vesselActual[i] > 0 || vesselPlan[i] > 0 ? i : lastActiveIndex;
      budget += vesselPlan[i];
    }

    diff = total - budget;

    return (
      <tr key={keyy}>
        <td className="table-info">{vesselName}</td>
        <td className="table-info">{vesselType}</td>
        <td className="table-info">{vesselManager}</td>
        <EachItem of={vesselPlan} render={(plan, index) => 
          index <= lastActiveIndex ?
            <>
              <td key={index+'c1'} className="table-info">{plan > 0 ? plan.toFixed(2) : "0"}</td>
              <td key={index+'c2'} className="table-info">{vesselActual[index] > 0 ? vesselActual[index]?.toFixed(2) : "0"}</td>
            </>
            : <>
              <td key={index+'c3'}></td>
              <td key={index+'c4'}></td>
            </>
        } />
        <td className="table-info">{total?.toFixed(3)}</td>
        <td className="table-info">{budget?.toFixed(3)}</td>
        <td className={diff > 0 ? "table-danger text-danger" : "table-success text-success"}>
          {diff.toFixed(3)}
        </td>
        <td className={lastActiveIndex === 0 ? "" : "table-info"}>
          {vesselName}
        </td>
      </tr>
    );
  }
}

export default OffhirePannelVessel;
