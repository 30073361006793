import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import DataListInput from "react-datalist-input";
import { BaseService } from "../../../../offhire/services/offhire-services";

class AnDatalistInput extends Component {
  state = {
    onProgress: false,
    isLoaded: false,
    error: null,
    options: [],
    value: "",
    doReset: true,
    lastLink: "",
  };

  // handleValueChange = (e) => {
  //   let { feilds } = this.state;
  //   let key = e.target.name;
  //   let value = e.target.value.toString();
  //   // if (key === "machinery_type") {
  //   //   value = value.toUpperCase();
  //   // }
  //   if (value.length > 2) {
  //     this.fetchData(value);
  //   }
  //   feilds[key] = value;
  //   this.setState({ feilds });
  // };

  handleInput = (value) => {
    this.setMainValue("");
    this.setState({ value });
    this.fetchData(value);
  };

  onSelectHandler = (selectedItem) => {
    this.setMainValue(selectedItem.key);
    this.setState({ value: selectedItem.label, doReset: true });
  };

  clearOnClick = () => {
    const { value } = this.props;
    // this.setState({ value: "", options: [], doReset: false });
    const { value: selectorValue, doReset } = this.state;
    if (
      (value === "" && selectorValue?.length > 0 && doReset) ||
      this.state.lastLink !== this.getSelectedLink()
      // value === "" &&
      // ((selectorValue?.length > 0 && doReset) ||
      //   this.state.lastLink !== this.getSelectedLink())
    ) {
      this.clearFeild();
      this.setMainValue("");
    }
  };

  clearFeild = () => {
    this.setState({ value: "", options: [], doReset: false });
  };

  setMainValue = (value) => {
    const { onChange, name } = this.props;
    onChange({ target: { name: name, value: value } });
  };

  getSelectedLinkObject = () => {
    const { link, linkSelectByKey } = this.props;
    // if multiple links are provided, then we need to find the correct link with the help of key
    return (
      link?.length > 0 &&
      linkSelectByKey?.length > 0 &&
      link.filter((item) => item.key === linkSelectByKey)[0]
    );
  };

  getSelectedLink = (selectedLinkObj) => {
    const { link } = this.props;
    if (!selectedLinkObj) {
      selectedLinkObj = this.getSelectedLinkObject();
    }
    return selectedLinkObj?.link ?? link;
  };

  fetchData = (value) => {
    const { options } = this.state;

    // if multiple links are provided, then we need to find the correct link with the help of key
    const selectedLinkObj = this.getSelectedLinkObject();
    let apiLink = this.getSelectedLink(selectedLinkObj);
    this.setState({ lastLink: apiLink });

    // if link does not exist, then we skip the fetching of data
    if (typeof apiLink !== "string" || apiLink?.length <= 0) {
      return;
    }
    if (selectedLinkObj?.fetch === "once") {
      if (options?.length > 0) return;
    } else {
      if (value?.length < 3) return;
      apiLink += value;
    }

    this.setState({ onProgress: true });
    BaseService.get(apiLink)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          const listOptions = data.map((option) => {
            // return {
            //   value: option.id,
            //   title: option.name,
            // };
            return {
              key: option.id,
              label: option.name,
            };
          });
          this.setState({
            onProgress: false,
            isLoaded: true,
            options: listOptions,
          });
        }
      })
      .catch((error) => {
        // alert("Error: " + error.response.status);
        if (error.response.status === 401) {
          this.setState({ isLoggedIn: false });
        }
        if (error.response.status === 404) {
          this.setState({
            onProgress: false,
            isLoaded: false,
            error: "No data found.",
          });
        } else {
          this.setState({
            onProgress: false,
            isLoaded: false,
          });
        }
      });
  };

  render() {
    const { options, isLoaded } = this.state;
    let { list } = this.props;
    if (isLoaded) {
      list = options;
    }

    // list = [{ value: "", title: "Select" }];
    if (!list) {
      list = [{ label: "", key: "Select" }];
    }

    return this.dataList(list);
  }

  dataList = (list) => {
    const { className, id, name, value, onChange, required, style, readOnly } =
      this.props;
    const { value: selectorValue, doReset } = this.state;
    // if (value === "" && selectorValue?.length > 0 && doReset) {
    //   this.setState({ value: "", options: [], doReset: false });
    // }
    doReset && value === "" && this.clearOnClick();
    return (
      <React.Fragment>
        <DataListInput
          // style={style}
          inputClassName={className}
          value={selectorValue}
          items={list}
          // requiredInputLength={1}
          onInput={this.handleInput}
          onSelect={this.onSelectHandler}
          suppressReselect={false}
          onClick={this.clearOnClick}
          placeholder="Search and Select"
        />
        <input
          type="text"
          // type="hidden"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
        />
      </React.Fragment>
    );
  };

  // customDataList() {
  //   const {
  //     className,
  //     id,
  //     name,
  //     value,
  //     onChange,
  //     required,
  //     style,
  //     readOnly,
  //     list: list_,
  //   } = this.props;
  //   const list = list_.map((option) => {
  //     return {
  //       value: option.id,
  //       title: option.name,
  //     };
  //   });
  //   return (
  //     <React.Fragment>
  //       <input
  //         type="text"
  //         className={className}
  //         // id={id+"_selector"}
  //         // name={name + "_selector"}
  //         id={id}
  //         name={name}
  //         value={value}
  //         list={name + "_datalist-input"}
  //         onChange={this.handleValueChange}
  //         onSelect={(e) => {
  //           onChange(e);
  //         }}
  //         required={required === false ? false : true}
  //         style={style}
  //         readOnly={readOnly ? true : false}
  //       />
  //       {/* <input
  //         type="text"
  //         className={className}
  //         id={id}
  //         name={name}
  //         value={value}
  //         // onChange={onChange}
  //         required={required === false ? false : true}
  //         style={style}
  //         readOnly={readOnly ? true : false}
  //       /> */}
  //       <datalist id={name + "_datalist-input"}>
  //         {list &&
  //           list.map((item) => (
  //             <option value={item.value}>{item.title}</option>
  //           ))}
  //       </datalist>
  //     </React.Fragment>
  //   );
  // }
}

export default AnDatalistInput;
