import axios from "axios";
import { getAuthHeader } from "../../core/application/connection";
import { getExtensionByMediaType } from "../../core/application/services/file.service";
import { OffhireAPIRoutes } from "../application/apiSources";
import OffhireReportFormService from "./OffhireReportFormService";
// import { loadMachinery, loadPorts } from "../util/data";
// import EventCategoryService from "./EventCategoryService";
// import EventTypeService from "./EventTypeService";
// import SeaService from "./SeaService";

export const OffhireReportService = {
  /**
   * Fetch reports by filters
   * @param {*} filterObject
   * @returns
   */
  getFiltered: (filterObject) => {
    // return axios.get(OffhireAPIRoutes.REPORT, {headers: getAuthHeader()});
    return axios.post(`${OffhireAPIRoutes.REPORT}filtered`, filterObject, {
      headers: getAuthHeader(),
    });
  },

  /**
   * Fetch last selected filtered values
   * @returns
   */
  get: () => {
    return axios.get(`${OffhireAPIRoutes.REPORT}by-vessel/filtered`, {
      headers: getAuthHeader(),
    });
  },

  approveMultiple: (reportsIdForApproval) => {
    const data = {
      ids: reportsIdForApproval,
    };
    return axios.post(`${OffhireAPIRoutes.REPORT}approval/multi`, data, {
      headers: getAuthHeader(),
    });
  },

  removeMultiple: (reportsIdToRemove) => {
    const data = {
      ids: reportsIdToRemove,
    };
    return axios.post(`${OffhireAPIRoutes.REPORT}remove/multi`, data, {
      headers: getAuthHeader(),
    });
  },

  /**
   * Fetches all(only filled with technical data) Offhire Report Commercials without memorising the filters
   * @param {*} filterObject
   * @returns
   */
  getFilteredNonMemo: (filterObject) => {
    return axios.post(`${OffhireAPIRoutes.REPORT}filtered/not-mem`, filterObject, {
      headers: getAuthHeader(),
    });
  },

  create: (report) => {
    return axios.post(OffhireAPIRoutes.REPORT, report, {
      headers: getAuthHeader(),
    });
  },

  update: (report) => {
    return axios.put(OffhireAPIRoutes.REPORT, report, {
      headers: getAuthHeader(),
    });
  },

  // add new technical report in commercial
  add: (report) => {
    return axios.post(`${OffhireAPIRoutes.REPORT}add/`, report, {
      headers: getAuthHeader(),
    });
  },

  approve: (report) => {
    return axios.put(`${OffhireAPIRoutes.REPORT}approval/`, report, {
      headers: getAuthHeader(),
    });
  },

  /**
   * 
   * @param {{
   *     filterByVessel,
   *     filterByYear,
   *     filterByMonth,
   *     pageToFetch,
   *   }} filterObject 
   */
  storeFilter: (filterObject) => {
    // Store the filter values in the local storage
    localStorage.setItem("reports_state", JSON.stringify(filterObject));
  },
  
  loadFilter: () => {
    // Load the filter values from the local storage
    return JSON.parse(localStorage.getItem("reports_state") ?? "null");    
  }


}

export const offhireAttachmentDownloadHandler = (e, feilds) => {
  // debugger;
  e.preventDefault();
  const downloadButtonName = e.target?.parentElement?.name ?? "";
  const BTN_MARKER = "DownloadButton";
  const FIELD_KEY = downloadButtonName.endsWith(BTN_MARKER)
    ? downloadButtonName.replace(BTN_MARKER, "")
    : "";
  const fileAttachmentId = feilds?.[FIELD_KEY]?.fileAttachmentId;
  if (!fileAttachmentId) return;
  const offhireReportFormService = new OffhireReportFormService();
  offhireReportFormService
    .downloadAttachment(fileAttachmentId)
    .then((response) => {
      const fileName =
        "attachment" +
          getExtensionByMediaType(response.headers?.["content-type"]) ?? ""; //'employees.png';//imo_number + "_data.xlsx";
      const blob = response.data;
      let downloadLink = window.document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob]) //, { type: contentTypeHeader })
      );
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch((error) => {
      alert("Download Failed!");
    });
};

export const isFieldEmpty = (value, type) => {
  switch (type) {
    case "file":
    // return !value.fileAttachmentId;
    case "number":
      return isNaN(value);
    case "text":
    default:
      return !value?.length;
  }
};
