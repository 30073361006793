import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";
import { getOptionsArrayForSelectElement } from "../util/first";

function MachineryForm(props){
    const labels = {
        machinery_type: {
          isGroupTitle: false,
          title: "Machinery Type",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Machinery Type-",
            },
          ],
          optionsFetch: {
            api: "/machinery/type/",
            responseHandler: getOptionsArrayForSelectElement,
          } 
        },
        machinery_name: {
          isGroupTitle: false,
          title: "Machinery Name",
          type: "text",
          value: "",
        },
        machinery_description: {
          isGroupTitle: false,
          title: "Machinery Description",
          type: "text",
          // readOnly: true,
        },
      };
  
      const getFieldsMap = () => {
        // front:back
        return {
          machinery_type: "machinery_type_id",
          machinery_name: "name",
          machinery_description: "description",
        };
      };
  
    return (
        <AnCommonFormDialog
            {...props}
            header="Machinery"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/machinery/"
            valueChangeInterceptor={(key, value) => {
                if(key === "machinery_type")
                    return value.toUpperCase();
                return value;
            }}
        />
    );
}

export default MachineryForm;