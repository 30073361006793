import React from "react";
import { EachItem } from "../../../components/hoc/EachItem";
import { Link } from "react-router-dom";

function HeaderNavItems({ menuItems, activePage }){
    return <EachItem of={menuItems} render={
      (option, index) => 
        <Link
          className={
            "nav-item nav-link btn" +
            (activePage===option ? "active" : "")
          }
          to={"/index?page=" + option}
          key={"nav_options_" + option}
        >
          {option.replace("_", "").toUpperCase()}
        </Link>
    } />;
  }

  
export default HeaderNavItems;
