import axios from "axios";
import { getAuthHeader } from "../../core/application/connection";
import { OffhireAPIRoutes, linkAllMachinery, linkAllOptionPorts } from "../application/apiSources";


export const BudgetService = {
    getAll: (year) => axios.get(`${OffhireAPIRoutes.BUDGET}all/${year}`, {headers: getAuthHeader(),}),
}


export const EventTypeService = {
    get: () => axios.get(OffhireAPIRoutes.EVENT_TYPE, {headers: getAuthHeader()}),
    delete: (id) => axios.delete(`${OffhireAPIRoutes.EVENT_TYPE}${id}`, {headers: getAuthHeader()}),
} 

export const EventCategoryService = {
    get: () =>  axios.get(OffhireAPIRoutes.EVENT_CATEGORY, {headers: getAuthHeader()}),
    delete: (id) =>  axios.delete(`${OffhireAPIRoutes.EVENT_CATEGORY}${id}`, {headers: getAuthHeader()}),
} 


export const OffhireEventDaysService = {
    /**
     * Fetch Offhire Report Event Days
     * @returns
     */
    getBoth: (year, fleetCode) => {
      return axios.get(`${OffhireAPIRoutes.EVENT_DAYS}both/${year}/${fleetCode}`, {
        headers: getAuthHeader(),
      });
    },  
    /**
     * Fetch Offhire Report Event Days
     * @returns
     */
    getPlanned: (year, fleetCode) => {
      return axios.get(`${OffhireAPIRoutes.EVENT_DAYS}planned/${year}/${fleetCode}`, {
        headers: getAuthHeader(),
      });
    }
  }



export const OffhireDashboardService = {
    /**
     * Fetch reports by filters
     * @param {*} filterObject 
     * @returns 
     */
    async getEventDays(filterObject) {
      // return await axios.get(this.route, {headers: getAuthHeader()});
      return await axios.post(`${OffhireAPIRoutes.DASHBOARD}filtered`, filterObject, {
        headers: getAuthHeader(),
      });
    },  
    /**
     * Fetch last selected filtered values
     * @returns 
     */
    get: () => {
      return axios.get(`${OffhireAPIRoutes.DASHBOARD}by-vessel/filtered`, {
        headers: getAuthHeader(),
      });
    },
    approveMultiple: (reportsIdForApproval) => {
      const data = {
        ids: reportsIdForApproval,
      };
      return axios.post(`${OffhireAPIRoutes.DASHBOARD}approval/multi`, data, {
        headers: getAuthHeader(),
      });
    },
    /**
     * Fetches all(only filled with technical data) Offhire Dashboard Commercials without memorising the filters
     * @param {*} filterObject 
     * @returns 
     */
    getFilteredNonMemo: (filterObject) => {
      return axios.post(`${OffhireAPIRoutes.DASHBOARD}filtered/not-mem`, filterObject, {
        headers: getAuthHeader(),
      });
    }
  
  }

  

export const ReportGroupService = {
    group: (firstTechnicalReportId, secondTechnicalReportId) => axios.post(`${OffhireAPIRoutes.REPORT_GROUP}add/`, {
        first_technical_report_id: firstTechnicalReportId,
        second_technical_report_id: secondTechnicalReportId
      }, {
        headers: getAuthHeader(),
      }),
    unGroup: (technicalReportId) => axios.delete(`${OffhireAPIRoutes.REPORT_GROUP}remove/${technicalReportId??''}/`, {
        headers: getAuthHeader(),
      }),
  }


export const SeaService = {
    get: () => axios.get(OffhireAPIRoutes.SEA, {headers: getAuthHeader(),}),    
} 

export const MachineryService = {
  getAll: () => axios.get(linkAllMachinery, {headers: getAuthHeader(),}),
}

export const PortService = {
  getAll: () => axios.get(linkAllOptionPorts, {headers: getAuthHeader(),}),
}
