import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getSubmitButton } from "../util/first";
import AnHPForm from "../../core/components/forms/factory/AnHPForm";
import AnInlineFormGroup from "../../core/components/forms/factory/AnInlineFormGroup";
import AnSpinner from "../../core/components/other/AnSpinner";
import { getAuthHeader } from "../../core/application/connection";
import AnFormGroup from "../../core/components/forms/factory/AnFormGroup";
import Row from "../../core/components/other/Row";
import AnGroupElements from "../../core/components/factory/AnGroupElements";
import { EventDaysService } from "../services/offhire-services";

export function withRouter(Children) {
  return (props) => {
    return <Children {...props} offhire_event_days={useLocation().state} />;
  };
}

class OffhireEventDaysEditForm extends React.Component {
  state = {
    isLoggedIn: true,
    isLoaded: false,
    isFound: false,
    onProgress: false,
    error: null,
    borderColor: "#ced4da",
    feilds: {},
    feildsOptions: {},

    // report: null,
  };

  labels = {
    jan: {
      isGroupTitle: false,
      title: "January",
      type: "number",
      placeholder: "Enter Days in January",
      value: "",
      required: false,
    },
    feb: {
      isGroupTitle: false,
      title: "February",
      type: "number",
      value: "",
      required: false,
    },
    mar: {
      isGroupTitle: false,
      title: "March",
      type: "number",
      value: "",
      required: false,
    },

    apr: {
      isGroupTitle: false,
      title: "April",
      type: "number",
      value: "",
      required: false,
    },
    may: {
      isGroupTitle: false,
      title: "May",
      type: "number",
      value: "",
      required: false,
    },
    jun: {
      isGroupTitle: false,
      title: "June",
      type: "number",
      value: "",
      required: false,
    },
    jul: {
      isGroupTitle: false,
      title: "July",
      type: "number",
      value: "",
      required: false,
    },
    aug: {
      isGroupTitle: false,
      title: "August",
      type: "number",
      value: "",
      required: false,
    },
    sep: {
      isGroupTitle: false,
      title: "September",
      type: "number",
      value: "",
      required: false,
    },
    oct: {
      isGroupTitle: false,
      title: "October",
      type: "number",
      value: "",
      required: false,
    },
    nov: {
      isGroupTitle: false,
      title: "November",
      type: "number",
      value: "",
      required: false,
    },
    dec: {
      isGroupTitle: false,
      title: "December",
      type: "number",
      value: "",
      required: false,
    },
    jan_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },
    feb_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    mar_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },
    apr_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    may_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    jun_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    jul_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    aug_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    sep_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    oct_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    nov_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    dec_year: {
      isGroupTitle: false,
      title: "Year",
      type: "number",
      value: "",
      required: false,
    },

    total_days: {
      isGroupTitle: false,
      title: "Total Days",
      type: "number",
      value: "0",
      readOnly: true,
    },
    difference: {
      isGroupTitle: false,
      title: "Difference",
      type: "number",
      value: "0",
      readOnly: true,
      required: false,
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getFieldsMap = () => {
    // front:back
    return {
      total_days: "days",
      difference: "difference",
      jan: "jan",
      jan_year: "jan_year",
      feb: "feb",
      feb_year: "feb_year",
      mar: "mar",
      mar_year: "mar_year",
      apr: "apr",
      apr_year: "apr_year",
      may: "may",
      may_year: "may_year",
      jun: "jun",
      jun_year: "jun_year",
      jul: "jul",
      jul_year: "jul_year",
      aug: "aug",
      aug_year: "aug_year",
      sep: "sep",
      sep_year: "sep_year",
      oct: "oct",
      oct_year: "oct_year",
      nov: "nov",
      nov_year: "nov_year",
      dec: "dec",
      dec_year: "dec_year",
      technical_report_id: "technical_report_id",
    };
  };

  updateAllFeildsValue = (responseFeildsObject) => {
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      if (value !== undefined) {
        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({ isLoaded: true, isFound: true, feilds });
  };

  getPostRequestObject = () => {
    // debugger;
    const feildsMap = this.getFieldsMap();
    const formFeildsState = this.state.feilds;
    const monthList = this.getMonthList();
    let postRequestObject = {};
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = formFeildsState[key];
      if (!this.validateValues(key, value)) {
        return null;
      }

      if (monthList.includes(key) && !!formFeildsState[key]) {
        const monthYear = formFeildsState[key + "_year"];
        // Empty month year OR Wrong value is not allowed
        if (!monthYear || (!!monthYear && monthYear.toString().length !== 4)) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Please provide " + feildName + " month Year.");
          return null;
        }
      }

      const is_required = this.labels[key]?.required === false ? false : true;
      if (value !== undefined) {
        if ((value?.length === 0 || !value) && is_required) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Error:" + feildName + " value is empty");
          return null;
        }
        postRequestObject[feildKey] = value;
      }
    }
    return postRequestObject;
  };

  responceError = (error) => {
    // alert("Error: " + error.response.status);
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.setState({
        onProgress: false,
        isLoaded: true,
        isFound: false,
        error: "No data found!",
      });
    } else {
      this.setState({
        onProgress: false,
        isLoaded: true,
        isFound: false,
        error: "Error: " + error.response.statusText,
      });
    }
  };

  async componentDidMount() {
    // debugger;
    //MONTH, VESSEL, PLANNED_EVENT
    let { offhire_event_days } = this.props;
    if (offhire_event_days) {
      // debugger;
      this.updateAllFeildsValue(offhire_event_days);
    } else {
      const { technical_report_id } = this.props;
      // Fetch Events
      EventDaysService.get(technical_report_id)
        .then(
          (response) => {
            if (response.status === 200) {
              offhire_event_days = response.data;
              this.updateAllFeildsValue(offhire_event_days);
            }
          },
          (error) => {
            this.responceError(error);
          }
        );
    }
  }

  getMonthList = () => {
    return [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
  };

  validateValues = (key, value) => {
    let isValid = true;
    if (!!value) {
      if (
        (["jan", "mar", "may", "jul", "aug", "oct", "dec"].includes(key) &&
          (value < 0 || value > 31)) ||
        (["feb"].includes(key) && (value < 0 || value > 29)) ||
        (["apr", "jun", "jul", "sep", "nov"].includes(key) &&
          (value < 0 || value > 30))
      ) {
        isValid = false;
      }
      if (!isValid) {
        alert("Please provide valid days in " + key + " month.");
        return isValid;
      }
    }
    return isValid;
  };

  handleValueChange = (e) => {
    let { feilds, borderColor } = this.state;
    let key = e.target.name;
    let value = e.target.value.toString();
    feilds[key] = value;
    // debugger;
    this.validateValues(key, value);
    // Total days limitation
    let days_sum = 0.0;
    const monthList = this.getMonthList();
    monthList.forEach((month) => {
      const daysInMonth = !feilds[month] ? 0 : parseFloat(feilds[month]);
      days_sum += daysInMonth;
    });
    feilds["difference"] = (
      parseFloat(feilds["total_days"]) - days_sum
    ).toFixed(2);
    if (days_sum > feilds.total_days) {
      //   backgroundColor = e.target.style.borderColor;
      e.target.style.borderColor = "red";
      //   alert("Total days cannot be greater than available days");
      //   return;
    } else {
      e.target.style.borderColor = borderColor;
    }

    this.setState({ feilds });
  };

  handleFormSubmit = (event) => {
    // debugger;
    // prevent default form submit
    event.preventDefault();
    // Mark the state as submitted to show the spinner
    this.setState({ onProgress: true });
    // Create object from form data
    const postRequestObject = this.getPostRequestObject();
    // Empty form validation
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }
    // console.log("postRequestObject");
    // console.log(postRequestObject);
    // return;
    EventDaysService.update(postRequestObject)
      .then(
        (response) => {
          // console.log("Post Response");
          // console.log(response);
          // console.log(this.props);
          if (response.data) {
            this.updateAllFeildsValue(response.data);
            this.setState({ onProgress: false });
          }
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            alert("Successfully Submitted");
          }
        },
        (error) => {
          const errorMessage = `Error: ${error.response.statusText}`;
          alert(errorMessage);
          if (error.response.status === 401) {
            this.setState({ isLoggedIn: false });
          }
          this.setState({
            onProgress: false,
            error: errorMessage,
          });
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        }
      );
  };

  render() {
    const { feilds, feildsOptions, isLoaded, isLoggedIn, isFound } = this.state;
    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    if (!isLoaded) return <AnSpinner />;
    if (!isFound) return <>Record Not Found!</>;

    let formFeildsArr = [];
    for (let key in this.labels) {
      const selectOptions = this.labels[key].options ?? [
        { value: "", title: "--Select--" },
      ];

      formFeildsArr.push({
        ...this.labels[key],
        name: key,
        value: feilds[key],
        onChangeHandler: this.handleValueChange,
        options: [...selectOptions, ...(feildsOptions[key] ?? [])],
      });
    }

    return (
      <React.Fragment>
        <AnHPForm
          title="Offhire Event Days"
          onBack={this.props.onBack}
          onSubmit={this.handleFormSubmit}
        >
          <AnGroupElements
            componentsPerGroup={12}
            items={formFeildsArr}
            condition={(item) => true}
            componentWrapper={(item, index) => (
              <AnFormGroup attribute={item} key={index} isCustom={true} />
              // <AnInlineFormGroup attribute={item} key={index} />
            )}
            rowClassName=""
          />
          <Row>
            {getSubmitButton(
              "Update",
              false,
              this.state.onProgress,
              this.state.error
            )}
          </Row>
        </AnHPForm>
      </React.Fragment>
    );
  }
}

export default withRouter(OffhireEventDaysEditForm);
