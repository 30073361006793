import React, { Component } from "react";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import { formatDatetime } from "../../core/utility/conversion";

function OffhireEventDaysScreen() {
    return (
      <CommonListViewScreen
        path="/reports/event_days/"
        dataPreprocessor={{
          mainTitle: "Offhire Event Days",
          path: "/offhire/event_days",
          columns: [
            { header: "ID", field: "id" },
            { header: "Technical Report ID", field: "technical_report_id" },
            { header: "Delay Started At", body: (rowData) => formatDatetime(rowData?.technical_report?.delay_start_at_gmt)?? "" },
            { header: "Delay End At", body: (rowData) => formatDatetime(rowData?.technical_report?.delay_end_at_gmt)?? "" },
            { header: "Total Days", body: (rowData) => rowData.days?.toFixed(2)},
            { header: "Planned / Unplanned", body: (rowData) => rowData?.technical_report?.is_planned === true ? "Planned" : "Unplanned"},
            { header: "Type", field: "technical_report.event_type.name" },
            { header: "Machinery", field: "technical_report.machinery.name"},
            { header: "Machinery Sub Type", field: "technical_report.machinery.machinery_type.name" },
            // { header: "Details", field: "details" },
            // { header: "Jan", field: "jan" },
            // { header: "Year", field: "jan_year" },
            // { header: "Feb", field: "feb" },
            // { header: "Year", field: "feb_year" },
            // { header: "Mar", field: "mar" },
            // { header: "Year", field: "mar_year" },
            // { header: "Apr", field: "apr" },
            // { header: "Year", field: "apr_year" },
            // { header: "May", field: "may" },
            // { header: "Year", field: "may_year" },
            // { header: "Jun", field: "jun" },
            // { header: "Year", field: "jun_year" },
            // { header: "Jul", field: "jul" },
            // { header: "Year", field: "jul_year" },
            // { header: "Aug", field: "aug" },
            // { header: "Year", field: "aug_year" },
            // { header: "Sep", field: "sep" },
            // { header: "Year", field: "sep_year" },
            // { header: "Oct", field: "oct" },
            // { header: "Year", field: "oct_year" },
            // { header: "Nov", field: "nov" },
            // { header: "Year", field: "nov_year" },
            // { header: "Dec", field: "dec" },
            // { header: "Year", field: "dec_year" },
          ],
        }}
        canEdit={true}
      />
    );
  
}

export default OffhireEventDaysScreen;
