import AnCommonFormDialog, { InvalidForm } from "../../core/components/forms/AnCommonFormDialog";
import EmployeeFetchService from "../../core/services/EmployeeFetchService";
import RoleFetchService from "../../core/services/RoleFetchService";
import { getOptionsArrayForSelectElement } from "../../offhire/util/first";


function UserForm(props){

    const labels = {
        username: {
            isGroupTitle: false,
            title: "Username",
            type: "text",
          },
          password: {
            isGroupTitle: false,
            title: "Password",
            type: "password",
          },
          confirm_password: {
            isGroupTitle: false,
            type: "password",
            title: "Confirm Password",
          },
          employee: {
            isGroupTitle: false,
            title: "Employee",
            type: "select",
            options: [
              {
                value: "",
                title: "-Select Employee-",
              },
            ],
            optionsFetch : {
                fetcher: EmployeeFetchService.getAll,
            }
          },
          role: {
            isGroupTitle: false,
            title: "Role",
            type: "select",
            options: [
              {
                value: "",
                title: "-Select Role-",
              },
            ],
            optionsFetch : {
                fetcher: RoleFetchService.getAll,
            }
          },   
    };

    const getFieldsMap = () => {
        // front:back
        return {
            username: "username",
            password: "password",
            confirm_password: "confirm_password",
            employee: "employee_id",
            role: "role_id",       
        };
    }

    return (
        <AnCommonFormDialog
            {...props}
            header="Add User"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/users/"
            formValidator={(fields) => {
                if (fields.password !== fields.confirm_password) 
                    return new InvalidForm("Password and Confirm Password does not match");

                if(fields.password.length < 8)
                    return new InvalidForm("Password must be at least 8 characters long");
                return false;
                }
            }
        />
    )
}

export default UserForm;