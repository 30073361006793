import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function EmployeeForm(props){

    const labels = {
    
    };

    const getFieldsMap = () => {
        // front:back
        return {
        };
    }

    return (
        <AnCommonFormDialog
            {...props}
            header="Add Employee"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/employee/"
        />
    )
}

export default EmployeeForm;