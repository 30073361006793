import React from "react";
import { Link } from "react-router-dom";
import AnSideBar from "../../../components/other/AnSideBar";
import AnSideBarOptions from "../../../components/other/AnSideBarOptions";
import OffhireAdminSidebar from "../../../../offhire/components/OffhireAdminSidebar";


function HeaderUserOptions({ user, onSideBarToggle }) {
    // const { fetchUser } = this.props; //fetchUser is a function
    // const { isLoggedIn, user } = this.state; //fetchUser();
    // const user = getUser();
    const isLoggedIn = user ? true : false;
    const { username, employee, role } = user ? user : {};
  
    if (!isLoggedIn)
      return (
        <Link
          className="nav-item nav-link btn btn-outline-light ml-md-2"
          to="/ulogin"
        >
          My Account
        </Link>
      );
  
    // AnSideBar is outer component (the sidebar)
    // OffhireAdminSidebar is inner component with the information of the links
    // AnSideBarOptions is the component (the option elements)
    return (
      <div className="nav-item dropdown open" style={{ paddingLeft: 0 }}>
        {username === "admin" && (
          <AnSideBar onToggle={onSideBarToggle}>
            <OffhireAdminSidebar>
              <AnSideBarOptions />
            </OffhireAdminSidebar>
          </AnSideBar>
        )}
        <button
          className="nav-item nav-link btn  dropdown-toggle"
          // to="javascript:;"
          id="navbarDropdown"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {/* <img
            src="<?= $dp_path ?>"
            className="pb-1 pr-1"
            alt=""
            style={{ widht: 20, height: 20 }}
          /> */}
          <i className="fa fa-user pr-1"></i>
          {username}
        </button>
  
        <div
          className="dropdown-menu dropdown-usermenu pull-right"
          aria-labelledby="navbarDropdown"
        >
          {/* {username === "admin" && (
            // <React.Fragment>
            // <Link className="dropdown-item" to="/user/new">
            //   <i className="fa fa-users pull-right"></i> Create New User
            // </Link>
            //   <Link className="dropdown-item" to="/reports/sync">
            //     <i className="fa fa-file pull-right"></i> Sync Reports
            //   </Link>
            // </React.Fragment>
            // <AnSideBar>
            //   <OffhireAdminSidebar />
            // </AnSideBar>
          )} */}
          <div className="ml-1 dopdown_driver">Welcome, {employee?.name}</div>
          <div className="dropdown-divider"></div>
          <Link className="dropdown-item" to="/main">
            <i className="fa fa-home pull-right"></i>Home
          </Link>
          <Link className="dropdown-item" to="/profile">
            <i className="fa fa-user pull-right"></i> Profile
          </Link>
          {/* <Link className="dropdown-item" to="javascript:;">
            <span className="badge bg-red pull-right">50%</span>
            <span>Settings</span>
          </Link> */}
          <Link className="dropdown-item" to="/ulogout">
            <i className="fa fa-sign-out pull-right"></i> Log Out
          </Link>
        </div>
      </div>
    );
  }

export default HeaderUserOptions;

  