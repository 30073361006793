import React, { Component } from "react";
import AnLabelValue from "../../core/components/factory/AnLabelValue";
import Col4 from "../../core/components/other/Col4";
import Row from "../../core/components/other/Row";
import { getDaysBetweenDates } from "../../core/utility/conversion";
import { useLocation } from "react-router-dom";
import { getOffhireDaysBetweenDates } from "../util/first";
import Col3 from "../../core/components/other/Col3";
import { offhireAttachmentDownloadHandler } from "../services/OffhireReportService";

export function withRouter(Children) {
  return (props) => {
    return <Children {...props} report={useLocation().state} />;
  };
}

class OffhireReportView extends Component {
  state = {};

  labels = {
    offhire_report_commercial_section: {
      isGroupTitle: true,

      title: "Offhire Report (Commercial)",
    },
    vessel_name: {
      title: "Vessel Name",
    },
    name: {
      title: "Name",
    },
    vessel_code: {
      title: "Vessel Code",
    },
    voyage_number: {
      title: "Voyage Number",
    },
    voyage_status: {
      title: "Voyage Status",
    },
    commenced_at: {
      title: "Voayge Commenced At",
      type: "datetime-local",
    },
    completed_at: {
      title: "Voayge Completed At",
      type: "datetime-local",
    },
    activity: {
      title: "Activity",
    },
    delay_start_at_gmt: {
      title: "Delay Start At (GMT)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_start_at_local: {
      title: "Delay Start At (Local)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_end_at_gmt: {
      title: "Delay End At (GMT)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    delay_end_at_local: {
      title: "Delay End At (Local)",
      type: "datetime-local",
      value: "2022-02-23T23:25:30.900Z",
    },
    reason: {
      title: "Reason",
    },
    tci: {
      title: "TCI %",
    },
    tco: {
      title: "TCO %",
    },

    delay_year: {
      title: "Delay Year",
    },

    hours: {
      title: "Delay Duration (in Hours)",
    },
    custom_offhire_days: {
      title: "Delay Duration (in Days)",
    },
    offhire_or_stoppage: {
      title: "Offhire/Stoppage",

      // type: "select",
      options: [
        {
          value: true,
          title: "Offhire",
        },
        {
          value: false,
          title: "Stoppage",
        },
      ],
    },
    // custom_offhire_days_group: {
    //
    //
    //
    //   title: "Offhire Days Group",
    //
    //
    // },
    offhire_report_technical_section: {
      isGroupTitle: true,

      title: "Offhire Report (Technical)",
    },
    offhire_report_technical_section_p1: {
      isGroupTitle: true,

      title: "Group 1:",
    },

    from_datetime: {
      title: "From Date Time",
      type: "datetime-local",
    },
    to_datetime: {
      title: "To Date Time",
      type: "datetime-local",
    },
    days: {
      title: "Days",
    },
    offhire_report_technical_section_p2: {
      isGroupTitle: true,

      title: "Group 2:",
    },
    offhire_type: {
      title: "Offhire Type",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Offhire Type-",
        },
      ],
    },
    events_category: {
    
    
    
      title: "Events Category",
      type: "select",
      // type: "select",
      options: [
        {
          value: "",
          title: "Select",
        },
        // {
        //   value: true,
        //   title: "Planned",
        // },
        // {
        //   value: false,
        //   title: "Unplanned",
        // },
      ],
    },
    is_planned: {
      title: "Planned/Unplanned",
      type: "select",
      options: [
        {
          value: "",
          title: "Select",
        },
        {
          value: true,
          title: "Planned",
        },
        {
          value: false,
          title: "Unplanned",
        },
      ],
    },
    filler: {
      type: "filler",
    },
    machinery_type: {
      title: "Machinery Type",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Machinery Type-",
        },
      ],
    },
    sfi_code: {
      title: "SFI Code",
    },
    machinery_subtype: {
      title: "Machinery Subtype",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Machinery Subtype-",
        },
      ],
    },
    offhire_report_technical_section_p3: {
      isGroupTitle: true,

      title: "Group 3:",
    },
    event: {
      title: "Event",
    },
    // ...
    // vessel_age: {
    //
    //   title: "Vessel Age",
    //
    //
    //
    // },
    location: {
      title: "Location",
      type: "select",
      options: [
        {
          value: "",
          title: "-- Select Location --",
        },
        {
          value: "SEA",
          title: "Sea",
        },
        {
          value: "PORT",
          title: "Port",
        },
      ],
      //
      //
    },
    place: {
      title: "Place",
      type: "datalist-input",
      options: [
        {
          value: "",
          title: "-- Select Place --",
        },
      ],
      linkSelectByKey: "port",
      link: [
        {
          key: "sea",
          link: "sea/all",
          fetch: "once",
        },
        {
          key: "port",
          link: "port/l/",
        },
      ],
      //
    },
    attachment: {
      title: "Attachment",
      type: "file",
      fileAttachmentId: "",
      readOnly: true,
    },
    // ...

    shipmanager_section: {
      isGroupTitle: true,

      title: "Ship Manager Section",
    },
    ship_manager_comment: {
      title: "Ship Manager's Comment",
    },
    subbmited_by: {
      //   title: "Submitted By",
      title: "Submitted By",
    },
    ship_manager_submited_on: {
      title: "Created/Updated On",
      type: "datetime-local",
    },
    // fleetmanager_section: {
    //   isGroupTitle: true,

    //   title: "Fleet Manager Section",
    // },
    fleet_manager_comment: {
      title: "Fleet Manager Comment",
    },
    approved_by: {
      title: "Approved / Rejected By",
    },
    fleet_manager_submited_on: {
      title: "Approved / Rejected On",
      type: "datetime-local",
    },
    report_status: {
      title: "Report Status",
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getFieldsMap = () => {
    // front:back
    return {
      vessel_name: "vessel_name",
      name: "name",
      vessel_code: "vessel_code",
      voyage_number: "voyage_number",
      voyage_status: "voyage_status",
      commenced_at: "commenced_at",
      completed_at: "completed_at",
      activity: "activity",
      offhire_or_stoppage: "activity",
      delay_start_at_gmt: "commercial_delay_start_at_gmt",
      delay_end_at_gmt: "commercial_delay_end_at_gmt",
      reason: "commercial_reason",
      tci: "tci",
      tco: "tco",
      // id: "id",
      delay_year: "delay_year",
      delay_start_at_local: "delay_start_at_local",
      delay_end_at_local: "delay_end_at_local",
      hours: "hours",
      custom_offhire_days: "custom_offhire_days",
      custom_offhire_days_group: "custom_offhire_days_group",
      // ------------------

      id: "offhire_report_commercial_id",
      //   offhire_type: "event_type_id",
      //   machinery_subtype: "machinery_id",
      offhire_type: "event_type_name",
      machinery_subtype: "machinery_name",
      from_datetime: "delay_start_at_gmt",
      to_datetime: "delay_end_at_gmt",
      days: "duration_in_days",
      event: "event",
      // vessel_age: "vessel_age",
      location: "location",
      //   place: "place_id",
      place: "place_name",
      ship_manager_comment: "ship_manager_comment",
      //--------------------------
      //   machinery_type: "machinery_type_id",
      machinery_type: "machinery_type_name",
      sfi_code: "sfi_code",
      //   events_category: "events_category_id",
      events_category: "events_category_name",
      is_planned: "is_planned",
      attachment: "attachment_id",
      fleet_manager_comment: "fleet_manager_comment",
      approved_by: "fleet_manager_name",
      subbmited_by: "ship_manager_name",
      ship_manager_submited_on: "ship_manager_submited_on",
      fleet_manager_submited_on: "fleet_manager_submited_on",
      report_status: "report_status",
    };
  };

  preProcessValuesForFeilds = (report) => {
    return {
      ...report,
      // vessel_age: "",
      location: report?.offhire_report_technical?.location ?? "",
      sea_id: report?.offhire_report_technical?.sea_id ?? "",
      port_id: report?.offhire_report_technical?.port_id ?? "",
      place_id:
        report?.offhire_report_technical?.location?.toUpperCase() === "SEA"
          ? report?.offhire_report_technical?.sea_id ?? ""
          : report?.offhire_report_technical?.port_id ?? "",
      place_name:
        report?.offhire_report_technical?.location?.toUpperCase() === "SEA"
          ? report?.offhire_report_technical?.sea?.name ?? ""
          : report?.offhire_report_technical?.port?.name ?? "",

      hours: report?.hours.toFixed(2),
      // days: report?.custom_offhire_days.toFixed(2),
      custom_offhire_days: report?.custom_offhire_days.toFixed(2),

      machinery_id: report?.offhire_report_technical?.machinery_id,
      machinery_name: report?.offhire_report_technical?.machinery?.name,

      machinery_type_id:
        report?.offhire_report_technical?.machinery?.machinery_type_id,
      sfi_code:
        report?.offhire_report_technical?.machinery?.machinery_type?.sfi_code,
      machinery_type_name:
        report?.offhire_report_technical?.machinery?.machinery_type.name,

      event_type_id: report?.offhire_report_technical?.event_type_id,
      event_type_name: report?.offhire_report_technical?.event_type?.name,

      events_category_id:
        report?.offhire_report_technical?.events_category_id ?? "",
      events_category_name:
        report?.offhire_report_technical?.events_category
          ?.name,
      is_planned: report?.offhire_report_technical?.is_planned ?? "-",
      ship_manager_comment:
        report?.offhire_report_technical?.ship_manager_comment ?? "",
      fleet_manager_comment:
        report?.offhire_report_technical?.fleet_manager_comment ?? "",
      ship_manager_name:
        report?.offhire_report_technical?.ship_manager?.name ?? "",
      ship_manager_submited_on:
        report?.offhire_report_technical?.ship_manager_updated_report_at?.slice(
          0,
          16
        ) ?? "",
      fleet_manager_name:
        report?.offhire_report_technical?.fleet_manager?.name ?? "",
      fleet_manager_submited_on:
        report?.offhire_report_technical?.fleet_manager_updated_report_at?.slice(
          0,
          16
        ) ?? "",
      report_status: report?.offhire_report_technical?.report_status ?? "",
      offhire_report_commercial_id: report?.id,
      // resolving the denesting key conflicts
      event: report?.offhire_report_technical?.event ?? "",
      delay_start_at_gmt:
        report?.offhire_report_technical?.delay_start_at_gmt ?? "",
      delay_end_at_gmt:
        report?.offhire_report_technical?.delay_end_at_gmt ?? "",
      duration_in_days: getOffhireDaysBetweenDates(
        report?.offhire_report_technical?.delay_start_at_gmt,
        report?.offhire_report_technical?.delay_end_at_gmt,
        report?.tci
      ),
      commercial_reason: report?.reason ?? "",
      commercial_delay_start_at_gmt: report?.delay_start_at_gmt ?? "",
      commercial_delay_end_at_gmt: report?.delay_end_at_gmt ?? "",
      attachment_id: {files:null, fileAttachmentId: report?.offhire_report_technical?.attachment_id},
    };
  };

  updateAllFeildsValue = (responseFeildsObjectOrg) => {
    const responseFeildsObject = this.preProcessValuesForFeilds(
      responseFeildsObjectOrg
    );
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    let timestamp = null;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      if (value !== undefined) {
        // if (key === "last_data_entry_date") {
        //   //"2019-12-31T11:49:41.457000"
        //   timestamp = value;
        //   value = value.split("T")[0];
        // } else if (
        //   key === "import_operation_timestamp" ||
        //   key === "calculation_operation_timestamp"
        // ) {
        //   value = value.replace("T", " ").slice(0, 19);
        // }
        if (key === "is_planned") {
          if (value === true) value = "Planned";
          if (value === false) value = "Unplanned";
        }

        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({
      isAllLoaded: this.state.isAllLoaded + 1,
      feilds,
      last_sync_timestamp: timestamp,
      report_status: responseFeildsObjectOrg?.report_status,
    });
  };

  componentDidMount() {
    const { report, reportDirect } = this.props;
    // console.log("props", this.props);
    this.updateAllFeildsValue(reportDirect ?? report);
  }

  handleFileDownload = (e) => {
    const { feilds } = this.state;
    offhireAttachmentDownloadHandler(e, feilds);
  }

  render() {
    const { feilds } = this.state;

    let viewFeilds = [];
    for (let key in this.labels) {
      // Form Input Feilds
      viewFeilds.push({
        ...this.labels[key],
        name: key,
        value: feilds[key],
        handleDownload: this.handleFileDownload,
      });
    }

    return (
      <Row className="m-1">
        {/* <Col4>
          <AnLabelValue label="Report ID" value={reportObject.id} />
        </Col4>
        <Col4>
          <AnLabelValue label="Report Date" value={reportObject.report_date} />
        </Col4> */}
        {viewFeilds.map((feild, index) => {
          if (feild?.type === "datetime-local" && feild.value?.length > 15) {
            let datetimeSplit = feild.value?.slice(0, 16).split("T");
            let dateSplit = datetimeSplit?.[0].split("-").reverse();
            feild.value = `${dateSplit?.[0]}/${dateSplit?.[1]}/${dateSplit?.[2]} ${datetimeSplit?.[1]}`;
          }
          return feild?.isGroupTitle === true ? (
            <div className="col-12 text-primary" key={index + "_col_sec"}>
              <h6>{feild.title}</h6>
            </div>
          ) : (
            <Col3 key={index + "_col_sec"}>
              <AnLabelValue
                key={index + "_an_label_value"}
                field={feild}
                label={feild.title}
                value={feild.value}
                name={feild.name}
              />
            </Col3>
          );
        })}
      </Row>
    );
  }
}

export default withRouter(OffhireReportView);
