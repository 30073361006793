import React from "react";
import { Navigate } from "react-router-dom";
import AnBordered from "../components/factory/AnBordered";
import AnHPForm from "../components/forms/factory/AnHPForm";
import AnInlineFormGroup from "../components/forms/factory/AnInlineFormGroup";
import { setToken } from "../application/session";
import BlobsBackground from "../components/design/BlobsBackground";
import { fetchMachienry, fetchPorts } from "../../offhire/util/data";
import AnSpinner from "../components/other/AnSpinner";
import AnFormGroup from "../components/forms/factory/AnFormGroup";
import { LoginService } from "../../offhire/services/offhire-services";

class Login extends React.Component {
  state = {
    isLoaded: true,
    username: "",
    password: "",
    redirect: false,
    errorMsg: "",
    serverCount: 0,
  };

  isValidPassword = (value) => {
    let decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    return value.length > 7 && value.length < 21 && value.match(decimal);
  };
  handleValueChange = (e) => {
    // let { feilds } = this.state;
    // console.log("handleValueChange");
    let key = e.target.name;
    let value = e.target.value.toString();
    // feilds[key] = value;
    //Validkeyns
    // if (key === "vkeyr_mobile_number") value = value.replace(/ /g, "");
    if (key === "password") {
      // debugger;
      value = value.trim();
      if (this.isValidPassword(value)) {
        e.target.style.borderColor = "green";
      } else {
        e.target.style.borderColor = "red";
      }
    }
    this.setState({ [key]: value });
  };
  APP_URL = "/login";
  getPostRequestObject = () => {
    const { username, password } = this.state;
    // return {
    //   grant_type: "",
    //   username: username,
    //   password: password,
    //   scope: "",
    //   client_id: "",
    //   client_secret: "",
    // };
    if (!this.isValidPassword(password)) {
      this.setState({ errorMsg: "Password does not meet password policy." });
      setTimeout(() => {
        this.setState({ errorMsg: "" });
      }, 3000);
      return null;
    }
    return `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`;
  };

  handleUserLogin = async (e) => {
    //   let history = useHistory();
    e.preventDefault();
    // let { username, password } = this.state;
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: JSON.stringify(
    //     `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`
    //   ),
    // };
    // //const response = await fetch("/login", requestOptions);
    // const response = await fetch("http://localhost:8000/login", requestOptions);
    // const data = await response.json();

    // if (!!data?.access_token) {
    //   localStorage.setItem("hpttoken", data.access_token);
    //   // localStorage.setItem("hptuser", JSON.stringify({ username: username }));
    //   const handleLogin = this.props.onLogin;
    //   handleLogin({ username: username });
    //   this.setState({ redirect: true });
    //   //redirect to home
    //   //   this.props.history.push("/");
    //   //   useNavigate("/");
    // }
    // console.log(data);

    const postRequestObject = this.getPostRequestObject();
    if (!postRequestObject) return;
    // console.log("postRequestObject");
    // console.log(postRequestObject);
    this.setState({
      isLoaded: false,
    });

    LoginService.login(postRequestObject)
      .then(
        (res) => {
          // console.log(res);
          const { data } = res;
          if (data?.access_token) {
            // localStorage.setItem("hpttoken", res.access_token);
            // this.props.history.push("/");
            setToken(data.access_token);
            // load data
            fetchMachienry();
            fetchPorts();
            // localStorage.setItem("hpttoken", data.access_token);
            // localStorage.setItem("offhire-username", this.state.username);
            // // localStorage.setItem("hptuser", JSON.stringify({ username: username }));
            // const handleLogin = this.props.onLogin;
            // handleLogin({ username: this.state.username });
            this.props?.setIsLoggedIn(true);
            this.setState({ redirect: true, isLoaded: true });
          } else {
            this.setState({ errorMsg: "Login Failed, Error", isLoaded: true });
          }
        },
        (err) => {
          console.log(err.responce);
          if (err.response?.status >= 400 || err.response?.status <= 406) {
            this.setState({
              errorMsg: "Login Failed, Recheck Credentials",
              isLoaded: true,
            });
          } else if (err.responce?.status >= 500) {
            if (this.state.serverCount < 5) {
              setTimeout(this.handleUserLogin, 1000);
            }
            this.setState({
              errorMsg: "Connection Dropped, Try Again.",
              serverCount: this.state.serverCount + 1,
              isLoaded: true,
            });
          } else {
            this.setState({ errorMsg: "Error, Try Again", isLoaded: true });
          }
          setTimeout(() => {
            this.setState({ errorMsg: "" });
          }, 3000);
        }
      );
  };

  render() {
    const { redirect, errorMsg, isLoaded } = this.state;
    if (!isLoaded) {
      return (
        <div>
          <AnSpinner />
        </div>
      );
    }
    if (redirect) {
      return <Navigate replace to="/main" />;
    }
    return (
      <div
        className="row justify-content-center  align-items-center"
        style={{ height: "80vh" }}
      >
        <BlobsBackground />
        <AnBordered
          title="User Login"
          col="3"
          displaySizeMargin={0}
          hideOnPrint={true}
          // isMinimised={true}
          // minimiseIconName="cog"
          // holdRow={true}
          canMinimise={false}
          // aling="center"
          shadow={true}
        >
          <AnHPForm
            // title={"User Login"}
            onBack={this.props.onBack}
            onSubmit={this.handleUserLogin}
          >
          {/* <AnInlineFormGroup */}
          <AnFormGroup 
            attribute={{
                name: "username",
                title: "Username",
                type: "text",
                isGroupTitle: false,
                value: this.state.username,
                onChangeHandler: this.handleValueChange,
                col: 12
              }}
              // object={this}
            />
          {/* <AnInlineFormGroup */}
          <AnFormGroup 
              attribute={{
                name: "password",
                title: "Password",
                type: "password",
                isGroupTitle: false,
                value: this.state.password,
                onChangeHandler: this.handleValueChange,
                col: 12
              }}
              // object={this}
            />
            
            <div className="form-group col-12 py-3 justify-content-center">
              <div className="">
                <input
                  type="submit"
                  className="form-control btn btn-sm btn-outline-info"
                  name="submit"
                  id="submit"
                  // onChange={this.handleValueChange}
                  value="Login"
                />
              </div>
            </div>
          </AnHPForm>
          {errorMsg?.length > 0 && (
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              </div>
            </div>
          )}
        </AnBordered>
      </div>
    );
  }
}

export default Login;
