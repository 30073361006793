import { confirmDialog } from "primereact/confirmdialog";
import React from "react";
import { Button } from "primereact/button";

class AnRemoveButton extends React.Component {
  confirmDelete = () => {
    const { onConfirm } = this.props;
    confirmDialog({
      // target: event.currentTarget,
      message: "Do you want to delete this record?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: onConfirm,
      reject: () => {},
    });
  };

  render() {
    return (
      <Button
        icon="pi pi-times-circle"
        className="p-button-danger p-button-text p-button-rounded"
        aria-label="Remove"
        tooltip="Remove"
        onClick={this.confirmDelete}
      />
    );
  }
}

export default AnRemoveButton;
