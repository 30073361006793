import React, { Component } from "react";
import AnSpinner from "../../core/components/other/AnSpinner";
import CommonListViewScreen from "../../core/pages/CommonListViewScreen";
import { formatDatetime } from "../../core/utility/conversion";

class OffhireBudgetTableWithTimelineConflicts extends Component {
  state = {
    year: new Date().getFullYear(),
  };
  
  render() {
    const { budgetedEvents } = this.props;
    const dataObjectList = budgetedEvents;
    const isSimpleTableView = true;
    if (!dataObjectList) return <AnSpinner />;
    return (
      <CommonListViewScreen
        path={dataObjectList}
        dataPreprocessor={{
          mainTitle: `Budgeted Events with overlapping timeline`,
          // titles: [
          //   "id",
          //   "name",
          //   "vessel imo",
          //   // "operation cost",
          //   "start date",
          //   "start time",
          //   "end date",
          //   "end time",
          //   "days",
          //   // "event type",
          //   // "event category",
          // ],
          path: "/budget",
          // rowProcessor: (dataObject) => ([
          //   dataObject.id,
          //   dataObject.event_name,
          //   dataObject.vessel_imo,
          //   // dataObject.operation_cost,
          //   dataObject.start_date.replace("T", " ").slice(0, 10),
          //   dataObject.start_date.replace("T", " ").slice(11, 16),
          //   dataObject.end_date.replace("T", " ").slice(0, 10),
          //   dataObject.end_date.replace("T", " ").slice(11, 16),
          //   dataObject.duration_in_days?.toFixed(5),
          //   // dataObject.planned_event_type?.name,
          //   // dataObject.planned_event_type?.offhire_events_category?.name,
          // ]),
          columns: [
            { title: "Id", field: "id" },
            { title: "Name", field: "event_name" },
            { title: "Vessel IMO", field: "vessel_imo" },
            // { title: "Operation Cost", field: "operation_cost" },
            { title: "Starts At", body: (row) => formatDatetime(row.start_date) },
            { title: "Ends At", body: (row) => formatDatetime(row.end_date) },
            { title: "Days", body: (row) => row.duration_in_days?.toFixed(5) },
            // { title: "Event Type", field: "planned_event_type.name" },
            // { title: "Event Category", field: "planned_event_type.offhire_events_category.name" },
          ]
        }}
        isSimpleTableView={isSimpleTableView}
      />
    );
  }
}

export default OffhireBudgetTableWithTimelineConflicts;
