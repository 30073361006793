import React, { Component } from "react";
import AnBordered from "../../core/components/factory/AnBordered";
import { BreadCrumb } from "primereact/breadcrumb";
import { Outlet, useLocation } from "react-router-dom";

function OffhireReportFormScreen(props) {
    const location = useLocation();
    const routeEnds = location.pathname.split("/").pop();
    const formPurposeScreenMap = {
      check: "Offhire Report Approval",
      update: "Offhire Report Update",
      view: "Offhire Report View",
      addon: "Offhire Report Addon",
    };
    const items = [
      { label: "Offhire Reports", url: "/offhire/reports" },
      { label: formPurposeScreenMap[routeEnds] || "Offhire Report Create" },
    ];

    return (
      <React.Fragment>
        <div
          className="row justify-content-center mt-3"
          //   style={{ height: "80vh" }}
        >
          {/* {formPurpose} */}
          <BreadCrumb model={items} home={{ icon: "pi pi-home" }} style={{background: 'none'}} />
          <AnBordered
            title="Offhire Report"
            col="10"
            displaySizeMargin={0}
            hideOnPrint={true}
            // isMinimised={true}
            // minimiseIconName="cog"
            // holdRow={true}
            canMinimise={false}
            // aling="center"
            shadow={true}
          >
            {<Outlet />}
          </AnBordered>
        </div>
      </React.Fragment>
    );
  
}

export default OffhireReportFormScreen;
