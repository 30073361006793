export const errorHandler = (
  error,
  setState,
  toastRef,
  callBack,
  autoClose = true
) => {
  const errorMessage =
    error.response?.data?.detail ??
    `Error: ${error.response?.statusText ?? error.message}`;

  if (error?.response?.status === 401) {
    this.setState({ isLoggedIn: false });
  }
  

  toastRef?.show({
    severity: "error",
    summary: "Error",
    detail: errorMessage,
  });

  // isLoaded refers to the required record being loaded
  setState({
    isLoaded: true, // error?.response?.status === 404,
    onProgress: false,
    error: errorMessage,
  });

  autoClose &&
    setTimeout(() => {
      setState({ error: null });
    }, 3000);

  callBack && callBack();
};
