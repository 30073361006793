import React, { Component } from "react";

function IconButton({ iconName, onClick }) {
    return (
      <span className="ml-3" onClick={onClick}>
        <i className={`fa fa-${iconName}`}></i>
      </span>
    );
  }

export default IconButton;