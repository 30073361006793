import { storage } from "./storage";
import { PROJECT_CODENAME } from "./application";

export const getToken = () => {
  return storage.get(PROJECT_CODENAME + "_token");
};

export const setToken = (token) => {
  storage.set(PROJECT_CODENAME + "_token", token);
};

export const getUser = () => {
  return storage.get(PROJECT_CODENAME + "_user");
};

export const setUser = (user) => {
  storage.set(PROJECT_CODENAME + "_user", user);
};

export const clearSession = () => {
  storage.remove(PROJECT_CODENAME + "_token");
  storage.remove(PROJECT_CODENAME + "_user");
};

export const app = "a";
