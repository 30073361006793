import React from "react";
import { Navigate } from "react-router-dom";
import AnHPForm from "../../core/components/forms/factory/AnHPForm";
import AnInlineFormGroup from "../../core/components/forms/factory/AnInlineFormGroup";
import AnSpinner from "../../core/components/other/AnSpinner";
import OffhireBudgetTableWithTimelineConflicts from "../components/OffhireBudgetTableWithTimelineConflits";
import { EventTypeService, PlannedEventService, VesselService } from "../services/offhire-services";

class AddBudgetItemForm extends React.Component {
  state = {
    isLoggedIn: true,
    onProgress: false,
    error: null,
    feilds: {},
    feildsOptions: {},

    isEventTypesLoaded: false,
    eventTypes: [],

    isVesselsLoaded: false,
    vessels: [],

    isAllLoaded: 0,
    isStateChanged: false,
  };

  APP_URL = "/events/item/planned";

  labels = {
    planned_event: {
      isGroupTitle: false,
      title: "Planned Event",
      type: "select",
      options: [
        {
          value: "",
          title: "-Select Event-",
        },
      ],
    },
    event_name: {
      isGroupTitle: false,
      title: "Event Name",
      type: "text",
      value: "",
    },

    vessel: {
      isGroupTitle: false,
      title: "Vessel",
      type: "select",
      options: [
        {
          value: 0,
          title: "-Select Vessel-",
        },
      ],
      // readOnly: true,
    },
    // event_year_month: {
    //   isGroupTitle: false,
    //   title: "Month",
    //   type: "month",
    //   min: new Date().toISOString().slice(0, 7),
    // },
    // days: {
    //   isGroupTitle: false,
    //   title: "Days",
    //   type: "text",
    // },
    event_start_date: {
      isGroupTitle: false,
      title: "Event Start Date",
      type: "datetime-local",
    },
    event_end_date: {
      isGroupTitle: false,
      title: "Event End Date",
      type: "datetime-local",
    },
    days: {
      isGroupTitle: false,
      title: "Days",
      type: "text",
      readOnly: true,
      required: false,
    },
    cost: {
      isGroupTitle: false,
      title: "Cost",
      type: "number",
    },
  };

  constructor(props) {
    super(props);
    this.state["feilds"] = {};
    for (let key in this.labels) {
      let { value, isGroupTitle } = this.labels[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

  getTommorowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().slice(0, 10);
  }

  // NIU
  extractEventsCategoriesFromEventTypes = (eventTypes) => {
    let eventsCategories = {};
    eventTypes.forEach((eventType) => {
      eventsCategories[eventType.offhire_events_category.id] =
        eventType.offhire_events_category;
    });

    // Convert to array
    let eventsCategoriesArray = [];
    for (let key in eventsCategories) {
      eventsCategoriesArray.push(eventsCategories[key]);
    }
    return eventsCategoriesArray;
  };

  getOptionsArrayForSelectElement = (
    optionsInJSON,
    property_value = "id",
    property_name = "name"
  ) => {
    // debugger;
    // console.log("optionsInJSON");
    let optionsArray = [];
    for (let aOption of optionsInJSON) {
      optionsArray.push({
        value: aOption[property_value],
        title: aOption[property_name],
        label: aOption[property_name],
      });
    }
    return optionsArray;
  };

  getFieldsMap = () => {
    // front:back
    return {
      planned_event: "planned_event_type_id",
      event_name: "event_name",
      vessel: "vessel_code",
      // event_year_month: "event_year_month",
      // days: "event_days",
      event_start_date: "start_date",
      event_end_date: "end_date",
      cost: "operation_cost",
      days: "duration_in_days",
    };
  };

  updateAllFeildsValue = (responseFeildsObject) => {
    const feildsMap = this.getFieldsMap();
    let { feilds } = this.state;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];

      if (value !== undefined) {
        // if (key === "last_data_entry_date") {
        //   //"2019-12-31T11:49:41.457000"
        //   timestamp = value;
        //   value = value.split("T")[0];
        // } else if (
        //   key === "import_operation_timestamp" ||
        //   key === "calculation_operation_timestamp"
        // ) {
        //   value = value.replace("T", " ").slice(0, 19);
        // }

        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({
      isAllLoaded: this.state.isAllLoaded + 1,
      feilds,
    });
  };

  getPostRequestObject = () => {
    const feildsMap = this.getFieldsMap();
    const formFeildsState = this.state.feilds;
    let postRequestObject = {};
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = formFeildsState[key];
      const is_required = this.labels[key]?.required === false ? false : true;
      if (value !== undefined) {
        if (value.length === 0 && is_required) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Error:" + feildName + " value is empty");
          return null;
        }
        postRequestObject[feildKey] = value;
      }
    }
    return postRequestObject;
  };

  responceError = (error) => {
    // alert("Error: " + error.response.status);
    if (error.response.status === 401) {
      this.setState({ isLoggedIn: false });
    }
    if (error.response.status === 404) {
      this.setState({
        onProgress: false,
        error: "No data found for the given dates.",
      });
    } else {
      this.setState({
        onProgress: false,
        error: "Error: " + error.response.statusText,
      });
    }
  };

  async componentDidMount() {
    // debugger;
    //MONTH, VESSEL, PLANNED_EVENT

    EventTypeService.getAll()
      .then(
        (response) => {
          if (response.status === 200) {
            // eventTypes = response.data.map((eventType) => {
            //   return {
            //     value: eventType.id,
            //     title:
            //       eventType.name +
            //       " (" +
            //       eventType.description.slice(0, 10) +
            //       "... )",
            //   };
            const eventTypes = response.data;
            // const eventCategories = this.extractEventsCategoriesFromEventTypes(
            //   response.data
            // );
            const eventTypesOptions = this.getOptionsArrayForSelectElement(
              eventTypes.filter(
                (eventType) =>
                  eventType?.offhire_events_category?.name === "Planned"
              )
            );
            // const eventCategoriesOptions =
            //   this.getOptionsArrayForSelectElement(eventCategories);
            this.setState({
              eventTypes,
              isEventTypesLoaded: true,
              // eventCategories,
              feildsOptions: {
                ...this.state.feildsOptions,
                planned_event: eventTypesOptions,
                // is_planned: eventCategoriesOptions,
              },
              isAllLoaded: this.state.isAllLoaded + 1,
            });
          }
        },
        (error) => {
          this.responceError(error);
        }
      );

    VesselService.getAsOptions()
      .then(
        (response) => {
          if (response.status === 200) {
            // vessels = response.data.map((vessel) => {
            //   return {
            //     value: vessel.id,
            //     title:
            //       vessel.name +
            //       " (" +
            //       vessel.description.slice(0, 10) +
            //       "... )",
            //   };
            const vessels = response.data;
            const vesselsOptions = this.getOptionsArrayForSelectElement(
              vessels,
              "vessel_code",
              "vessel_name"
            );
            this.setState({
              vessels,
              isVesselsLoaded: true,
              feildsOptions: {
                ...this.state.feildsOptions,
                vessel: vesselsOptions,
              },
              isAllLoaded: this.state.isAllLoaded + 1,
            });
          }
        },
        (error) => {
          this.responceError(error);
        }
      );

    // this.setState({
    //   isEventTypesLoaded,
    //   eventTypes,
    //   isVesselsLoaded,
    //   vessels,
    //   onProgress: false,
    //   isLoaded: isEventTypesLoaded && isVesselsLoaded,
    // });
  }

  handleValueChange = (e) => {
    // debugger;
    let { feilds } = this.state;
    let key = e.target.name;
    let value = e.target.value.toString();

    if (key === "event_start_date") {
      feilds["days"] = this.getDaysBetweenDates(
        value,
        feilds["event_end_date"]
      );
    } else if (key === "event_end_date") {
      const diff = this.getDaysBetweenDates(feilds["event_start_date"], value);
      if (diff < 0) {
        alert("End date should be greater than Start date");
        return;
      }
      feilds["days"] = diff;
    }
    feilds[key] = value;
    this.setState({ feilds });
  };

  getDaysBetweenDates = (startDate, endDate) => {
    // debugger;
    if (startDate === "" || endDate === "") {
      return "";
    }
    var start = new Date(startDate);
    var end = new Date(endDate);
    var days = (end - start) / (1000 * 60 * 60 * 24);
    return days;
  };

  // Form submit handler
  handleFormSubmit = (event) => {
    // debugger;
    // prevent default form submit
    event.preventDefault();
    // Mark the state as submitted to show the spinner
    this.setState({ onProgress: true });
    // Create object from form data
    const postRequestObject = this.getPostRequestObject();
    // Empty form validation
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }
    // console.log("postRequestObject");
    // console.log(postRequestObject);
    // return;
    this.setState({
      reportsWithTimelineConflits: null,
      isReportsWithTimelineConflitsLoaded: false,
      isStateChanged: !this.state.isStateChanged,
    });


    PlannedEventService.create(postRequestObject)
      .then(
        (response) => {
          // console.log("Post Response");
          // console.log(response);
          // console.log(this.props);
          if (response.data && response.data.length > 0) {
            this.updateAllFeildsValue(response.data[0]);
            this.setState({ onProgress: false });
          }
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            alert("Successfully Submitted");
          }

          //   console.log(response);
        },
        (error) => {
          let errorMessage = `Error: ${error.response.status} ${
            error.response?.data?.detail ?? error.response?.statusText
          }`;
          // console.log("Error");
          // console.log(error);
          if (error.response.status === 401) {
            this.setState({ isLoggedIn: false });
          }
          if (
            error.response.status === 409 &&
            error.response?.data?.length > 0
          ) {
            errorMessage =
              "Given time period is overlapping with already existing event";
            this.setState({
              reportsWithTimelineConflits: error.response.data,
              isReportsWithTimelineConflitsLoaded: true,
              isStateChanged: !this.state.isStateChanged,
            });
          }
          alert(errorMessage);
          this.setState({
            onProgress: false,
            error: errorMessage,
          });
          setTimeout(() => {
            this.setState({ error: null });
          }, 3000);
        }
      );
  };

  getSubmitButton = (buttonTitle, isSizeSmall = false) => {
    return (
      <div className="form-group row  m-0 p-0 pt-1 justify-content-end">
        <div className="col-sm-12 col-md-6">
          {this.state.onProgress && (
            <React.Fragment>
              <span className="text-danger" style={{ fontSize: "0.7rem" }}>
                {" "}
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                <span className="sr-only">Genrating...</span>
              </span>
            </React.Fragment>
          )}
          {!this.state.error && (
            <span className="text-danger" style={{ fontSize: "0.7rem" }}>
              {this.state.error}
            </span>
          )}
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <input
            type="submit"
            className="form-control btn btn-outline-primary btn-sm p-0"
            name="submit"
            id="submit"
            value={buttonTitle}
            style={{ fontSize: "0.6rem" }}
            disabled={this.state.onProgress}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      feilds,
      feildsOptions,
      isAllLoaded,
      isLoggedIn,
      reportsWithTimelineConflits,
    } = this.state;

    if (!isLoggedIn) return <Navigate replace to="/sessionends" />;
    if (isAllLoaded < 2) return <AnSpinner />;

    let formFeildsArr = [];
    for (let key in this.labels) {
      const selectOptions = this.labels[key].options ?? [
        { value: "", title: "--Select--" },
      ];

      formFeildsArr.push({
        ...this.labels[key],
        name: key,
        value: feilds[key],
        onChangeHandler: this.handleValueChange,
        options: [...selectOptions, ...(feildsOptions[key] ?? [])],
      });
    }

    return (
      <React.Fragment>
        <AnHPForm onBack={this.props.onBack} onSubmit={this.handleFormSubmit}>
          <div className="row">
            {formFeildsArr.map((attribute, index) => {
              return (
                <React.Fragment>
                  <AnInlineFormGroup attribute={attribute} key={index} />
                </React.Fragment>
              );
            })}
          </div>
          {/* <VesselOffhireReportTableWithTimelineConflict
            reports={reportsWithTimelineConflits}
          /> */}
          {reportsWithTimelineConflits && (
            <OffhireBudgetTableWithTimelineConflicts
              budgetedEvents={reportsWithTimelineConflits}
            />
          )}

          {this.getSubmitButton("Add")}
        </AnHPForm>
      </React.Fragment>
    );
  }
}

export default AddBudgetItemForm;
