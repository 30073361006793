import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";
import { getOptionsArrayForSelectElement } from "../../offhire/util/first";

function FleetForm(props){
     
    const labels = {
        fleet_name: {
          isGroupTitle: false,
          title: "Fleet Name",
          type: "text",
          value: "",
        },
        fleet_code: {
          isGroupTitle: false,
          title: "Fleet Code",
          type: "text",
          value: "",
        },
        fleet_description: {
          isGroupTitle: false,
          title: "Fleet Description",
          type: "text",
          value: "",
        },
        fleet_type: {
          isGroupTitle: false,
          title: "Fleet Type",
          type: "text",
          value: "",
        },
        fleet_status: {
          isGroupTitle: false,
          title: "Fleet Status",
          type: "text",
          value: "",
        },
        fleet_manager: {
          isGroupTitle: false,
          title: "Fleet Manager",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Fleet Manager-",
            },
          ],
          optionsFetch:{
            api:  "/person/fleetmanager",
          }
        },
      };

      const getFieldsMap = () => {
        // front:back
        return {
            fleet_name: "fleet_name",
            fleet_code: "fleet_code",
            fleet_description: "description",
            fleet_type: "fleet_type",
            fleet_status: "fleet_status",
            fleet_manager: "fleet_manager_id",
        };
      };

     return (
        <AnCommonFormDialog
            {...props}
            header="Add Fleet"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/fleet/"
        />
     )
    
}

export default FleetForm;