import IsAvailable from "../hoc/IsAvailable";
import { AnDialog } from "../other/AnDialog";
import React, { Component } from "react";
import {
  getSubmitButton,
  getOptionsArrayForSelectElement,
} from "../../../offhire/util/first";
import AnHPForm from "./factory/AnHPForm";
import AnInlineFormGroup from "./factory/AnInlineFormGroup";
import { errorHandler } from "../../utility/http-util";
import AnFormGroup from "./factory/AnFormGroup";
import Row from "../other/Row";
import { BaseService } from "../../../offhire/services/offhire-services";

export class InvalidForm{
  constructor(message){
    this.message = message;
  }
}

class AnCommonFormDialog extends Component {
  state = {
    isLoggedIn: true,
    isLoaded: true,
    onProgress: false,
    error: null,
    feilds: {},
    feildsOptions: {},
    isOptionsLoaded: {},
  };

  toastRef;
  handleToastRef = ref=>this.toastRef=ref;


  constructor(props) {
    super(props);
    const { formFields } = props;
    this.state["feilds"] = {};
    for (let key in formFields) {
      let { value, isGroupTitle } = formFields[key];
      if (!isGroupTitle) {
        this.state.feilds[key] = value;
      }
    }
  }

 

  updateAllFeildsValue = (responseFeildsObject) => {
    const { formFieldsMap } = this.props;
    const feildsMap = formFieldsMap();
    let { feilds } = this.state;
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = responseFeildsObject[feildKey];
      if (value !== undefined) {
        feilds[key] = value;
      }
    }
    const { setFeilds } = this.props;
    if (setFeilds) {
      setFeilds(feilds);
    }
    this.setState({ isLoaded: true, feilds });
  };

  getPostRequestObject = () => {
    const { formFields, formFieldsMap, formValidator } = this.props;
    const feildsMap = formFieldsMap();
    const formFeildsState = this.state.feilds;
    let postRequestObject = {};
    if(typeof formValidator === 'function'){
      const invalid = formValidator(formFeildsState);
      if(invalid){
        alert(invalid.message);
        return null;
      }
    }
    for (let key in feildsMap) {
      const feildKey = feildsMap[key];
      let value = formFeildsState[key];
      const is_required = formFields[key]?.required === false ? false : true;
      if (value !== undefined) {
        if (value.length === 0 && is_required) {
          const feildName = key.split("_").join(" ").toUpperCase();
          alert("Error:" + feildName + " value is empty");
          return null;
        }

        postRequestObject[feildKey] = value;
      }
    }
    return postRequestObject;
  };

  responseError = (error) => errorHandler(
    error, 
    this.setState.bind(this), 
    this.toastRef, 
    null, 
    true
    );

  async componentDidMount() {
    const { formFields } = this.props;
    // Call the API to get the options for the select elements
    Object.entries(formFields)
    .filter(([key,value])=>value.optionsFetch)
    .forEach(([key,value])=>{
      const { api, fetcher, responseHandler } = value.optionsFetch;
      this.optionsFetcher(key, fetcher ?? api, responseHandler);
    });
  }

  /**
   * 
   * @param {string} key 
   * @param {string | ()=>Promise<AxiosResponse<any, any>>} urlOrFetcher 
   * @param {(responseData)=>Array} responseHandler 
   * @returns 
   */
  optionsFetcher = (key, urlOrFetcher, responseHandler) => {
    this.setState({ isOptionsLoaded: { ...this.state.isOptionsLoaded, [key]: false } });
    if(!urlOrFetcher) return;

    const FETCHER = typeof urlOrFetcher === 'string' 
      ? () => BaseService.get(urlOrFetcher) 
      : urlOrFetcher;
    
    FETCHER().then(
        (response) => {
          if (response.status === 200) {
            const data = response.data;
            // Default response handler for select element options
            if(!responseHandler) responseHandler = getOptionsArrayForSelectElement;
            const options = responseHandler(data);
            this.setState({
              isOptionsLoaded: {
                ...this.state.isOptionsLoaded,
                [key]: true,
              },
              feildsOptions: {
                ...this.state.feildsOptions,
                [key]: options,
              },
            });
          }
        },
        (error) => {
          this.responseError(error);
        }
      );
  }

  handleValueChange = (e) => {
    let { feilds } = this.state;
    let { valueChangeInterceptor } = this.props;
    let key = e.target.name;
    let value = e.target.value.toString();
    if(typeof valueChangeInterceptor === 'function')
      value = valueChangeInterceptor(key, value, feilds);
    feilds[key] = value;
    this.setState({ feilds });
  };


  handleFormSubmit = (event) => {
    // prevent default form submit
    event.preventDefault();
    // Mark the state as submitted to show the spinner
    this.setState({ onProgress: true });
    // Create object from form data
    const postRequestObject = this.getPostRequestObject();
    // Empty form validation
    if (postRequestObject == null) {
      this.setState({ onProgress: false });
      return;
    }
    const { createAPI, onHide, refreshData } = this.props;

    BaseService.create(createAPI, postRequestObject)
      .then(
        (response) => {
          if (response.data) {
            this.updateAllFeildsValue(response.data);
            this.setState({ onProgress: false });
          }
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            onHide?.();
            refreshData?.();
            alert("Successfully Submitted");
          }
        },
        (error) => this.responseError(error)
      );
  };

  render() {
    const { feilds, feildsOptions, isLoaded, isLoggedIn } = this.state;

    const { visible, onHide, header, footerContent, position, formFields } = this.props;

    const formFeildsArr = Object.entries(formFields).map(([key, value]) => ({
      ...value,
      name: key,
      value: feilds[key],
      onChangeHandler: this.handleValueChange,
      options: [
        ...(value.options ?? [
          { value: "", title: "--Select--" },
        ]), 
        ...(feildsOptions[key] ?? [])
      ],
    }));

    return (
    <IsAvailable isLoaded={isLoaded} isLoggedIn={isLoggedIn} toastRef={this.handleToastRef}>
        <AnDialog className="an-dialog" header={header} visible={visible} position={position ?? 'right'} 
          onHide={onHide} footer={footerContent} draggable={false} resizable={false}>
            <AnHPForm onBack={this.props.onBack} onSubmit={this.handleFormSubmit}>
                {formFeildsArr.map((attribute, index) => 
                    // <AnInlineFormGroup attribute={attribute} key={index} />
                    <Row>
                      <AnFormGroup isCustom={true} attribute={attribute} key={index} />
                    </Row>
                )}
                {getSubmitButton(
                    "Add",
                    false,
                    this.state.onProgress,
                    this.state.error
                )}
            </AnHPForm>
        </AnDialog>
    </IsAvailable>
    );
  }
}

export default AnCommonFormDialog;
