import React, { Component } from "react";
import { Link } from "react-router-dom";
// import app from "../../core/application/session";
import { StatusPill, getOffhireDaysBetweenDates, } from "../util/first";
import { getUser } from "../../core/application/session";
import OffhireReportExpandView from "./OffhireReportExpandView";
import { getDaysBetweenDates } from "../../core/utility/conversion";
import OffhireReportTechnicalDetails from "./OffhireReportTechnicalDetails";
import OffhireReportTechnicalUpdateHistory from "./OffhireReportTechnicalUpdateHistory";
import ORTUpdateHistory from "./ORTUpdateHistory";
class VesselOffhireReport extends Component {
  state = {
    doExpand: false,
  };

  /**
   * Report Object Format
   [
  {
    "vessel_name": "string",
    "name": "string",
    "vessel_code": 0,
    "voyage_number": 0,
    "voyage_status": "string",
    "commenced_at": "2022-02-23T18:54:35.150Z",
    "completed_at": "2022-02-23T18:54:35.150Z",
    "activity": "string",
    "delay_start_at_gmt": "2022-02-23T18:54:35.150Z",
    "delay_end_at_gmt": "2022-02-23T18:54:35.150Z",
    "reason": "string",
    "tci": 0,
    "tco": 0,
    "id": 0,
    "delay_year": 0,
    "delay_start_at_local": "2022-02-23T18:54:35.150Z",
    "delay_end_at_local": "2022-02-23T18:54:35.150Z",
    "hours": 0,
    "custom_offhire_days": 0,
    "custom_offhire_days_group": "string",
    "offhire_report_technical": {
      "event_type_id": 0,
      "machinery_id": 0,
      "delay_start_at_gmt": "2022-02-23T18:54:35.150Z",
      "delay_end_at_gmt": "2022-02-23T18:54:35.150Z",
      "reason": "string",
      "id": 0,
      "ship_manager_comment": "string",
      "ship_manager_updated_report_at": "2022-02-23T18:54:35.150Z",
      "fleet_manager_comment": "string",
      "fleet_manager_updated_report_at": "2022-02-23T18:54:35.150Z",
      "machinery": {
        "machinery_type_id": 0,
        "name": "string",
        "description": "string",
        "id": 0,
        "machinery_type": {
          "name": "string",
          "description": "string",
          "id": 0
        }
      },
      "event_type": {
        "offhire_events_category_id": 0,
        "name": "string",
        "description": "string",
        "id": 0,
        "offhire_events_category": {
          "name": "string",
          "description": "string",
          "id": 0
        }
      }
    }
  }
] 
   */

  showReportActionsButton = (
    vesselOffhireReport,
    report_status,
    USERROLE,
    is_overlapping = true
  ) => {

    const { onAction } = this.props;
    const isShipManager = USERROLE === "SHIPMANAGER";
    const isFleetManager = USERROLE === "FLEETMANAGER";
    const buttonClassName = "btn btn-sm p-1 py-0 btn-outline-";
    // debugger;
    let element = (
      // <Link
      //   to={"/offhire/report/view"}
      //   state={vesselOffhireReport}
      //   className={buttonClassName + "secondary "}
      //   data-toggle="tooltip"
      //   data-placement="top"
      //   title="View Technical Offhire Report"
      // >
      //   <i className="fa fa-eye"></i>
      // </Link>
      <button 
      className={buttonClassName + "secondary "}
      onClick={()=> onAction({
        action: "view",
        state: vesselOffhireReport
      })}>
        <i className="fa fa-eye"></i>
      </button>
    );

    if (isShipManager) {
      // Create Report
      if (!is_overlapping) {
        if (report_status === "INCOMPLETE") {
          element = (
            <>
              {element}
              <Link
                className={buttonClassName + "info m-1"}
                to={"/offhire/report/create"}
                state={vesselOffhireReport}
                data-toggle="tooltip"
                data-placement="top"
                title="Create Technical Offhire Report"
              >
                <i className="fa fa-file-o"></i>
              </Link>
            </>
          );
        } else {
          element = (
            <>
              {element}
              <Link
                to={"/offhire/report/addon"}
                state={vesselOffhireReport}
                className={buttonClassName + "secondary"}
                data-toggle="tooltip"
                data-placement="top"
                title="Add Technical Offhire Report"
              >
                <i className="fa fa-plus"></i>
              </Link>
            </>
          );
        }
      }

      // report_status === "APPROVED" ||
      if (report_status === "REJECTED" || report_status === "PENDING")
        element = (
          <>
            {element}
            <Link
              to={"/offhire/report/update"}
              state={vesselOffhireReport}
              className={buttonClassName + "secondary"}
              title="Edit Technical Offhire Report"
            >
              <i className="fa fa-edit"></i>
            </Link>
          </>
        );
    }

    // Approve/Reject Technical Offhire Report by Fleet Manager
    if (report_status === "PENDING" && isFleetManager)
      element = (
        <>
          {element}
          <Link
            to={"/offhire/report/check"}
            state={vesselOffhireReport}
            className={buttonClassName + "secondary"}
            data-toggle="tooltip"
            data-placement="top"
            title="View Technical Offhire Report"
          >
            <i className="fa fa-arrow-right"></i>
          </Link>
        </>
      );

    // View TECHNICAL OFHIRE REPORT
    // if (
    //   report_status === "APPROVED" ||
    //   (report_status === "INCOMPLETE" && !isShipManager)
    // )
    //   element = (
    //     <>
    //       {element}
    //       <Link
    //         to={"/offhire/report/view"}
    //         state={vesselOffhireReport}
    //         className={buttonClassName+"secondary"}
    //         data-toggle="tooltip"
    //         data-placement="top"
    //         title="View Technical Offhire Report"
    //       >
    //         <i className="fa fa-eye"></i>
    //       </Link>
    //     </>
    //   );

    return element;
  };

  render() {
    const { doExpand } = this.state;
    const { srno, vesselOffhireReport, theme, reportsIdForApprovalHandler, reportsIdForRemoveHandler, isGrouped } =
      this.props;
    const cellThemeClassName = theme === "dark" ? `table-dark bg-white` : `table-light bg-dark`;
    let user = getUser();
    let USERROLE = user?.role?.name?.toUpperCase() ?? "";
    // const USERNAME = localStorage.getItem("offhire-username");
    // if (USERNAME === "offhiredemo1") USERROLE = "FLEETMANAGER"; //localStorage.getItem("user_role");
    // if (USERNAME === "offhiredemo2") USERROLE = "SHIPMANAGER"; //localStorage.getItem("user_role");

    const {
      vessel_name,
      name,
      vessel_code,
      voyage_number,
      voyage_status,
      commenced_at,
      completed_at,
      activity,
      delay_start_at_gmt,
      delay_end_at_gmt,
      reason,
      tci,
      tco,
      id,
      delay_year,
      delay_start_at_local,
      delay_end_at_local,
      hours,
      custom_offhire_days,
      custom_offhire_days_group,
      offhire_report_technical,
      report_status,
      is_active,
      is_overlapping,
    } = vesselOffhireReport;

    let offhireType = "";
    if (offhire_report_technical?.event_type?.name)
      offhireType = offhire_report_technical.event_type.name;
    // let event_category = "";
    // if (offhire_report_technical?.event_type?.offhire_events_category?.name)
    //   event_category =
    //     offhire_report_technical.event_type?.offhire_events_category.name;

    let is_planned_report = "";
    if (offhire_report_technical?.is_planned === true)
      is_planned_report = "Planned";
    else if (offhire_report_technical?.is_planned === false)
      is_planned_report = "Unplanned";

    let machinery_type = "";
    if (offhire_report_technical?.machinery?.machinery_type.name)
      machinery_type = offhire_report_technical.machinery.machinery_type.name;
    let machinery_name = "";
    if (offhire_report_technical?.machinery?.name)
      machinery_name = offhire_report_technical.machinery.name;
    let vessel_age = "",
      location = offhire_report_technical?.location ?? "",
      place =
        offhire_report_technical?.location?.toUpperCase() === "SEA"
          ? offhire_report_technical?.sea?.name ?? ""
          : offhire_report_technical?.port?.name ?? "";

    let rowSpan = offhire_report_technical ? 2 : 1;
    // console.log("offhire_report_technical", offhire_report_technical);
    const isDelayStartDatetimeDiff =
      delay_start_at_gmt === offhire_report_technical?.delay_start_at_gmt
        ? false
        : true;
    const isDelayEndDatetimeDiff =
      delay_end_at_gmt === offhire_report_technical?.delay_end_at_gmt
        ? false
        : true;
    const offhireReportTechnicalDataColorStyleClass = "text-primary";
    const OffhireTimeOnDiffersColorStyleClass = "text-danger";

    return (
      <React.Fragment>
        <tr
          key={"report_row_" + id}
          className={
            is_active === false ? "table-secondary text-secondary" : ""
          }
        >
          <td
            className={
              offhire_report_technical
                ? offhireReportTechnicalDataColorStyleClass
                : ""
            }
            rowSpan={rowSpan}
            style={{ fontSize: "1rem" }}
            onClick={() => {
              this.setState({ doExpand: !doExpand });
            }}
          >
            {doExpand ? (
              // <br/>
              <i className="fa fa-caret-down" style={{ fontSize: "1rem" }} />
            ) : (
              <i className="fa fa-caret-right" style={{ fontSize: "1rem" }} />
            )}{" "}
            {srno}
          </td>
          <td rowSpan={rowSpan}>{vessel_name}</td>
          {/* <td rowSpan={rowSpan}>{vessel_age}</td> */}
          <td
            className={offhireReportTechnicalDataColorStyleClass}
            rowSpan={rowSpan}
          >
            {location}
          </td>
          <td
            className={offhireReportTechnicalDataColorStyleClass}
            rowSpan={rowSpan}
          >
            {place}
          </td>
          <td className={is_overlapping === true ? "text-warning" : ""}>
            {delay_start_at_gmt.slice(0, 10)}
          </td>
          <td className={is_overlapping === true ? "text-warning" : ""}>
            {delay_start_at_gmt.slice(11, 16)}
          </td>
          <td className={is_overlapping === true ? "text-warning" : ""}>
            {delay_end_at_gmt.slice(0, 10)}
          </td>
          <td className={is_overlapping === true ? "text-warning" : ""}>
            {delay_end_at_gmt.slice(11, 16)}
          </td>
          <td>{custom_offhire_days.toFixed(2)}</td>
          <td rowSpan={rowSpan}>{activity}</td>
          <td
            className={offhireReportTechnicalDataColorStyleClass}
            rowSpan={rowSpan}
          >
            {/* {event_category} */}
            {is_planned_report}
          </td>
          <td
            className={offhireReportTechnicalDataColorStyleClass}
            rowSpan={rowSpan}
          >
            {offhireType}
          </td>
          <td
            className={offhireReportTechnicalDataColorStyleClass}
            rowSpan={rowSpan}
          >
            {machinery_type}
          </td>
          <td
            className={offhireReportTechnicalDataColorStyleClass}
            rowSpan={rowSpan}
          >
            {machinery_name}
          </td>
          <td rowSpan={rowSpan}>{reason}</td>
          <td rowSpan={rowSpan}>
            {report_status === "PENDING" && USERROLE === "FLEETMANAGER" && (
              <input
                type="checkbox"
                className="form-check-input"
                onClick={(e) => {
                  let operation = e.target.checked ? "add" : "remove";
                  reportsIdForApprovalHandler(id, operation);
                }}
              />
            )}
            {report_status === "INCOMPLETE" && !is_active && USERROLE === "FLEETMANAGER" && (
              <input
                type="checkbox"
                className="form-check-input"
                onClick={(e) => {
                  let operation = e.target.checked ? "add" : "remove";
                  reportsIdForRemoveHandler(id, operation);
                }}
              />
            )}
            <StatusPill status={report_status} />
          </td>
          <td rowSpan={rowSpan} style={{ fontSize: "1.10rem" }}>
            {this.showReportActionsButton(
              vesselOffhireReport,
              report_status,
              USERROLE,
              is_overlapping
            )}
          </td>
        </tr>
        {rowSpan > 1 && (
          <tr key={"report_row_" + id + "_2"}>
            <td
              className={
                isDelayStartDatetimeDiff
                  ? OffhireTimeOnDiffersColorStyleClass
                  : offhireReportTechnicalDataColorStyleClass
              }
              title="Offhie(Technical) Delay Start Date"
            >
              {offhire_report_technical?.delay_start_at_gmt?.slice(0, 10)}
            </td>
            <td
              className={
                isDelayStartDatetimeDiff
                  ? OffhireTimeOnDiffersColorStyleClass
                  : offhireReportTechnicalDataColorStyleClass
              }
              title="Offhie(Technical) Delay Start Time"
            >
              {offhire_report_technical?.delay_start_at_gmt?.slice(11, 16)}
            </td>
            <td
              className={
                isDelayEndDatetimeDiff
                  ? OffhireTimeOnDiffersColorStyleClass
                  : offhireReportTechnicalDataColorStyleClass
              }
              title="Offhie(Technical) Delay End Date"
            >
              {offhire_report_technical?.delay_end_at_gmt?.slice(0, 10)}
            </td>
            <td
              className={
                isDelayEndDatetimeDiff
                  ? OffhireTimeOnDiffersColorStyleClass
                  : offhireReportTechnicalDataColorStyleClass
              }
              title="Offhie(Technical) Delay End Time"
            >
              {offhire_report_technical?.delay_end_at_gmt?.slice(11, 16)}
            </td>
            <td
              className={offhireReportTechnicalDataColorStyleClass}
              title="Offhie(Technical) Delay in days X tci"
            >
              <b>
                {getOffhireDaysBetweenDates(
                  offhire_report_technical?.delay_start_at_gmt,
                  offhire_report_technical?.delay_end_at_gmt,
                  tci
                )}
              </b>
            </td>
          </tr>
        )}
        {doExpand && (
          // <OffhireReportExpandView report={vesselOffhireReport} />
          <>
            <tr>
              <td colSpan="20">
                <OffhireReportTechnicalDetails report={vesselOffhireReport} isGrouped={isGrouped}/>
              </td>
            </tr>
          </>
        )}
      </React.Fragment>
    );
  }
}

export default VesselOffhireReport;
