import React, { Component } from "react";

class AnScreenCenter extends Component {
  state = {};
  render() {
    return (
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "75vh" }}
      >
        <div className="col-auto">{this.props.children}</div>
      </div>
    );
  }
}

export default AnScreenCenter;
