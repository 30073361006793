

export const appAlert=(message)=>{
    alert(message ?? '');
}

export const appAlertError=(error)=>{
    const errorStatus = error?.response?.status;
    appAlert(`Operation failed with a error ${errorStatus??''}!`);
    console.error(error)
}