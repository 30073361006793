import React, { Component } from "react";
import Select from "react-select";

class AnSearchableSelect extends Component {
  state = {
    value: "",
    valueLabel: "",
    inFocus: false,
  };

  changeLabel = (event) => {
    // debugger;
    const { name, value } = event.target;
    const { options } = this.props;
    const selectedOption = options.find((item) => item.value === value);
    this.setState({ valueLabel: selectedOption.label });
  };

  componentDidMount() {
    const { value } = this.props;
    const valueLabel = this.getLabel(value);
    this.setState({ value, valueLabel });
  }

  getLabel = (value) => {
    const { options } = this.props;
    const selectedOption = options.find((item) =>
      this.isValuesEqual(item.value, value)
    );
    return selectedOption?.label ?? "";
  };

  isValuesEqual = (value1, value2) => {
    return value1?.toString() === value2?.toString();
  };

  render() {
    const {
      className,
      id,
      name,
      value,
      onChange,
      options,
      required,
      style,
      readOnly,
    } = this.props;
    const { inFocus } = this.state;
    // const { value: prevSelectedValue } = this.state;

    // let valueLabel = "";
    // if (value?.toString() !== prevSelectedValue?.toString()) {
    //   debugger;
    //   const selectedOption = options.find(
    //     (item) => item.value?.toString() === value?.toString()
    //   );
    //   valueLabel = selectedOption?.title || selectedOption?.label;
    //   console.log("vl", valueLabel);
    // }
    const valueLabel = this.getLabel(value);

    return (
      <div
        onFocus={() => this.setState({ inFocus: true })}
        onBlur={() => this.setState({ inFocus: false })}
      >
        <input
          type="text"
          id={name + "_label"}
          name={name + "_label"}
          value={valueLabel}
          //   value={this.state.valueLabel}
          className={`form-control form-control-sm p-1 ${className}`}
          required={required === false ? false : true}
          readOnly={readOnly ? true : false}
          disabled={readOnly ? true : false}
          style={style}
        />
        <input
          type="hidden"
          id={name}
          name={name}
          value={value}
          //   onChange={this.changeLabel}
          required={required === false ? false : true}
          readOnly={readOnly ? true : false}
          disabled={readOnly ? true : false}
        />
        {inFocus && (
          <div className="px-1">
            <Select
              defaultMenuIsOpen
              options={options}
              placeholder="Search Here"
              onChange={(selelctedOption) => {
                this.setState({ value: selelctedOption.value });
                onChange({
                  target: {
                    value: selelctedOption.value,
                    name: name,
                    valueLabel: selelctedOption.label,
                  },
                });
              }}
              isSearchable={true}
              //   isClearable={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AnSearchableSelect;
