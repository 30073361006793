import React, { Component } from "react";
import { Link } from "react-router-dom";

class VesselOffhireReportWithTimelineConflict extends Component {
  state = {};

  /**
   * Report Object Format
   [
  {
    "vessel_name": "string",
    "name": "string",
    "vessel_code": 0,
    "voyage_number": 0,
    "voyage_status": "string",
    "commenced_at": "2022-02-23T18:54:35.150Z",
    "completed_at": "2022-02-23T18:54:35.150Z",
    "activity": "string",
    "delay_start_at_gmt": "2022-02-23T18:54:35.150Z",
    "delay_end_at_gmt": "2022-02-23T18:54:35.150Z",
    "reason": "string",
    "tci": 0,
    "tco": 0,
    "id": 0,
    "delay_year": 0,
    "delay_start_at_local": "2022-02-23T18:54:35.150Z",
    "delay_end_at_local": "2022-02-23T18:54:35.150Z",
    "hours": 0,
    "custom_offhire_days": 0,
    "custom_offhire_days_group": "string",
    "offhire_report_technical": {
      "event_type_id": 0,
      "machinery_id": 0,
      "delay_start_at_gmt": "2022-02-23T18:54:35.150Z",
      "delay_end_at_gmt": "2022-02-23T18:54:35.150Z",
      "reason": "string",
      "id": 0,
      "ship_manager_comment": "string",
      "ship_manager_updated_report_at": "2022-02-23T18:54:35.150Z",
      "fleet_manager_comment": "string",
      "fleet_manager_updated_report_at": "2022-02-23T18:54:35.150Z",
      "machinery": {
        "machinery_type_id": 0,
        "name": "string",
        "description": "string",
        "id": 0,
        "machinery_type": {
          "name": "string",
          "description": "string",
          "id": 0
        }
      },
      "event_type": {
        "offhire_events_category_id": 0,
        "name": "string",
        "description": "string",
        "id": 0,
        "offhire_events_category": {
          "name": "string",
          "description": "string",
          "id": 0
        }
      }
    }
  }
] 
   */

  render() {
    const { srno, vesselOffhireReport } = this.props;
    // console.log("vesselOffhireReport");
    // console.log(vesselOffhireReport);
    /**
    
     */
    const {
      vessel_name,
      name,
      vessel_code,
      voyage_number,
      voyage_status,
      commenced_at,
      completed_at,
      activity,
      delay_start_at_gmt,
      delay_end_at_gmt,
      reason,
      tci,
      tco,
      id,
      delay_year,
      delay_start_at_local,
      delay_end_at_local,
      hours,
      custom_offhire_days,
      custom_offhire_days_group,
      offhire_report_technical,
      report_status,
    } = vesselOffhireReport;

    let vessel_age = "",
      location = "",
      place = "",
      technical_delay_end_at_gmt = "",
      technical_delay_start_at_gmt = "",
      offhireType = "",
      event_category = "",
      machinery_type = "",
      machinery_name = "";

    if (offhire_report_technical?.event_type?.name)
      offhireType = offhire_report_technical.event_type.name;
    if (offhire_report_technical?.events_category?.name)
      event_category =
        offhire_report_technical.events_category.name;
    if (offhire_report_technical?.machinery?.machinery_type.name)
      machinery_type = offhire_report_technical.machinery.machinery_type.name;
    if (offhire_report_technical?.machinery?.name)
      machinery_name = offhire_report_technical.machinery.name;
    if (offhire_report_technical?.delay_start_at_gmt)
      technical_delay_start_at_gmt =
        offhire_report_technical.delay_start_at_gmt;
    if (offhire_report_technical?.delay_end_at_gmt)
      technical_delay_end_at_gmt = offhire_report_technical.delay_end_at_gmt;

    let statusBadgeColorClass = "danger";
    if (report_status === "APPROVED") statusBadgeColorClass = "success";
    else if (report_status === "INCOMPLETE") statusBadgeColorClass = "danger";
    else if (report_status === "PENDING") statusBadgeColorClass = "warning";
    let rowSpan = 1;
    return (
      <React.Fragment>
        <tr>
          <td
            rowSpan={rowSpan}
            className={
              offhire_report_technical ? "text-success" : "text-danger"
            }
          >
            {srno}
          </td>
          <td rowSpan={rowSpan}>{vessel_name}</td>
          <td rowSpan={rowSpan}>{vessel_age}</td>
          <td rowSpan={rowSpan}>{location}</td>
          <td rowSpan={rowSpan}>{place}</td>

          <td rowSpan={rowSpan}>{offhireType}</td>
          <td rowSpan={rowSpan}>{machinery_type}</td>
          <td rowSpan={rowSpan}>{machinery_name}</td>
          <td rowSpan={rowSpan}>{reason}</td>
          <td rowSpan={rowSpan}>{event_category}</td>
          <td rowSpan={rowSpan}>{activity}</td>
          {/* <td>{delay_start_at_gmt.slice(0, 10)}</td>
          <td>{delay_start_at_gmt.slice(11, 16)}</td>
          <td>{delay_end_at_gmt.slice(0, 10)}</td>
          <td>{delay_end_at_gmt.slice(11, 16)}</td>
          <td>{custom_offhire_days.toFixed(2)}</td> */}
          <td className="table-danger text-danger">
            {technical_delay_start_at_gmt.slice(0, 10)}
          </td>
          <td className="table-danger text-danger">
            {technical_delay_start_at_gmt.slice(11, 16)}
          </td>
          <td className="table-danger text-danger">
            {technical_delay_end_at_gmt.slice(0, 10)}
          </td>
          <td className="table-danger text-danger">
            {technical_delay_end_at_gmt.slice(11, 16)}
          </td>
          {/* <td>{custom_offhire_days.toFixed(2)}</td> */}
          <td rowSpan={rowSpan}>
            <span className={"badge badge-pill badge-" + statusBadgeColorClass}>
              {report_status}
            </span>
          </td>
          {/* <td rowSpan={rowSpan}>
            {(report_status === "INCOMPLETE" ||
              report_status === "APPROVED") && (
              <Link to={"/offhire/report/update"} state={vesselOffhireReport}>
                <i className="fa fa-edit"></i>
              </Link>
            )}
          </td> */}
        </tr>
        {/* {rowSpan > 1 && (
          <tr>
            <td>{commenced_at.slice(0, 10)}</td>
            <td>{commenced_at.slice(11, 16)}</td>
            <td>{completed_at.slice(0, 10)}</td>
            <td>{completed_at.slice(11, 16)}</td>
            <td>{hours.toFixed(2)}</td>
          </tr>
        )} */}
      </React.Fragment>
    );
  }
}

export default VesselOffhireReportWithTimelineConflict;
