import React, { Component } from "react";
import VesselOffhireReportWithTimelineConflict from "./VesselOffhireReportWithTimelineConflict";

class VesselOffhireReportTableWithTimelineConflict extends Component {
  state = {};
  render() {
    const { reports } = this.props;
    const reportsWithTimelineConflits = reports;
    const theadRow2 = [
      "Sr. No.",
      "Vessel Name",
      "Vessel Age",
      "Location",
      "Place",
      "Type",
      "Machinery",
      "Machinery SubType",
      "Datails",
      "Planned/Unplanned",
      "Offhire/Stoppage",
      "Date",
      "Time",
      "Date",
      "Time",
      // "Total Time (day)",
      "Status",
    ];
    return (
      <div className="row bg-light mt-3">
        <div className="col ">
          {reportsWithTimelineConflits &&
            reportsWithTimelineConflits.length > 0 && (
              <React.Fragment>
                <h6 className="">
                  Reports with overlaping Event <br />
                  <small>
                    This event timeline is overlapping with the Events given
                    below
                  </small>
                </h6>
                <table
                  className="table table-bordered table-sm"
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  <tr>
                    {theadRow2.map((item, index) => {
                      return <th>{item}</th>;
                    })}
                  </tr>
                  {reportsWithTimelineConflits.map((report, index) => {
                    return (
                      <VesselOffhireReportWithTimelineConflict
                        srno={index + 1}
                        vesselOffhireReport={report}
                      />
                    );
                  })}
                </table>
              </React.Fragment>
            )}
        </div>
      </div>
    );
  }
}

export default VesselOffhireReportTableWithTimelineConflict;
